import React from 'react'
import { Switch, Route, useRouteMatch } from "react-router-dom";
import {loadStripe} from '@stripe/stripe-js';
import { Elements  } from '@stripe/react-stripe-js';
import ApiDetails from '../home-components/dataaccess/ApiDetails';
import Billing from '../V2/ClientPages/Billing/Billing.index';
import EsPageDataDownload from '../home-components/es_pages/DataAccess/DataDownload/DataDownload.index';
import AllSites from '../home-components/es_pages/sites/AllSites';
import IssueList from '../V2/ClientPages/Issues/Issues.index';
import AddNewCard from '../V2/ClientPages/Cards/AddNewCard/AddNewCard.component';
import ListAvailableCards from '../V2/ClientPages/Cards/ListAvailableCards/ListAvailableCards.component';
import Loader from 'react-loader-spinner';

export default function MyComponents({banner_notifications, current_organization, 
  current_user, stripeToken, take_my_org_details}) {
  const { path } = useRouteMatch();

  return <Switch>
    {
      current_organization?.org_identifier ?
      <>
        <Route path={`${path}invoices`} exact>
          <Billing 
            banner_notifications={banner_notifications} 
            current_organization={current_organization} 
            current_user={current_user} 
            take_my_org_details={take_my_org_details} 
          />
        </Route>
        <Route path={`${path}api`} exact> 
          <ApiDetails
            current_organization={current_organization} 
            current_user={current_user} 
            take_my_org_details={take_my_org_details}
          />
        </Route>
        <Route path={`${path}api_details`} exact>
          <ApiDetails
            current_organization={current_organization} 
            current_user={current_user} 
            take_my_org_details={take_my_org_details}
          />
        </Route>
        <Route path={`${path}data_download`} exact>
          <EsPageDataDownload 
            banner_notifications={banner_notifications} 
            current_organization={current_organization} 
            current_user={current_user} 
            take_my_org_details={take_my_org_details}
          />
        </Route>

        <Route path={`${path}sites`} exact>
          <AllSites 
            banner_notifications={banner_notifications} 
            current_organization={current_organization} 
            current_user={current_user} 
            take_my_org_details={take_my_org_details}
          />
        </Route>

        <Route path={`${path}issues`} exact>
          <IssueList 
            banner_notifications={banner_notifications} 
            current_organization={current_organization} 
            current_user={current_user} 
            take_my_org_details={take_my_org_details}
          />
        </Route>
        <Route path={`${path}cards`} exact>
          <ListAvailableCards 
            current_organization={current_organization} 
            current_user={current_user} 
            take_my_org_details={take_my_org_details}
          />
        </Route>
        {stripeToken !== '' && 
        <Elements stripe={loadStripe(stripeToken)}>
          <Route path={`${path}cards/new`} exact>
            <AddNewCard 
              current_organization={current_organization} 
              current_user={current_user} 
              take_my_org_details={take_my_org_details}
            />
          </Route>
        </Elements>
        }
      </> : <Loader type="Puff" color="#00BFFF" height={60} width={60} className='pc-loader' />
    }
  </Switch>;
}
