import React, {useEffect, useState, useContext} from 'react'
import { useParams } from "react-router-dom";
import Breadcrumb from '../../../global/Breadcrumb';
import axios from 'axios'
import { toast } from 'react-toastify';
import getSymbolFromCurrency from 'currency-symbol-map'
import { Card, CardBody, Container, Table, Row, Col, CardHeader, Button, Alert,
  Modal, ModalBody, ModalHeader, ModalFooter, FormGroup, Input, Label } from 'reactstrap';
import Loader from 'react-loader-spinner';
import {useHistory} from 'react-router-dom';

import SitegroupDetailsInSites from './SitegroupDetailsInSites';

// custom hooks
import useOrgIdentifer from '@hooks/org_identifier';

export default function AllSites({banner_notifications, current_organization, current_user, take_my_org_details}) {

  let history = useHistory();

  const org_identifier = useOrgIdentifer();

  const [bannerNotifications, setBannerNotifications] = useState([])

  useEffect(() => {
    take_my_org_details(org_identifier);
  }, [org_identifier]);

  useEffect(() => {
    if (org_identifier == "promptcloud"){
      history.push('/');
      toast.error("You are not authorized this page")
    } 
  }, [org_identifier])

  useEffect(() => {
    setBannerNotifications(banner_notifications)
  }, [banner_notifications])

  return (
    <Container fluid>
      <Breadcrumb parent={"Sites"} title={`Site Status`} />
      {bannerNotifications.map(item => {
        if (item?.gst_update?.message){
          return (
            <Alert color='primary' className='d-flex align-items-center justify-content-between my-2'>
              <p className='my-0'>{item?.gst_update?.message}</p>
              <a href={item?.gst_update?.url} target='_blank' style={{background: 'white', whiteSpace: 'nowrap'}} className='btn btn-xs text-primary'>Update GSTIN</a>
            </Alert>
          )
        }
        if (item?.pending_feasibility_reports?.length > 0){
          return (item?.pending_feasibility_reports?.map((each, index) => (
            <Alert color='primary' key={index} className='d-flex align-items-center justify-content-between my-2'>
              <p className='my-0'>{each?.message}</p>
              <a href={each?.url} target='_blank' style={{background: 'white', whiteSpace: 'nowrap'}} className='btn btn-xs text-primary'>View and Proceed</a>
            </Alert>
          )))
        }
      })}
      <div className='d-flex justify-content-between mb-3'>
        <a href={`/organizations/${org_identifier}/site/add`} className='btn btn-primary me-4'>
          + Add Site
        </a>
        <a href={`/organizations/${org_identifier}/sites/new`} className='btn btn-primary'>
          + Add Site group
        </a>
      </div>
      <SitegroupDetailsInSites orgName={org_identifier} current_organization={current_organization} />
    </Container>
  )
}
