import React, { useRef} from 'react';
import { useState } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Row, Col, Label, FormGroup, Input, Button } from 'reactstrap';
import DatePicker from "react-datepicker";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';


// Validation schema
const validationSchema = Yup.object().shape({
  crawl_type: Yup.string().required('Please select crawl type'),
  delivery_format: Yup.string().required('Please select delivery format'),
  frequency: Yup.string().required('Please select frequency method'),
  delivery_method: Yup.string().required('Please select delivery method'),
  delivery_method_details: Yup.object().when('delivery_method', {
    is: 's3',
    then:()=> Yup.object().shape({
      s3_bucket_name: Yup.string().required('S3 bucket name is required'),
      s3_id: Yup.string().required('S3 ID is required'),
      s3_key: Yup.string().required('S3 key is required'),
      s3_location: Yup.string().required('S3 location is required'),
    }),
    otherwise:()=> Yup.object().shape({
      dropbox_access_token: Yup.string().when('delivery_method', {
        is: 'dropbox',
        then:()=> Yup.string().required('Dropbox access token is required'),
      }),
      box_id: Yup.string().when('delivery_method', {
        is: 'box',
        then:()=> Yup.string().required('Box ID is required'),
      }),
      box_secret: Yup.string().when('delivery_method', {
        is: 'box',
        then:()=> Yup.string().required('Box secret is required'),
      }),
      box_username: Yup.string().when('delivery_method', {
        is: 'box',
        then:()=> Yup.string().required('Box username is required'),
      }),
      box_password: Yup.string().when('delivery_method', {
        is: 'box',
        then:()=> Yup.string().required('Box password is required'),
      }),
      ftp_server: Yup.string().when('delivery_method', {
        is: 'ftp',
        then:()=> Yup.string().required('FTP server is required'),
      }),
      ftp_username: Yup.string().when('delivery_method', {
        is: 'ftp',
        then:()=> Yup.string().required('FTP username is required'),
      }),
      ftp_password: Yup.string().when('delivery_method', {
        is: 'ftp',
        then:()=> Yup.string().required('FTP password is required'),
      }),
    }),
  }),
});

export default function EditDeliveryDetails({ version, localData, setLocalData, updateDeliveryDetails, toggle, modalOpen }) {

  const DeliveryRef = useRef(null)

  const initialValues = {
    crawl_type: localData?.delivery_details?.crawl_type || null,
    delivery_format: localData?.delivery_details?.delivery_format || 'json',
    frequency: localData?.delivery_details?.frequency || 'daily',
    delivery_frequency_details: localData?.delivery_frequency_details || {},
    delivery_method: localData?.delivery_details?.delivery_method || 'api',
    delivery_method_details: localData?.delivery_method_details || {}
  }

  const handleSubmit = async () => {
    if (DeliveryRef.current) {
      const errors = await DeliveryRef.current.validateForm();
      if (Object.keys(errors).length === 0) {
        await DeliveryRef.current.submitForm();
      } else {
        toast.error('Form is invalid, please check');
      }
    }
  };

  return (
    <div>
      <Button color='success' className='btn-sm' onClick={() => toggle()}>Edit</Button>
      <Modal isOpen={modalOpen} toggle={() => toggle()} size='lg'>
        <ModalHeader toggle={() => toggle()}>
          Edit Delivery Details
        </ModalHeader>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          enableReinitialize={true}
          innerRef={DeliveryRef}
          onSubmit={async (values, { setSubmitting }) => {
            await updateDeliveryDetails(values);
            setSubmitting(false)
          }}
        >
          {({ setFieldValue, values, validateForm }) => (
            <Form>
              <ModalBody>
                <div className='my-2 mx-3'>
                  <Row className='my-1'>
                    <Col lg={3}><h6 className=''>Crawl Type: </h6></Col>
                    <Col lg={9}>
                      {version === 'v2' && (
                        <div className='d-flex'>
                          <FormGroup check className='mx-2'>
                            <Label check>
                              <Field type="radio" name="crawl_type" value="none" />
                              {' '} Not set
                            </Label>
                          </FormGroup>
                          <FormGroup check className='mx-2'>
                            <Label check>
                              <Field type="radio" name="crawl_type" value="fullcrawl" />
                              {' '} Full crawl
                            </Label>
                          </FormGroup>
                          <FormGroup check className='mx-2'>
                            <Label check>
                              <Field type="radio" name="crawl_type" value="incremental_recrawl" />
                              {' '} Incr Crawl
                            </Label>
                          </FormGroup>
                        </div>
                      )}
                      {version === 'v3' && (
                        <Field as="select" name="crawl_type" className='form-control show-arrow'>
                          <option value={null}>Select Crawl Option</option>
                          <option value='fullcrawl'>Full crawl</option>
                          <option value='incremental_recrawl'>Incremental Crawl</option>
                        </Field>
                      )}
                      <ErrorMessage name="crawl_type" component="div" className='m-0 error-msg mt-1 mb-2' />
                    </Col>
                  </Row>
                  <hr />
                  <Row className='my-1'>
                    <Col lg={3}><h6 className=''>Data format: </h6></Col>
                    <Col lg={9}>
                      {version === 'v2' && (
                        <div className='d-flex'>
                          <FormGroup check className='mx-2'>
                            <Label check>
                              <Field type="radio" name="delivery_format" value="json" />
                              {' '} JSON
                            </Label>
                          </FormGroup>
                          <FormGroup check className='mx-2'>
                            <Label check>
                              <Field type="radio" name="delivery_format" value="csv" />
                              {' '} CSV
                            </Label>
                          </FormGroup>
                          <FormGroup check className='mx-2'>
                            <Label check>
                              <Field type="radio" name="delivery_format" value="xml" />
                              {' '} XML
                            </Label>
                          </FormGroup>
                          <FormGroup check className='mx-2'>
                            <Label check>
                              <Field type="radio" name="delivery_format" value="tsv" />
                              {' '} TSV
                            </Label>
                          </FormGroup>
                        </div>
                      )}
                      {version === 'v3' && (
                        <Field as="select" name="delivery_format" className='form-control show-arrow'>
                          <option value='json'>JSON</option>
                          <option value='ld_json'>LD-JSON</option>
                          <option value='xml'>XML</option>
                          <option value='csv'>CSV</option>
                          <option value='tsv'>TSV</option>
                        </Field>
                      )}
                      <ErrorMessage name="delivery_format" component="div" className='m-0 error-msg mt-1 mb-2' />
                    </Col>
                  </Row>
                  <hr />
                  <Row className='my-1'>
                    <Col lg={3}><h6 className=''>Frequency: </h6></Col>
                    <Col lg={9}>
                      <Field as="select" name="frequency" className='form-control show-arrow'>
                        <option value="daily">Daily</option>
                        <option value="weekly">Weekly</option>
                        <option value="monthly">Monthly</option>
                        <option value="other_frequency">Other</option>
                      </Field>
                      <ErrorMessage name="frequency" component="div" className='m-0 error-msg mt-1 mb-2' />
                    </Col>
                  </Row>
                  {values.frequency === 'weekly' && (
                    <Row className='my-4'>
                      <Col lg={3}><h6 className=''>Days: </h6></Col>
                      <Col lg={9}>
                        <div className='d-flex flex-wrap'>
                          {['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'].map(day => (
                            <FormGroup check className='mx-3' key={day}>
                              <Label check>
                                <Field type="radio" name="delivery_frequency_details.days" value={day} />
                                {' '} {day.charAt(0).toUpperCase() + day.slice(1)}
                              </Label>
                            </FormGroup>
                          ))}
                        </div>
                      </Col>
                    </Row>
                  )}
                  {values.frequency === 'monthly' && (
                    <Row className='my-4'>
                      <Col lg={3}><h6 className=''>Day of Month: </h6></Col>
                      <Col lg={9}>
                        <Field name="delivery_frequency_details.date">
                          {({ field, form }) => (
                            <DatePicker
                              className="form-control digits"
                              selected={field.value ? new Date(field.value) : new Date()}
                              onChange={(date) => form.setFieldValue('delivery_frequency_details.date', date)}
                            />
                          )}
                        </Field>
                        <ErrorMessage name="delivery_frequency_details.date" component="div" className='m-0 error-msg mt-1 mb-2' />
                      </Col>
                    </Row>
                  )}
                  {values.frequency === 'other_frequency' && (
                    <Row className='my-4'>
                      <Col lg={3}><h6 className=''>Details: </h6></Col>
                      <Col lg={9}>
                        <Field as="textarea" name="delivery_frequency_details.details" className='form-control' />
                      </Col>
                    </Row>
                  )}
                  <hr />
                  <Row className='my-2'>
                    <Col lg={3}><h6 className=''>Delivery Method: </h6></Col>
                    <Col lg={9}>
                      <Field as="select" name="delivery_method" className='form-control show-arrow'>
                        {version === 'v2' ? (
                          <>
                            <option value='api'>Promptcloud Data API (Free)</option>
                            <option value='s3'>S3 (+ $30 per month)</option>
                            <option value='dropbox'>Dropbox (+ $30 per month)</option>
                            <option value='box'>Box (+ $30 per month)</option>
                            <option value='ftp'>FTP (+ $30 per month)</option>
                          </>
                        ) : (
                          <>
                            <option value='api'>Promptcloud Data API (Free)</option>
                            <option value='s3'>Amazon s3</option>
                            <option value='dropbox'>Dropbox</option>
                            <option value='box'>Box</option>
                            <option value='ftp'>FTP</option>
                            <option value='sftp'>sFTP</option>
                            <option value='microsoft_azure'>Microsoft Azure</option>
                            <option value='google_drive'>Google drive</option>
                          </>
                        )}
                      </Field>
                      <ErrorMessage name="delivery_method" component="div" className='m-0 error-msg mt-1 mb-2' />
                    </Col>
                  </Row>
                  <div className='my-2 float-end'>
                    <a href='https://api.promptcloud.com/v2/data/info?id=demo&type=help' target="_blank" className='fw-bold'>Promptcloud API Help</a>
                  </div>
                  {values.delivery_method === 's3' && (
                    <Row className='my-4'>
                      <Col lg={3}><h6 className=''>Bucket Name: </h6></Col>
                      <Col lg={9}>
                        <Field name="delivery_method_details.s3_bucket_name" className='form-control' />
                        <ErrorMessage name="delivery_method_details.s3_bucket_name" component="div" className='m-0 error-msg mt-1 mb-2' />
                      </Col>
                    </Row>
                  )}
                  {values.delivery_method === 'dropbox' && (
                    <Row className='my-4'>
                      <Col lg={3}><h6 className=''>Access Token: </h6></Col>
                      <Col lg={9}>
                        <Field name="delivery_method_details.dropbox_access_token" className='form-control' />
                        <ErrorMessage name="delivery_method_details.dropbox_access_token" component="div" className='m-0 error-msg mt-1 mb-2' />
                      </Col>
                    </Row>
                  )}
                  {values.delivery_method === 'box' && (
                    <Row className='my-4'>
                      <Col lg={3}><h6 className=''>Box ID: </h6></Col>
                      <Col lg={9}>
                        <Field name="delivery_method_details.box_id" className='form-control' />
                        <ErrorMessage name="delivery_method_details.box_id" component="div" className='m-0 error-msg mt-1 mb-2' />
                      </Col>
                    </Row>
                  )}
                  {values.delivery_method === 'ftp' && (
                    <React.Fragment>
                      <Row className='my-4'>
                        <Col lg={3}><h6 className=''>FTP Username: </h6></Col>
                        <Col lg={9}>
                          <Field name="delivery_method_details.ftp_username" className='form-control' />
                          <ErrorMessage name="delivery_method_details.ftp_username" component="div" className='m-0 error-msg mt-1 mb-2' />
                        </Col>
                      </Row>
                      <Row className='my-4'>
                        <Col lg={3}><h6 className=''>FTP Server: </h6></Col>
                        <Col lg={9}>
                          <Field name="delivery_method_details.ftp_server" className='form-control' />
                          <ErrorMessage name="delivery_method_details.ftp_server" component="div" className='m-0 error-msg mt-1 mb-2' />
                        </Col>
                      </Row>
                    </React.Fragment>
                  )}
                </div>
              </ModalBody>
              <ModalFooter className='my-4'>
                <Button color="primary" onClick={() => handleSubmit(values, { validateForm })}>Done</Button>
              </ModalFooter>
            </Form>
          )}
        </Formik>
      </Modal>
    </div>
  );
}
