import React, { useEffect, useState, createContext, useMemo } from 'react'
import { toast } from 'react-toastify'
import { userLoggedin } from './localStorageMethods'
import { Switch, Route, useRouteMatch, useHistory } from "react-router-dom";
import Sidebar from './sidebar'
import Header from './header';
import Admin from './admin-components/Admin.js'
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import CrawlNotificationPreferences from './home-components/sites/CrawlNotificationPreferences';
import MergeAndUpload from './home-components/dataaccess/MergeAndUpload';
import EditOrganization from './home-components/profile-pages/EditOrganization';
import ProfilePageV2 from './home-components/profile-pages/V2/Profile/index';
import Feedback from './users-components/Feedback';
import ApiDetails from './home-components/dataaccess/ApiDetails';
import Webhooks from './home-components/dataaccess/Webhooks';
import ManageMembers from './home-components/profile-pages/ManageMembers/ManageMembers.component';
import FAQ from './static-components/FAQ';
import Help from './static-components/Help';
import NotificationPage from './home-components/profile-pages/NotificationPage';
import Footer from './Footer';
import axios from 'axios';
import Loader from "react-loader-spinner";
import MyComponents from './my-components/MyComponents';
import OrgShow from './admin-components/organizations/OrgShow';
import WebhookDashboard from './home-components/dataaccess/WebhookDashboard';
import SetBillingAlaramsIndex from './home-components/sites/set-billing-alarms/index';

/* import ES Pages */
import EsPageAllSites from './home-components/es_pages/sites/AllSites';
import EsPageHomepage from './home-components/es_pages/Home/Home.index';
import EsPageDataDownload from './home-components/es_pages/DataAccess/DataDownload/DataDownload.index';

/* Refactored V2 Pages */
import AddNewSitesToSiteGroup from './V2/ClientPages/AddNewSites/AddNewSitesToSiteGroup.component'
import AddNewSitegroup from './V2/ClientPages/AddNewSitegroup/AddNewSitegroup.index'
import FeasibilityReports from './V2/ClientPages/FeasibilityReports/FeasibilityReports.index';
import ClientFeasibilityReportSummary from './V2/ClientPages/FeasibilityReports/ClientFeasibilityReportSummary/ClientFeasibilityReportSummary.component';
import ClientUserAgreement from './V2/ClientPages/FeasibilityReports/ClientUserAgreement/ClientUserAgreement.component';
import ClientBillingDetailsPage from './V2/ClientPages/FeasibilityReports/ClientBillingDetails/ClientBillingDetailsPage.component';
import Billing from './V2/ClientPages/Billing/Billing.index';
import InvoiceDetails from './V2/ClientPages/Billing/InvoiceDetails/InvoiceDetails.component';
import ListAvailableCards from './V2/ClientPages/Cards/ListAvailableCards/ListAvailableCards.component';
import AddNewCard from './V2/ClientPages/Cards/AddNewCard/AddNewCard.component';
import MakePayment from './V2/ClientPages/MakePayment/MakePayment.index';
import OrganizationDetails from './V2/ClientPages/Organization/Organization.index';
import IssueList from './V2/ClientPages/Issues/Issues.index.jsx';
import IssueShow from './V2/ClientPages/Issues/TicketDetails/TicketDetails.component';

const OrgnaziationContext = createContext({
  orgName: '', setOrgName: () => { },
});

export default function Home() {
  let history = useHistory();
  const { path } = useRouteMatch();
  const [bannerNotifications, setBannerNotifications] = useState([])
  const [stripeToken, setStripeToken] = useState('')
  const [razorpayKey, setRazorpayKey] = useState('');
  const [loading, setLoading] = useState(true)
  const [user, setUser] = useState({})
  const [organization, setOrganization] = useState({})
  const [orgName, setOrgName] = useState('')
  const [environment, setEnvironment] = useState('')

  useEffect(() => {
    if (!userLoggedin()) {
      sessionStorage.setItem('redirectUrl', window.location.href)
      history.push('/users/sign_in')
    }
  }, [])

  const token = localStorage.getItem("authToken");
  const email = localStorage.getItem("authEmail");

  useEffect(() => {
    if (token && email) {
      axios.defaults.headers["X-Auth-Email"] = email;
      axios.defaults.headers["X-Auth-Token"] = token;
      axios.defaults.headers["Authorization"] = `Bearer ${token}`;
      axios.get(`/api/v1/user/details?email=${email}&page_type=client&controller_name=dashboard`).then(res => {
        setUser(res.data.user)
      }).catch(err => {
        if (err.response.data.message === "User is not present!") {
          handleLogout()
        }
      })
    }

  }, [token, email])

  const handleLogout = () => {
    axios.delete(`/api/v1/users/logout?page_type=client&controller_name=sessions`, {
      user: {
        email: localStorage.getItem("authEmail"),
        authenticity_token: localStorage.getItem("authToken")
      }
    }).then(res => {
      toast.success(res.data?.message)
    }).catch(err => {
      console.log('err', err);
    })
    localStorage.removeItem('authEmail');
    localStorage.removeItem('authToken');
    localStorage.removeItem('user_name');
    history.push("/users/sign_in")
  }

  useEffect(() => {
    setLoading(true)
    if (localStorage.getItem("authToken") && localStorage.getItem("authEmail")) {
      take_my_org_details();
    }
  }, [])

  const take_my_org_details = (org_identifier="") => {
    axios.get(`/api/v1/my_organization?org_id=${org_identifier}&page_type=client&controller_name=dashboard`).then(res => {
      setOrganization(res.data?.current_org_details)
      setOrgName(res.data?.current_org_details?.org_identifier)
      setStripeToken(res.data?.stripe_publishable_key)
      setRazorpayKey(res.data?.razorpay_key)
      setEnvironment(res.data?.current_environment)
      axios.get(`/api/v1/organizations/${res.data.current_org_details.org_identifier}/banner_notifications?page_type=client&controller_name=notifications`).then(res => {
        setBannerNotifications(res.data.banner_notifications)
        setLoading(false)
      }).catch(err => {
        setLoading(false)
      })
    }).catch(err => {
      if (err.response.data.message === "Authorization token has expired") {
        handleLogout()
      }
      else if (err.response.status == 500) {
        history.push("/server-error")
      }
      else if (err.response.status == 401) {
        history.push("/")
        toast.error(err.response.data.message)
      }
      else {
        history.push("/organizations/new")
      }
    })
  }

  return (
    <OrgnaziationContext.Provider value={{ orgName, setOrgName }}>
      <div className="page-wrapper compact-wrapper" id="pageWrapper">
        {!loading && <Header orgName={orgName} />}
        {loading ? <Loader type="Puff" color="#00BFFF" height={100} width={100} className='pc-loader' />
            : <div className="page-body-wrapper">
              <Sidebar orgName={orgName} />
              <div className="page-body">
                <Switch>
                  <Route path={`${path}admins/`}>
                    <Admin user={user} />
                  </Route>

                  <Route path={`${path}my/`}>
                    <MyComponents 
                      banner_notifications={bannerNotifications} 
                      stripeToken={stripeToken}
                      current_organization={organization} 
                      current_user={user} 
                      take_my_org_details={take_my_org_details}
                    />
                  </Route>

                  <Route path={`${path}`} exact>
                    <EsPageHomepage 
                      banner_notifications={bannerNotifications} 
                      current_organization={organization} 
                      current_user={user} 
                      take_my_org_details={take_my_org_details} 
                    />
                  </Route>

                  <Route path={`${path}faq`} component={FAQ} exact />
                  <Route path={`${path}help`} component={Help} exact />
                  <Route path={`${path}notifications/all_notifications`} exact>
                    <NotificationPage myOrganization={organization} />
                  </Route>

                  {/* data access pages */}
                  <Route path={`${path}organizations/:organization_name/merge_and_upload_new`} exact>
                    <MergeAndUpload 
                      current_user={user} 
                      current_organization={organization} 
                      take_my_org_details={take_my_org_details} 
                    />
                  </Route>
                  <Route path={`${path}organizations/:organization_name/api_details`} exact>
                    <ApiDetails 
                      current_user={user} 
                      current_organization={organization} 
                      take_my_org_details={take_my_org_details} 
                    />
                  </Route>
                  <Route path={`${path}organizations/:organization_name/webhooks`} exact>
                    <Webhooks 
                      current_user={user} 
                      current_organization={organization}
                      take_my_org_details={take_my_org_details}
                    />
                  </Route>
                  <Route path={`${path}organizations/:organization_name/webhook_dashboard`} exact>
                    <WebhookDashboard 
                      current_user={user} 
                      current_organization={organization}
                      take_my_org_details={take_my_org_details}
                    />
                  </Route>

                  {/* issues pages */}
                  <Route path={`${path}organizations/:organization_name/issues`} exact>
                    <IssueList 
                      banner_notifications={bannerNotifications} 
                      current_user={user} 
                      current_organization={organization} 
                      take_my_org_details={take_my_org_details}
                    />
                  </Route>
                  <Route path={`${path}organizations/:organization_name/issues/:issue_id`} exact>
                    <IssueShow 
                      banner_notifications={bannerNotifications} 
                      current_user={user} 
                      current_organization={organization} 
                      take_my_org_details={take_my_org_details}
                    />
                  </Route>

                  {/* client feasibility reports */}
                  <Route path={`${path}organizations/:organization_name/feasibility_reports`} exact>
                    <FeasibilityReports 
                      banner_notifications={bannerNotifications} 
                      environment={environment} 
                      current_user={user} 
                      current_organization={organization} 
                      take_my_org_details={take_my_org_details}
                    />
                  </Route>
                  <Route path={`${path}organizations/:organization_name/feasibility_reports/:feas_id`} exact>
                    <ClientFeasibilityReportSummary 
                      environment={environment} 
                      current_user={user} 
                      current_organization={organization} 
                      take_my_org_details={take_my_org_details}
                    />
                  </Route>
                  <Route path={`${path}organizations/:organization_name/feasibility_reports/:feas_id/user_agreements`} exact>
                    <ClientUserAgreement
                      current_user={user} 
                      current_organization={organization} 
                      take_my_org_details={take_my_org_details} 
                    />
                  </Route>
                  <Route path={`${path}organizations/:organization_name/feasibility_reports/:feas_id/billing_details`} exact>
                    <ClientBillingDetailsPage
                      current_user={user} 
                      current_organization={organization} 
                      take_my_org_details={take_my_org_details} 
                    />
                  </Route>

                  {/* sites */}
                  <Route path={`${path}organizations/:organization_name/sites/new`} exact>
                    <AddNewSitegroup 
                      current_user={user} 
                      environment={environment} 
                      current_organization={organization} 
                      take_my_org_details={take_my_org_details} 
                    />
                  </Route>
                  <Route path={`${path}organizations/:organization_name/site/add`} exact>
                    <AddNewSitesToSiteGroup 
                      current_user={user} 
                      current_organization={organization} 
                      take_my_org_details={take_my_org_details} 
                    />
                  </Route>
                  <Route path={`${path}organizations/:organization_name/sites/crawl_notification_preferences`} exact>
                    <CrawlNotificationPreferences 
                      current_user={user} 
                      current_organization={organization} 
                      take_my_org_details={take_my_org_details} 
                    />
                  </Route>
                  <Route path={`${path}organizations/:organization_name/sites/set_billing_alarms`} exact> 
                    <SetBillingAlaramsIndex
                      current_user={user} 
                      current_organization={organization} 
                      take_my_org_details={take_my_org_details} 
                    />
                  </Route>

                  {/* invoice */}
                  <Route path={`${path}organizations/:organization_name/invoices`} exact>
                    <Billing 
                      banner_notifications={bannerNotifications} 
                      current_user={user} 
                      current_organization={organization} 
                      take_my_org_details={take_my_org_details} 
                    />
                  </Route>
                  <Route path={`${path}organizations/:organization_name/invoices/:invoiceId`} exact>
                    <InvoiceDetails 
                      razorpayKey={razorpayKey} 
                      current_user={user} 
                      current_organization={organization} 
                      take_my_org_details={take_my_org_details} 
                    />
                  </Route>

                  {/* organization page */}
                  <Route path={`${path}organizations/:organization_name`} exact>
                    <OrganizationDetails 
                      banner_notifications={bannerNotifications} 
                      current_user={user} 
                      current_organization={organization} 
                      take_my_org_details={take_my_org_details} 
                    />
                  </Route>
                  <Route path={`${path}organizations/:organization_name/manage_users`} exact>
                    <ManageMembers 
                      current_user={user} 
                      current_organization={organization} 
                      take_my_org_details={take_my_org_details} 
                    />
                  </Route>
                  <Route path={`${path}organizations/:organization_name/edit`} exact>
                    <EditOrganization 
                      myOrganization={organization} 
                      take_my_org_details={take_my_org_details} 
                    />
                  </Route>

                  <Route path={`${path}profile/:profile_id`}
                    component={ProfilePageV2} exact />

                  <Route path={`${path}organizations/:organization_name/organization_show`} exact>
                    <OrgShow current_organization={organization} />
                  </Route>

                  {/* ES pages */}
                  {<Route path={`${path}organizations/:organization_name/home`} exact>
                    <EsPageHomepage 
                      banner_notifications={bannerNotifications} 
                      current_organization={organization} 
                      current_user={user} 
                      take_my_org_details={take_my_org_details}
                    />
                </Route>}
                  <Route path={`${path}organizations/:organization_name/data_download`} exact>
                    <EsPageDataDownload
                      banner_notifications={bannerNotifications} 
                      current_organization={organization} 
                      current_user={user} 
                      take_my_org_details={take_my_org_details}
                    />
                  </Route>
                  <Route path={`${path}organizations/:organization_name/sites`} exact>
                    <EsPageAllSites 
                      banner_notifications={bannerNotifications} 
                      current_organization={organization} 
                      current_user={user} 
                      take_my_org_details={take_my_org_details}
                    />
                  </Route>

                  {/* payment */}
                  {stripeToken !== '' && <Elements stripe={loadStripe(stripeToken)}>
                    <Route path={`${path}organizations/:organization_name/cards`} exact>
                      <ListAvailableCards 
                        current_user={user} 
                        current_organization={organization} 
                        take_my_org_details={take_my_org_details}
                      />
                    </Route>
                    <Route path={`${path}organizations/:organization_name/cards/new`} exact>
                      <AddNewCard 
                        current_user={user} 
                        current_organization={organization} 
                        take_my_org_details={take_my_org_details}
                      />
                    </Route>
                    <Route path={`${path}organizations/:organization_name/invoices/:invoiceId/payments/new`} exact>
                      <MakePayment 
                        current_user={user} 
                        current_organization={organization} 
                        take_my_org_details={take_my_org_details}
                      />
                    </Route>
                </Elements>}
              </Switch>
              <Feedback />
            </div>
        </div>}
          {!loading && <Footer />}
        </div>
      </OrgnaziationContext.Provider>
  )
}

export { OrgnaziationContext }
