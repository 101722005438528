import React, {useEffect, useState, useRef} from 'react';
import { Card, CardBody, Row, Col, Table} from 'reactstrap';
import axios from 'axios';
import Loader from 'react-loader-spinner';
import DataTable from 'react-data-table-component';
import { toast } from 'react-toastify';
import SitesTable from './SitesTable';
import SitesGraph from './SitesGraph';
import AsyncSelect from 'react-select/async';

export default function SitegroupDetailsInSites({orgName, current_organization}) {
  const [sitegroupNames, setSitegroupNames] = useState([]);
  const [siteNames, setSiteNames] = useState([])
  const [sitegroupId, setSitegroupId] = useState('');
  const [loading, setLoading] = useState(true);
  const [sitegroupData, setSitegroupData] = useState({});
  const [sitegroupNamesDropdownOptions, setSitegroupNamesDropdownOptions] = useState([]);
  const [selectedSitegroup, setSelectedSitegroup] = useState({value: '', label:'Select a Site Group'});
  const [country, setCountry] = useState('');

  const sitesTableRef = useRef();
  const sitesGraphRef = useRef();

  const handleSitegroup = (e) => {
    setSelectedSitegroup(e);
    let sitegroupVal = e['value'];
    setSitegroupId(sitegroupVal);
    getSitegroupDetails(sitegroupVal);
    sitesTableRef.current.getSitesBySitegroupId(sitegroupVal);
    sitesGraphRef.current.fetchSitesGraphData(sitegroupVal);
  }

  useEffect(() => {
    if (orgName){
      setLoading(true);
      axios.get(`/api/v1/organizations/${orgName}/sitegroups?from=site_list&page_type=client&controller_name=sitegroups`).then(res => {
        setSitegroupNames(res.data.sitegroups);
        setSiteNames(res.data.sites)
        setSitegroupNamesDropdownOptions(convertToArrayOfObjects(res?.data?.sitegroups));
        setCountry(res.data.currency_code);
        setLoading(false);
      }).catch(err => {
        toast.error(err.response.data.message);
        setLoading(false);
      })
    }
  }, [orgName])

  function getSitegroupDetails(sitegroupId){
    if (orgName && sitegroupId != ''){
      setLoading(true);
      axios.get(`/api/v1/organizations/${orgName}/sitegroups/${sitegroupId}?page_type=client&controller_name=sitegroups`)
        .then(res => {
          setSitegroupData(res.data.sitegroup)
          setLoading(false);
        }).catch(err => {
          toast.error(err.response.data.message)
          setLoading(false);
        }) 
    }
  }

  const columns = [
    {name: 'Field Name',selector: 'field_name',sortable: true},
    {name: 'Data Type',selector: 'data_type',sortable: true,},
    {name: 'Is Mandatory',selector: 'is_mandatory',sortable: true},
    {name: 'Default Value',selector: 'default_value',sortable: true},
    {name: 'Sample Value',selector: 'sample_value',sortable: true},
    {name: 'Comments',selector: 'comments',sortable: true},
  ];

  const cleanText = (text) => {
    return text.replaceAll("_", " ")
  }

  // Helper function to convert array of arrays into
  // Array of objects
  function convertToArrayOfObjects(inputArray) {
    return inputArray.map(([value, label]) => ({ value, label }));
  }

  // Helper function to filter the values and return the object
  // based on user input
  const filterValues = (inputValue) => {
    return sitegroupNamesDropdownOptions.filter((i) =>
      i.label.toLowerCase().includes(inputValue.toLowerCase())
    );
  };

  // AsyncMethod to traverse the array and return resut that matches 
  // the input query entered by the user 
  const loadOptions = (inputValue, callback) => {
    setTimeout(() => {
      callback(filterValues(inputValue));
    }, 650);
  };

  return (
    <>
      {
        loading ? <Loader type="Puff" color="#00BFFF" height={60} width={60} className='pc-loader' />
          : 
          <Card className='my-2'>
            <CardBody>
              <Row>
                <Col lg={3}>
                  <AsyncSelect
                    name='site_name'
                    id="siteName"
                    
                    value={selectedSitegroup}
                    placeholder="Select a Site Group"
                    cacheOptions
                    defaultOptions={sitegroupNamesDropdownOptions}
                    onChange={res => handleSitegroup(res)}
                    loadOptions={loadOptions}
                  />
                  {/* <select className='form-control show-arrow'
                    onChange={(e) => handleSitegroup(e)}
                    defaultValue={sitegroupId}>
                    <option value=''>
                      Select a Sitegroup
                    </option>
                    {sitegroupNames.map(each => (
                      <option value={each[0]} key={each[0]}>
                        {each[1]}
                      </option>
                    ))}
                  </select> */}
                </Col>
                {
                  sitegroupData.fields_to_extract && 
                  (
                    <Col lg={3}>
                      <div>
                        <h6>Fields to extract</h6>
                        <h6 className='fw-bold'>
                          {sitegroupData.fields_to_extract}
                        </h6>
                      </div>
                    </Col>
                  )
                }
                {
                  sitegroupData.additional_details && 
                  (
                    <Col lg={3}>
                      <Table bordered responsive>
                        <tbody>
                          <tr>
                            <td>Enable Merge</td>
                            <td className='fw-bold'>
                              {sitegroupData.additional_details.merge_files == 0 ? "Disabled" : "Enabled"}
                            </td>
                          </tr>
                          <tr>
                            <td>Enable Image Downloads</td>
                            <td className='fw-bold'>
                              {sitegroupData.additional_details.image_downloads == 0 ? "Disabled" : "Enabled"}
                            </td>
                          </tr>
                          {
                            sitegroupData.additional_details.hosted_indexing && 
                            (
                              <>
                                <tr>
                                  <td>Two days of delivery: </td>
                                  <td className='fw-bold'>
                                    {sitegroupData.additional_details.two_days_of_delivery == 0 ? "Disabled" : "Enabled"}
                                  </td>
                                </tr>
                                <tr>
                                  <td>Hosted Indexing </td>
                                  <td className='fw-bold'>
                                    {sitegroupData.additional_details.hosted_indexing == 0 ? "Disabled" : "Enabled"}
                                  </td>
                                </tr>
                              </>
                            )
                          }
                        </tbody>
                      </Table>
                    </Col>
                  )
                }
                <Col lg={3}>
                  <Table bordered responsive>
                    <tbody>
                      <tr>
                        <td>Output Format</td>
                        <td className='fw-bold'>
                          {
                            sitegroupData.delivery_format? cleanText(sitegroupData.delivery_format) : "NA"
                          }
                        </td>
                      </tr>
                      <tr>
                        <td>Frequency</td>
                        <td className='fw-bold'>
                          {
                            sitegroupData.delivery_frequency_in_text? cleanText(sitegroupData.delivery_frequency_in_text) : "NA"
                          }
                        </td>
                      </tr>
                      <tr>
                        <td>Delivery method</td>
                        <td className='fw-bold'>
                          {
                            sitegroupData.delivery_method? cleanText(sitegroupData.delivery_method) : "NA"
                          }
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </Col>
              </Row>
              <Row className='my-4'>
                { 
                  sitegroupData.schema_field_details && sitegroupData.schema_field_details.length > 0 &&
                  <div>
                    <DataTable data={sitegroupData.schema_field_details} 
                      columns={columns} pagination responsive noHeader />
                  </div>
                }
              </Row>
            </CardBody>
          </Card>
      }
      <SitesTable 
        orgName={orgName} 
        current_organization={current_organization}
        sitegroupNames={sitegroupNames} 
        siteNames={siteNames}
        country={country}
        ref={sitesTableRef}
      />
      <SitesGraph orgName={orgName} sitegroupId={sitegroupId} ref={sitesGraphRef} />
    </>
  )
}
