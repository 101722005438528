import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import Breadcrumb from '../../global/Breadcrumb';
import { Card, CardBody, CardHeader, Container, FormGroup, Row, Col, Input, Button } from 'reactstrap';
import axios from 'axios';
import { toast } from 'react-toastify';
import DatePicker from "react-datepicker";
import Loader from 'react-loader-spinner';
import * as Yup from 'yup';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import '../../../stylesheets/custom.scss';
import { object } from 'prop-types';

// custom hooks
import useOrgIdentifer from '@hooks/org_identifier';

const validationSchema = Yup.object({
  sitename: Yup.string().required('Site name is required').min(1,'Site name is required'),
  records: Yup.string().required('Please select records per file option'),
  recordCount: Yup.string().when('records', {
    is: 'custom_record',
    then: () => Yup.string()
    .required('Number of records is required')
    .min(0, 'Number of records must be positive')
    .matches(/^\d+$/, 'Number of records must be a positive integer'),
    otherwise:()=> Yup.string(),
  }),
  startDate: Yup.date().required('From date is required'),
  endDate: Yup.date().required('To date is required').min(Yup.ref('startDate'), 'End date cannot be before start date'),
});

export default function MergeAndUpload({ current_user, current_organization, take_my_org_details }) {
  const [loading, setLoading] = useState(true);
  const [sitesData, setSitesData] = useState([]);
  const [initialValues, setInitialValues] = useState({
    sitename: '',
    records: 'all',
    recordCount: 50000,
    unzip: false,
    dedup: false,
    startDate: new Date(),
    endDate: new Date(),
  });
  const [loadingButtons, setLoadingButtons] = useState(false);
  let today = new Date();

  let history = useHistory();

  const org_identifier = useOrgIdentifer();

  useEffect(() => {
    take_my_org_details(org_identifier);
  }, [org_identifier]);

  useEffect(() => {
    if (org_identifier){
    axios.get(`/api/v1/organizations/${org_identifier}/merge_and_upload_new?page_type=client&controller_name=data_download`)
      .then(res => {
        setSitesData(res.data.sites);
        setLoading(false);
      })
      .catch(err => {
        setLoading(false);
        // Refactor later
        if (err?.response?.data?.page_auth_failed) {
          history.push("/")
          toast.error(err?.response?.data?.message)
        }
        else if (!err?.response?.data?.org_auth_failed){
          toast.error(err?.response?.data?.message)
        }
      });
    }
  }, [org_identifier]);

  const handleFormSubmit = (values, formik) => {
    if (datediff(parseDate(values.startDate), parseDate(values.endDate)) < 0) {
      toast.error('Please provide valid From and To Dates');
    } else {
      let finalData = {
        sitename: values.sitename,
        records_per_file: values.records,
        unzip: values.unzip,
        dedup_records: values.dedup,
        crawldate: { from: getFormattedDate(values.startDate), to: getFormattedDate(values.endDate) }
      };
      if (values.records === 'custom_record') {
        finalData.records_custom = values.recordCount;
      }
      formik.validateForm().then((error)=>{
        if(Object.keys(error).length == 0){
          setLoadingButtons(true);
          axios.post(`/api/v1/organizations/${org_identifier}/merge_and_upload_create`, {
            filter: finalData,
            page_type: "client",
            controller_name: "data_download"
          }).then(res => {
            toast.success(res.data.message);
            setLoadingButtons(false);
          })
            .catch(err => {
              toast.error(err.response.data.message);
              setLoadingButtons(false);
            });
        } else {
          toast.error("Form is invalid please check.")
        }
      })

    }
  };

  function getFormattedDate(date) {
    var year = date.getFullYear();
    var month = (1 + date.getMonth()).toString();
    month = month.length > 1 ? month : '0' + month;
    var day = date.getDate().toString();
    day = day.length > 1 ? day : '0' + day;
    return year + '/' + month + '/' + day;
  }

  function parseDate(str) {
    str = getFormattedDate(str);
    var mdy = str.split('/');
    return new Date(mdy[2], mdy[0] - 1, mdy[1]);
  }

  function datediff(first, second) {
    return Math.round((second - first) / (1000 * 60 * 60 * 24));
  }

  return (
    <Container fluid>
      <Breadcrumb parent="Merge and upload" title="Merge and upload" />
      {loading ? <Loader type="Puff" color="#00BFFF" height={60} width={60} className='pc-loader' /> :
      <Card>
        <CardHeader className="internal-dash-card-header">
          <h6 className='fw-bold'>Merge and upload</h6>
        </CardHeader>
        <CardBody>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleFormSubmit}
            validateOnBlur={true}
            validateOnChange={true}
          >
            {({ setFieldValue, values, errors, touched, validateForm, setFieldTouched, validateField }) => {
              return(
                <Form className='pb-3' noValidate>
                  <FormGroup>
                    <h6 className='fw-bold'> Site Name: </h6>
                    <Field as="select" name="sitename" className='form-control show-arrow' required>
                      <option value=''>Choose a sitename</option>
                      {sitesData.map((each, index) => (
                        <option key={index} value={each}> {each} </option>
                      ))}
                    </Field>
                    <ErrorMessage name="sitename" component="p" className="m-0 error-msg mt-1 mb-2" />
                  </FormGroup>
                  <Row className='mt-4 mb-3'>
                    <Col lg={4}>
                      <h6 className='fw-bold'>Records Per File (Optional)</h6>
                      <div className='form-group my-3'>
                        <div className='my-2'>
                          <Field type="radio" name="records" value="all" className='me-2' />
                          <label>All</label>
                        </div>
                        <div className='my-2'>
                          <Field type="radio" name="records" value="custom_record" className='me-2' />
                          <label>Records per file - Custom</label>
                        </div>
                      </div>
                    </Col>
                    <Col lg={4}>
                      <h6 className='fw-bold'>Unzip (Optional)</h6>
                      <div className='d-flex'>
                        <Field type="checkbox" name="unzip" className='form-check-input me-2' />
                        <label>Unzip</label>
                      </div>
                    </Col>
                    <Col lg={4}>
                      <h6 className='fw-bold'>Dedup Records (Optional)</h6>
                      <div className='d-flex'>
                        <Field type="checkbox" name="dedup" className='form-check-input me-2' />
                        <label>Dedup Records</label>
                      </div>
                    </Col>
                  </Row>
                  {values.records === 'custom_record' && <FormGroup className='mt-2 mb-4'>
                    <h6 className='fw-bold'>Enter Number of records: </h6>
                    <Field type='number' name='recordCount' placeholder='Number of records' min={0} className='form-control' />
                    <ErrorMessage name="recordCount" component="p" className="m-0 error-msg mt-1 mb-2" />
                </FormGroup>}
                  <Row className=''>
                    <h6 className='fw-bold mb-3'>Select Date:</h6>
                    <Col lg={6}>
                      <h6 className='fw-bold'>From</h6>
                      <DatePicker
                        className="form-control"
                        selected={values.startDate}
                        dateFormat={"yyy/MM/dd"}
                        maxDate={today}
                        onChange={(date) => setFieldValue('startDate', date)}
                        onBlur={() => {
                          setFieldTouched('startDate', true).then(()=> validateField('startDate'))
                        }}
                        onSelect={() => {
                          if(!touched?.endDate) setFieldTouched('startDate', true)
                        }}
                        required
                      />
                      <ErrorMessage name="startDate" component="p" className="m-0 error-msg mt-1 mb-2" />
                    </Col>
                    <Col lg={6}>
                      <h6 className='fw-bold'>To</h6>
                      <DatePicker
                        className="form-control"
                        selected={values.endDate}
                        dateFormat={"yyy/MM/dd"}
                        minDate={values.startDate}
                        maxDate={today}
                        onChange={(date) => setFieldValue('endDate', date)}
                        onBlur={() => {
                          setFieldTouched('endDate', true).then(()=> validateField('endDate'))
                        }}
                        onSelect={() => {
                          if(!touched?.endDate) setFieldTouched('endDate', true)
                        }}
                        required
                      />
                      <ErrorMessage name="endDate" component="p" className="m-0 error-msg mt-1 mb-2" />
                    </Col>
                  </Row>
                  <Button type='submit' color='success' className='mt-4' disabled={loadingButtons}>Submit</Button>
                </Form>
              )}}
            </Formik>
          </CardBody>
      </Card>}
    </Container>
  );
}
