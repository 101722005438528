import React, {forwardRef, useImperativeHandle, useState, useEffect} from 'react';
import axios from 'axios'
import { toast } from 'react-toastify';
import { useHistory } from "react-router-dom";
import Loader from 'react-loader-spinner';

const BusinessDetails = forwardRef(({data, setData, organization_name, myOrganization}, ref) => {
    const [businessType, setBusinessType] = useState('')
    const [businessDuration, setBusinessDuration] = useState('')
    const [businessNumber, setBusinessNumber] = useState('')
    const [taxNumber, setTaxNumber] = useState('')
    const [ein, setEin] = useState('')
    const [loading, setLoading] = useState(false)
    const [panNumber, setPanNumber] = useState('')
    const [loadingBtns, setLoadingBtns] = useState(false)
    const [user, setUser] = useState('')
    let history = useHistory();

    useEffect(() => {
        setUser(myOrganization?.org_identifier || '')
    }, [myOrganization])

    useEffect(() => {
        if ("address" in data){
            setLoading(true)
            setBusinessType(data?.business_type || '')
            setBusinessNumber(data?.business_number || '')
            setTaxNumber(data?.tax_number || '')
            setEin(data?.ein || '')
            setPanNumber(data?.pan_number)
            setLoading(false)
        }
    }, [])

    useEffect(() => {
        setData({...data, 
            business_number: businessNumber, business_type: businessType,
            ein, pan_number : panNumber, tax_number: taxNumber
        })
    }, [businessType, businessDuration, businessNumber, taxNumber, ein, panNumber])

    useImperativeHandle(ref, () => ({
        isValidated() {
            return true
        }
    }));

    const updateOrganization = () => {
        const resObj = {}
        if (data.address.country_name == "India"){
            resObj["gstin_id"] = data.gstin_id || ''
        }
        resObj["billing_name"] = data.billing_name || ''
        resObj["phone_number"] = data.phone_number || ''
        resObj["business_number"] = data.business_number || ''
        resObj["business_type"] = data.business_type || ''
        resObj["business_duration"] = data.business_duration || ''
        resObj["tax_number"] = data.tax_number || ''
        resObj["ein"] = data.ein || ''
        resObj["pan_number"] = data.pan_number || ''
        resObj["address_attributes"] = {
            id: data.address.id, 
            line1: data.address.line1, 
            line2: data.address.line2, 
            city: data.address.city, 
            state: data.address.state, 
            zip: data.address.zip, 
        }
        axios.put(`/api/v1/organizations/${organization_name}`, {
          organization: resObj,
          page_type: "client",
          controller_name: "organizations"
        }).then(res => {
            toast.success(res.data.message)
            history.push(`/organizations/${organization_name}`)
        }).catch(err => {
            toast.error(err.response.data.message)
        })
    }

    return (
        <>
        {
        loading ? <Loader type="Puff" color="#00BFFF" height={60} width={60} className='pc-loader' /> :
        <div className='px-2 py-4'>
            <div className='my-4'>
                <h6 className='fw-bold'>Business Type </h6>
                <input type="text" className="form-control" 
                    value={businessType} placeholder="Buisness Type" onChange={(e) => setBusinessType(e.target.value)} />
            </div>

            <div className='my-4'>
                <h6 className='fw-bold'>Business Duration</h6>
                <input type="text" className="form-control" 
                    value={businessDuration} placeholder="Buisness Duration" onChange={(e) => setBusinessDuration(e.target.value)} />
            </div>

            <div className='my-4'>
                <h6 className='fw-bold'>Business Number </h6>
                <input type="text" className='form-control'
                    value={businessNumber} placeholder="Buisness Number" onChange={(e) => setBusinessNumber(e.target.value)} />
            </div>

            <div className='my-4'>
                <h6 className='fw-bold'>TAX number </h6>
                <input type="text" className="form-control" name="billing_name" 
                    value={taxNumber} placeholder="Tax Number" onChange={(e) => setTaxNumber(e.target.value)} />
            </div>

            <div className='my-4'>
                <h6 className='fw-bold'>EIN </h6>
                <input type="text" className='form-control' 
                    value={ein} placeholder="EIN" onChange={(e) => setEin(e.target.value)} />
            </div>

            <div className='my-4'>
                <h6 className='fw-bold'>PAN Number </h6>
                <input type="text" className='form-control' 
                    value={panNumber} placeholder="PAN Number" onChange={(e) => setPanNumber(e.target.value)} />
            </div>
        </div>}
        <button className='btn btn-success mb-2' style={{float: 'right', height: 39}} 
            onClick={updateOrganization} disabled={loadingBtns}>
            Update organization
        </button>
        </>
    );
});

export {BusinessDetails as default}
