import React, { useContext, useState, useEffect } from 'react';
import { Card, Container } from 'reactstrap'
import { useParams, useHistory } from 'react-router-dom'
import StepZilla from "react-stepzilla"
import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as Yup from "yup"
import axios from 'axios'
import { toast } from 'react-toastify'

import BreadCrumbs from '../../Common/BreadCrumb/BreadCrumbs.component'
import SchemaDetails from "../../../shared_components/stepzilla/SchemaDetails/SchemaDetails.component"
import SiteDetails from '../../../shared_components/stepzilla/SiteDetails/SiteDetails.component'
import AdditionalDetails from '../../../shared_components/stepzilla/AdditionalDetails/AdditionalDetails.component'

// custom hooks
import useOrgIdentifer from '@hooks/org_identifier';

export default function AddNewSitegroup({current_user, environment, current_organization, take_my_org_details}) {

  const formikRef = React.useRef();
  let history = useHistory();
  const org_identifier = useOrgIdentifer();
  
  const [buttonLoading, setButtonLoading] = useState(false);

  const accessEnv = ['development','staging']

  useEffect(() => {
    take_my_org_details(org_identifier);
  }, [org_identifier]);

  useEffect(() => {
    if (org_identifier == "promptcloud"){
      history.push('/');
      toast.error("You are not authorized this page")
    } 
  }, [org_identifier])
  
  // Solution for a personlised unique checking function in Yup
  Yup.addMethod(Yup.object, 'unique', function (propertyName, message) {
    return this.test('unique', message, function (value) {
      if (!value || !value[propertyName]) {
        return true;
      }

      const { path } = this;
      const options = [...this.parent];
      const currentIndex = options.indexOf(value);

      const subOptions = options.slice(0, currentIndex);

      if (subOptions.some((option) => option[propertyName] === value[propertyName])) {
        throw this.createError({
          path: `${path}.${propertyName}`,
          message,
        });
      }

      return true;
    });
  });

  const conditionalSchema = Yup.object().shape({
    url: Yup.string().when("rss_urls_crawl_limit", {
      is: (rss_urls_crawl_limit) => rss_urls_crawl_limit?.length > 0 && accessEnv.includes(environment),
      then:() => Yup.string()
        .required("This field is required if rss urls crawl limit has value!")
        .min(1, "This field is required if rss urls crawl limit has value!")
        .matches(/^((www\.)|(http(s*):\/\/))/i, "Please enter a valid URL"),
      otherwise: () => Yup.string().matches(/^((www\.)|(http(s*):\/\/))/i, {message:"Please enter a valid URL", excludeEmptyString: true}),
    }),
    rss_urls_crawl_limit: Yup.string().when("url", {
      is: (url) => url?.length > 0 && accessEnv.includes(environment),
      then:() => Yup.string()
        .required('This field is required if url has value!')
        .min(1, 'This field is required if url has value!')
        .matches(/^\d+$/, "This field should be integer!"),
      otherwise: () => Yup.string(),
    })
  },['rss_urls_crawl_limit','url'])

  const site_details_schema = Yup.array().of(conditionalSchema.unique("url", "Entered URL is already present"))

  const validationSchema = Yup.object({
    sitegroup_name: Yup.string()
      .required('This field is required')
      .min(2, 'Sitegroup Name must be at least 2 characters long')
      .matches(/^[\w-]+$/, 'Sitegroup Name should only contain letters, numbers, underscores, and hyphens'),
    crawl_type: Yup.string()
      .min(1,"This field is required")
      .required("This field is required"),
    data_delivery: Yup.string()
      .min(1,"This field is required")
      .required("This field is required"),
    rss_urls_crawl_limit: Yup.string()
      .when('/', {
        is: (value) => value && value.length > 0,
        then:()=> Yup.string().test('is-integer', 'This field should be an integer!', (value) => /^\d+$/.test(value))
      }),
    data_format: Yup.string()
      .min(1,"This field is required")
      .required("This field is required"),
    frequency: Yup.string()
      .min(1,"This field is required")
      .required("This field is required"),
    weekly: Yup.string().when('frequency', {
      is: (frequency) => frequency == 'weekly',
      then: () => Yup.string().required("This field is required"),
    }),
    details: Yup.string().when('frequency', {
      is: (frequency) => frequency == 'other_frequency',
      then: () => Yup.string()
        .required("This field is required")
        .min(1, 'Minimum requirement - at least one crawl per month, for a minimum period of 3 months')
    }),
    monthly: Yup.date().when('frequency', {
      is: (frequency) => frequency == 'monthly',
      then: () => Yup.date().required('This field is required'),
    }),
    site_details: site_details_schema,
    schema_fields_details: Yup.array().of(
      Yup.object({
        field_name: Yup.string().required('This field is required'),
        data_type: Yup.string().min(1,"This field is required").required('This field is required'),
        is_mandatory: Yup.string().min(1,"This field is required").required('This field is required')
      }).unique("field_name","Field name should be unique")
    ),
  });

  const handleSubmission = (values) => {
    setButtonLoading(true)
    axios.post(`/api/v1/organizations/${org_identifier}/sitegroups`, values)
    .then(res => {
      const data = res.data
      if (data.status == 'success') {
        toast.success(data.message)
        history.push(`/organizations/${org_identifier}/sites`)
      }
      else {
        toast.error(data.message)
      }
      setButtonLoading(false);
    }).catch(err => {
      toast.error(err.response.data.message)
      setButtonLoading(false);
    })
  }

  const steps = [
    {
      name: 'Schema Details',
      component: <SchemaDetails formikRef={formikRef} accessEnv={accessEnv} />
    },
    {
      name: 'Site Details',
      component: <SiteDetails formikRef={formikRef} accessEnv={accessEnv} />
    },
    {
      name: 'Additional Details',
      component: <AdditionalDetails 
        formikRef={formikRef} 
        accessEnv={accessEnv} 
        handleSubmission={handleSubmission} 
        environment={environment} 
        buttonLoading={buttonLoading} 
      />
    },
  ]

  return (
    <div>
      <Container fluid>
        <BreadCrumbs parent={"Add New Sitegroup"} title={"Add New Sitegroup"} />
        <Formik
          initialValues={{
            sitegroup_name:"",
            crawl_type:"",
            data_delivery: "api",
            rss_urls_crawl_limit:"",
            data_format:"",
            frequency:"",
            monthly: new Date(),
            schema_fields_details:[
              {field_name:"uniq_id", data_type:"string", is_mandatory:"yes", default_value:"system_generated", sample_value:"default",comments:""},
              {field_name:"crawl_timestamp", data_type:"date", is_mandatory:"yes", default_value:"system_generated", sample_value:"default",comments:""},
              {field_name:"pageurl", data_type:"string", is_mandatory:"yes", default_value:"system_generated", sample_value:"",comments:""},
              {field_name: "", data_type: "", is_mandatory: "no", default_value: "", sample_value: "", comments: "" }
            ],
            site_details:[
              { url:"",rss_urls_crawl_limit:""},
              { url:"",rss_urls_crawl_limit:""},
              { url:"",rss_urls_crawl_limit:""},
              { url:"",rss_urls_crawl_limit:""},
            ],
           }}
           validationSchema={validationSchema}
           innerRef={formikRef}
        >
          {({ isSubmitting, values, setFieldValue, validateForm , errors})=>{
            return(
              <Form>
                <Card className='px-5 py-4'>
                  <StepZilla
                    steps={steps}
                    preventEnterSubmission={true}
                    showSteps={true}
                    showNavigation={true}
                    stepsNavigation={true}
                    prevBtnOnLastStep={true}
                    dontValidate={false}
                  />
                </Card>
              </Form>
            )
          }}
        </Formik>
      </Container>
    </div>
  )
}
