import React, { useState, useEffect } from 'react';
import { Row, Col } from "reactstrap";
import DataTable from 'react-data-table-component';

import SiteLevelForm from "./SiteLevelForm";

export default function SiteLevel({ orgName, country, activeSites }) {

  const [searchText, setSearchText] = useState('');
  const [searchResults, setSearchResults] = useState([]);

  useEffect(() => {
    setSearchResults(activeSites);
  }, [activeSites]);

  // Table column definitions
  const columns = [
    { name: "Site Name", selector: "sitename", wrap: true },
    {
      name: 'Notify me when:',
      cell: row => (<SiteLevelForm
        orgName={orgName}
        country={country}
        site_id={row.id}/>),
    },
  ];


  const handleInputChange = event => {
    if (event.target.value == '') {
      setSearchResults(activeSites)
    }
    else {
      var search_result = activeSites.filter(item => item.sitename.includes(event.target.value));
      if (search_result.length > 0){
        setSearchResults(search_result)
      }
    }
    setSearchText(event.target.value);
  }

  return (
    <>
      { 
        activeSites.length == 0 ? 
        <div className="fw-bold">No Data Available</div> : 
        <>
          <Row className="my-3">
            <Col>
              <div className="float-end">
                <Row className="align-items-center">
                  <Col sm="3">Search</Col>
                  <Col>
                    <input type="text" className="form-control" value={searchText} onChange={handleInputChange} />
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
          <DataTable
            columns={columns}
            data={searchResults}
            responsive
            noHeader
            pagination
          />
      </>
      }
    </>
  )
}
