import React, { forwardRef, useImperativeHandle, useState, useEffect, version } from 'react';
import { useHistory } from "react-router-dom";

import { Row, Col, Input, Table, FormGroup} from 'reactstrap'
import { toast } from 'react-toastify'
import axios from 'axios'
import XLSX from 'xlsx'
import { FieldArray, Field, useFormikContext, ErrorMessage, setNestedObjectValues, } from 'formik';
import moment from 'moment';

import AddNewSite from './Modals/AddNewSite.component';
import EditDeliveryDetails from './Modals/EditDeliveryDetails.component';


const SiteDetails = forwardRef(({ data, setDataToggle, dataToggle,
   v2Data, dataDisable, setDataToggle2, formikRef }, ref) => {

    const [addSiteModal, setAddSiteModal] = useState(false)
    const [editDeliveryDetails, setDeliveryDetails] = useState(false)
    const [globalValues, setGlobalValues ] = useState({
      site_setup_fee: 0,
      monthly_maintenance_fee: 0,
      volume_charges: 0,
      number_of_free_records: 0,
    })
    const [globalCheck, setGlobalCheck] = useState(false)
    const [anyAdditionalCost, setAnyAdditionalCost] = useState(0)


    const { values, setFieldValue, validateForm, setTouched } = useFormikContext() 
    let history = useHistory()
    let accessEnv = ['development', 'staging']
    const environment = localStorage.getItem("environment")

    const toggleAddSiteModal = () => {
      setAddSiteModal(!addSiteModal)
    }

    const toggleEditDeliveryDetails = () => {
      setDeliveryDetails(!editDeliveryDetails)
    }

    const submitNewSiteRequirements = (values) => {
      if(addSiteModal){
        let new_urls = []
        var rss_urls = accessEnv.includes(environment) ? new RegExp(/^\d+$/) : new RegExp(/.*/)
        new_urls = values?.site_details.filter(site => site?.url?.length > 0 && rss_urls.test(site?.rss_urls_crawl_limit))
        axios.post(`/api/v1/organizations/${data.organization_identifier}/sites`, {
          "submit_requirements": {
            sitegroup_id: data?.sitegroup_id,
            site_add_from: "feasibility_check",
            site_details: new_urls
          }
        }).then(res => {
          if (res.status === 200) {
            toast.success(res.data.message)
            setAddSiteModal(false)
          }
        }).catch(err => {
          toast.error(err.response.data.message)
        }).finally(() => setDataToggle(curr => !curr))
        }
    }


    const updateDeliveryDetails = async (val) => {
      try {
        let values = val
        let date = values?.delivery_frequency_details?.date 
        if(date) values["delivery_frequency_details"].date = moment(date).format('MM/DD/YYYY')
        const endpoint = `/api/v1/admins/feasibility_checks/${data.id}/update_delivery_details`;
        const payload = {
          submit_requirements: {
            crawl_type: values.crawl_type,
            delivery_format: values.delivery_format,
            delivery_frequency: values.frequency,
            delivery_method: values.delivery_method,
            delivery_frequency_details: {
              ...values.delivery_frequency_details
            },
            delivery_method_details: values.delivery_method_details,
          }
        }

        const response = await axios.put(endpoint, payload)
  
        if (data?.project_version === 'v3') {
          toast.success(response.data.message)
          if (dataDisable) {
            history.push(`/admins/feasibility_checks/${data.id}/edit`);
          } else {
            history.push(`/admins/feasibility_checks/${data.id}`);
          }
          setDataToggle(currentState => !currentState)
          toggleEditDeliveryDetails()
        } else {
          setDataToggle(currentState => !currentState)
          toast.success(response.data.message)
          toggleEditDeliveryDetails()
        }
      } catch (err) {
        console.log(err)
        toast.error(err.response?.data?.message || 'An error occurred');
      }
    }

    const parseToFloat = (value) => parseFloat(value) || value === "0" ? 0 : parseFloat(value);

    const generateFeasibilityReport = async () => {

        let originalUrls = []

        const errors = await formikRef.current.validateForm()

        if (Object.keys(errors).length > 0) {
          toast.error("Form is invalid please check.")
          setTouched(setNestedObjectValues(errors, true))
          return
        }

        if (data.project_version === 'v2') {
        const temp_site_details = values?.site_details?.map((item) => ({
          ...item,
          feasibility_status: item?.isEnabled ? 'feasible' : 'not_feasible',
        }))

        const resData = {
          org_feasibility_check: {
            sitegroup_id: data.sitegroup_id,
            feasibility_report_id: data.id,
            comments: values?.comments,
            skip_agreements: values?.skip_agreements,
            skip_invoice: values?.skip_invoice,
            use_custom_contracts: false,
            overall_feasibility: values?.overall_feasibility,
            project_version: data?.project_version,
            organization_name: data?.organization_name,
            send_feasibility_report: true,
            any_additional_cost: data?.any_additional_cost || anyAdditionalCost,
            additional_information: values?.additional_information ? values?.additional_information : data?.additional_information,
            fields_to_extract: values?.fields_to_extract,
            sites_details: temp_site_details,
            additional_details_fee: {
              image_downloads_fee: values?.image_downloads_fee,
              two_days_of_delivery_fee: values?.two_days_of_delivery_fee,
              hosted_indexing_fee: values?.hosted_indexing_fee,
              merge_files_fee: values?.merge_files_fee
            }
          }
        }

        if(dataDisable){
          axios.put(`/api/v2/admins/feasibility_checks/${data.id}`, resData)
            .then((res) => {
              toast.success(res.data.message)
              setTimeout(() => {
                setDataToggle2(curr => !curr)
                history.push(`/admins/feasibility_checks/${data.id}`)
              }, 2000);
            })
            .catch(err => {
              toast.error(err.response.data.message)
            })
        } else {
          axios.post('/api/v2/admins/feasibility_checks', resData)
            .then((res) => {
              toast.success(res.data.message)
              setTimeout(() => {
                setDataToggle2(curr => !curr)
              }, 2000);
            })
            .catch(err => {
              toast.error(err.response.data.message)
            })
        }
      } else if (data.project_version === 'v3') {
        await data.schema_fields_details.forEach(each => {
          originalUrls.push(each.id)
        })
        await values.schema_fields_details.forEach(eachRow => {
          originalUrls = originalUrls.filter(each => each !== eachRow.id)
        })
        const temp_site_details = values?.site_details?.map((item) => ({
          ...item,
          feasibility_status: item?.isEnabled ? 'feasible' : 'not_feasible',
        }))
        const resData = {
          org_feasibility_check: {
            sitegroup_id: data.sitegroup_id,
            feasibility_report_id: data.id,
            comments: values?.comments,
            skip_agreements: values?.skip_agreements,
            skip_invoice: values?.skip_invoice,
            use_custom_contracts: false,
            overall_feasibility: values?.overall_feasibility,
            project_version: data?.project_version,
            organization_name: data?.organization_name,
            send_feasibility_report: true,
            any_additional_cost: data?.any_additional_cost || anyAdditionalCost,
            additional_information: values?.additional_information ? values?.additional_information : data?.additional_information,
            schema_fields_details: values?.schema_fields_details,
            remove_schema_fields: { ids: originalUrls},
            sites_details: temp_site_details,
            additional_details_fee: {
              image_downloads_fee: values?.image_downloads_fee,
              merge_files_fee: values?.merge_files_fee
            }
          }
        }
        if (dataDisable) {
          axios.put(`/api/v2/admins/feasibility_checks/${data.id}`, resData)
            .then((res) => {
              toast.success(res.data.message)
              setTimeout(() => {
                setDataToggle2(curr => !curr)
                history.push(`/admins/feasibility_checks/${data.id}`)
              }, 2000);
            })
            .catch(err => {
              toast.error(err.response.data.message)
            })
        } else {
          axios.post('/api/v2/admins/feasibility_checks', resData)
            .then((res) => {
              toast.success(res.data.message)
              setTimeout(() => {
                setDataToggle2(curr => !curr)
              }, 2000);
            })
            .catch(err => {
              toast.error(err.response.data.message)
            })
        }
      }
    }

    const discardOrganization = () => {
      axios.post(`/api/v1/admins/feasibility_checks/${data.id}/discard_requirement`, {
        feasibility_report_id: data.id
      }).then(res => {
        toast.success(res.data.message)
        history.push(`/admins/feasibility_checks/`)
      }).catch(err => {
        toast.error(err.response.data.message)
      })
    }

    return(
      <FieldArray name="site_details">
      {
        ( arrayHelpers )=>{
          return(
            <div className='p-3'>
              <div className='mt-3 feasibility__site-details'>
              <Table responsive>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Sample URL</th>
                    <th className=''>
                      <input type="checkbox" className='form-check-input ms-2'
                        checked={globalCheck || values.site_details.every(siteDetail => siteDetail?.isEnabled === true)}
                        onChange={(e) => {
                          const newValue = e.target.checked
                          setGlobalCheck(newValue)
                          values.site_details.forEach((_, index) => {
                            setFieldValue(`site_details.${index}.isEnabled`, newValue);
                          })
                        }}
                        name='feasibility' />
                    </th>
                    <th>Site setup</th>
                    <th>Site Maintenance</th>
                    <th>Volume charges</th>
                    <th>No.of free records</th>
                  </tr>
                </thead>
                <tbody>
                {values?.site_details?.map((item, index) => (
                  <tr key={index}>
                    <td scope='row'>
                      {index + 1}
                    </td>
                    <td>
                      <a href={item?.site_url}>{item?.site_url}</a>
                    </td>
                    <td>
                      <Field
                        type="checkbox"
                        name={`site_details[${index}].isEnabled`}
                        className='form-check-input'
                        onChange={(e) => {
                          setFieldValue(`site_details[${index}].isEnabled`, e.target.checked);
                          if(e.target.checked) {
                            setFieldValue(`site_details[${index}].site_setup_fee`, globalValues.site_setup_fee || item.site_setup_fee);
                            setFieldValue(`site_details[${index}].monthly_maintenance_fee`, globalValues.monthly_maintenance_fee || item.monthly_maintenance_fee);
                            setFieldValue(`site_details[${index}].volume_charges`, globalValues.volume_charges || item.volume_charges);
                            setFieldValue(`site_details[${index}].number_of_free_records`, globalValues.number_of_free_records || item.number_of_free_records);
                          } else {
                            setFieldValue(`site_details[${index}].site_setup_fee`, 0);
                            setFieldValue(`site_details[${index}].monthly_maintenance_fee`, 0);
                            setFieldValue(`site_details[${index}].volume_charges`, 0);
                            setFieldValue(`site_details[${index}].number_of_free_records`, 0);                          
                          }
                        }}
                      />
                    </td>
                    <td>
                      <Field
                        type="text"
                        name={`site_details[${index}].site_setup_fee`}
                        className='form-control'
                        disabled={!item.isEnabled}
                        value={item.isEnabled ? item?.site_setup_fee : 0}
                        onChange={(e) => setFieldValue(`site_details[${index}].site_setup_fee`, e.target.value)}
                      />
                      <ErrorMessage name={`site_details[${index}].site_setup_fee`} component="p" className='m-0 error-msg mt-1 mb-2'/>
                    </td>
                    <td>
                      <Field
                        type="text"
                        name={`site_details[${index}].monthly_maintenance_fee`}
                        className='form-control'
                        disabled={!item.isEnabled}
                        value={item.isEnabled ? item?.monthly_maintenance_fee : 0}
                        onChange={(e) => setFieldValue(`site_details[${index}].monthly_maintenance_fee`, e.target.value)}
                      />
                      <ErrorMessage name={`site_details[${index}].monthly_maintenance_fee`} component="p" className='m-0 error-msg mt-1 mb-2'/>
                    </td>
                    <td>
                      <Field
                        type="text"
                        name={`site_details[${index}].volume_charges`}
                        className='form-control'
                        disabled={!item.isEnabled}
                        value={item.isEnabled ? item?.volume_charges : 0}
                        onChange={(e) => setFieldValue(`site_details[${index}].volume_charges`, e.target.value)}
                      />
                      <ErrorMessage name={`site_details[${index}].volume_charges`} component="p" className='m-0 error-msg mt-1 mb-2'/>
                    </td>
                    <td>
                      <Field
                        type="text"
                        name={`site_details[${index}].number_of_free_records`}
                        className='form-control'
                        disabled={!item.isEnabled}
                        value={item.isEnabled ? item?.number_of_free_records : 0}
                        onChange={(e) => setFieldValue(`site_details[${index}].number_of_free_records`, e.target.value)}
                      />
                      <ErrorMessage name={`site_details[${index}].number_of_free_records`} component="p" className='m-0 error-msg mt-1 mb-2'/>
                    </td>
                  </tr>
                ))}
                </tbody>
              </Table>
              {
                data?.project_version == 'v3' &&
                (
                  <AddNewSite
                   dataDisable={dataDisable}
                   localData={data}
                   environment={environment}
                   accessEnv={accessEnv}
                   toggle={toggleAddSiteModal}
                   modalOpen={addSiteModal}
                   submitNewSiteRequirements={submitNewSiteRequirements}
                  />
                )
              }
              </div>
              <div className='my-5'>
                <Row className='my-2'>
                  <Col lg={6}>
                   <div className="d-flex justify-content-between align-items-center">
                    <h6>Delivery Details</h6>
                      <EditDeliveryDetails 
                       version={data?.project_version}
                       localData={data}
                       toggle={toggleEditDeliveryDetails}
                       modalOpen={editDeliveryDetails}
                       updateDeliveryDetails={updateDeliveryDetails}
                      />
                   </div>
                    <Table>
                      <tbody>
                        <tr>
                          <td> Crawl Type </td>
                          <td className='fw-bold'>
                            {(data.delivery_details && data.delivery_details.crawl_type === 'incremental_recrawl') && 'Incremental Crawl'}
                            {(data.delivery_details && data.delivery_details.crawl_type === 'fullcrawl') && 'Full crawl'}
                            {(data.delivery_details && data.delivery_details.crawl_type === 'none') && 'N/A'}
                          </td>
                        </tr>
                        <tr>
                          <td> Data format </td>
                          <td className='fw-bold'>
                            {data.delivery_details && data.delivery_details.delivery_format?.toUpperCase().replace(/_/g, '-')}
                          </td>
                        </tr>
                        <tr>
                          <td> Frequency </td>
                          <td className='fw-bold'>
                            {data.delivery_details &&
                              (data.delivery_details.frequency === 'monthly'
                                ? `Every month at ${data.delivery_frequency_details.date && data.delivery_frequency_details.date.substring(3, 5)}`
                                : data.delivery_details.frequency === 'weekly'
                                  ? `Every week on ${data.delivery_frequency_details.days && data.delivery_frequency_details.days}`
                                  : data.delivery_details.frequency === 'daily'
                                    ? 'Daily'
                                    : `Other: ${data.delivery_frequency_details.other_details && data.delivery_frequency_details.other_details}`)}
                          </td>
                        </tr>
                        <tr>
                          <td> Data Delivery </td>
                          <td className='fw-bold'>
                            {data.delivery_details && data.delivery_details.delivery_method?.toUpperCase()}
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                    <Row className='mt-3'>
                      <Col lg={4}>
                        <h6>Feasibility</h6>
                      </Col>
                      <Col lg={8}>
                        <FormGroup>
                          <Field as="select" name="overall_feasibility" className='form-control show-arrow'>
                            <option value=''>Select</option>
                            <option value='feasible'>Feasible</option>
                            <option value='partially_feasible'>Partially Feasible</option>
                            <option value='not_feasible'>Not Feasible</option>
                          </Field>
                          <ErrorMessage name="overall_feasibility" component="p" className='m-0 error-msg mt-1 mb-2'/>
                        </FormGroup>
                      </Col>
                    </Row>
                  </Col>
                  <Col lg={6}>
                    <h6>Additional Details</h6>
                    <Table>
                      {data?.project_version === 'v2' && (
                        <tbody>
                          {dataDisable ? (
                            <>
                              {data?.additional_details.map(each => (
                                <tr key={each.key}>
                                  <td>{each.title}</td>
                                  <td>{each.status}</td>
                                  <td>
                                    <Field
                                      name={`additional_details[${each.key}].fee`}
                                      type="text"
                                      className="form-control"
                                      disabled
                                      value={each.fee}
                                    />
                                  </td>
                                </tr>
                              ))}
                            </>
                          ) : (
                            <>
                              <tr>
                                <td>Image Downloads</td>
                                <td>{data?.additional_details?.image_downloads == 0 ? 'Disabled' : 'Enabled'}</td>
                                <td>
                                  <Field
                                    name="image_downloads_fee"
                                    type="text"
                                    className="form-control"
                                    value={data?.additional_details?.image_downloads == 0 ? '' : values.image_downloads_fee}
                                    disabled={data?.additional_details?.image_downloads == 0 || dataDisable}
                                  />
                                  <ErrorMessage name="image_downloads_fee" component="div" className='m-0 error-msg mt-1 mb-2' />
                                </td>
                              </tr>
                              <tr>
                                <td>Two days of delivery</td>
                                <td>{data?.additional_details?.two_days_of_delivery == 0 ? 'Disabled': 'Enabled' }</td>
                                <td>
                                  <Field
                                    name="two_days_of_delivery_fee"
                                    type="text"
                                    className="form-control"
                                    value={data?.additional_details?.two_days_of_delivery == 0 ? '' : values.two_days_of_delivery_fee }
                                    disabled={data?.additional_details?.two_days_of_delivery == 0 || dataDisable}
                                  />
                                  <ErrorMessage name="two_days_of_delivery_fee" component="div" className='m-0 error-msg mt-1 mb-2' />
                                </td>
                              </tr>
                              <tr>
                                <td>Hosted Indexing</td>
                                <td>{data?.additional_details?.hosted_indexing == 0 ? 'Disabled' : 'Enabled'}</td>
                                <td>
                                  <Field
                                    name="hosted_indexing_fee"
                                    type="text"
                                    className="form-control"
                                    value={data?.additional_details?.hosted_indexing == 0 ? '' : values.hosted_indexing_fee}
                                    disabled={data?.additional_details?.hosted_indexing == 0 || dataDisable}
                                  />
                                  <ErrorMessage name="hosted_indexing_fee" component="div" className='m-0 error-msg mt-1 mb-2' />
                                </td>
                              </tr>
                              <tr>
                                <td>Merge files</td>
                                <td>{data?.additional_details?.merge_files == 0 ? 'Disabled' : 'Enabled'}</td>
                                <td>
                                  <Field
                                    name="merge_files_fee"
                                    type="text"
                                    className="form-control"
                                    value={data?.additional_details?.merge_files == 0 ? '' : values.merge_files_fee}
                                    disabled={data?.additional_details?.merge_files == 0 || dataDisable}
                                  />
                                  <ErrorMessage name="merge_files_fee" component="div" className='m-0 error-msg mt-1 mb-2' />
                                </td>
                              </tr>
                            </>
                          )}
                        </tbody>
                      )}
                      {data?.project_version === 'v3' && (
                        <tbody>
                          {dataDisable ? (
                            <>
                              {data?.additional_details.map(each => (
                                <tr key={each.key}>
                                  <td>{each.title}</td>
                                  <td>{each.status}</td>
                                  <td>
                                    <Field
                                      name={`additional_details[${each.key}].fee`}
                                      type="number"
                                      className="form-control"
                                      disabled
                                      value={each.fee}
                                    />
                                  </td>
                                </tr>
                              ))}
                            </>
                          ) : (
                            <>
                              <tr>
                                <td>Enable merge</td>
                                <td>{data?.additional_details?.merge_files == 0 ? 'Disabled' : 'Enabled'}</td>
                                <td>
                                  <Field
                                    name="merge_files_fee"
                                    type="text"
                                    className="form-control"
                                    value={data?.additional_details?.merge_files == 0 ? '' : values.merge_files_fee}
                                    disabled={data?.additional_details?.merge_files == 0}
                                  />
                                  <ErrorMessage name="merge_files_fee" component="div" className='m-0 error-msg mt-1 mb-2' />
                                </td>
                              </tr>
                              <tr>
                                <td>Enable Image / File Downloads</td>
                                <td>{data?.additional_details?.image_downloads == 0 ? 'Disabled' : 'Enabled'}</td>
                                <td>
                                  <Field
                                    name="image_downloads_fee"
                                    type="text"
                                    className="form-control"
                                    value={data?.additional_details?.image_downloads == 0  ? '' : values.image_downloads_fee}
                                    disabled={data?.additional_details?.image_downloads == 0}
                                  />
                                  <ErrorMessage name="image_downloads_fee" component="div" className='m-0 error-msg mt-1 mb-2' />
                                </td>
                              </tr>
                            </>
                          )}
                        </tbody>
                      )}
                    </Table>
                  </Col>
                  <div className='mb-3 mt-5'>
                    <div className='d-flex mb-2'>
                      <h6 className='me-1'>Signup Url:</h6> 
                      {data?.sign_up_url ? data.sign_up_url : "NA"}
                    </div>
                    <div>
                      <Field
                        as='textarea'
                        rows='3'
                        className='form-control'
                        name='comments'
                        placeholder='Enter the comments to be mailed'
                        value={values.comments}
                      />
                      <ErrorMessage name='comments' component='p' className='m-0 error-msg mt-1 mb-2' />
                    </div>
                  </div>
                  <Row gutter={[5,5]}>
                    <Col lg={4} className='p-2'>
                      <p className='m-0'> Site Setup Cost </p>
                      <input className='form-control' name="site_setup_fee" value={dataDisable ? data.site_setup_fee : globalValues?.site_setup_fee}
                        onChange={e => {
                          values.site_details.forEach((d, index) => {
                            setFieldValue(`site_details.${index}.site_setup_fee`, e.target.value, true);
                          })
                          setGlobalValues({...globalValues, site_setup_fee: e.target.value})
                        }} disabled={dataDisable} />
                    </Col>
                    <Col lg={4} className='p-2'>
                      <p className='m-0'> Maintenance Cost </p>
                      <input className='form-control' name="monthly_maintenance_fee" value={dataDisable ? data.monthly_maintenance_fee : globalValues?.monthly_maintenance_fee}
                        onChange={e => {
                          values.site_details.forEach((d, index) => {
                            setFieldValue(`site_details.${index}.monthly_maintenance_fee`, e.target.value, true);
                          })
                          setGlobalValues({...globalValues, monthly_maintenance_fee: e.target.value})
                        }} disabled={dataDisable} />
                    </Col>
                    <Col lg={4} className='p-2'>
                      <p className='m-0'> Volume costs </p>
                      <input className='form-control' name="volume_charges" value={dataDisable ? data.volume_charges : globalValues?.volume_charges}
                        onChange={e => {
                          values.site_details.forEach((d, index) => {
                            setFieldValue(`site_details.${index}.volume_charges`, e.target.value, true);
                          })
                          setGlobalValues({...globalValues, volume_charges: e.target.value})
                        }} disabled={dataDisable} />
                    </Col>
                    <Col lg={4} className='p-2'>
                      <p className='m-0'> Number of free records </p>
                      <input className='form-control' name="number_of_free_records" value={dataDisable ? data.number_of_free_records : globalValues?.number_of_free_records}
                        onChange={e => {
                          values.site_details.forEach((d, index) => {
                            setFieldValue(`site_details.${index}.number_of_free_records`, e.target.value, true);
                          })
                          setGlobalValues({...globalValues, number_of_free_records: e.target.value})
                        }} disabled={dataDisable} />
                    </Col>
                    <Col lg={4} className='p-2'>
                      <p className='m-0'> Any Additional cost </p>
                      <input className='form-control' name="any_additional_cost" value={dataDisable ? data.any_additional_cost :anyAdditionalCost}
                        onChange={e => setAnyAdditionalCost(e.target.value)} disabled={dataDisable} />
                    </Col>
                  </Row>
                  <Row className='mt-4 mb-3'>
                    <Col lg={3}>
                      <Field
                        type="checkbox"
                        name="send_feasibility_report"
                        className='form-check-input'
                        checked={values.send_feasibility_report}
                      />
                      {' '} Send Feasibility Report
                    </Col>
                    <Col lg={3}>
                      <Field
                        type="checkbox"
                        name="skip_agreements"
                        className='form-check-input'
                        checked={values.skip_agreements}
                      />
                      {' '} Skip Agreements
                    </Col>
                    <Col lg={3}>
                      <Field
                        type="checkbox"
                        name="skip_invoice"
                        className='form-check-input'
                        checked={values.skip_invoice}
                      />
                      {' '} Skip Invoice
                    </Col>
                    <Col lg={3}>
                      <Field
                        type="checkbox"
                        name="use_custom_contracts"
                        className='form-check-input'
                        checked={values.use_custom_contracts}
                      />
                      {' '} Use custom contracts
                    </Col>
                  </Row>
                  {values?.use_custom_contracts && <Row className='my-2'>
                    <Col lg={2}>
                      <h6 className='fw-bold my-0'>Upload MSA*</h6>
                    </Col>
                    <Col lg={3}>
                      File upload
                    </Col>
                    <Col lg={2}></Col>
                    <Col lg={2}>
                      <h6 className='fw-bold my-0'>Upload SoW*</h6>
                    </Col>
                    <Col lg={3}>
                      File upload
                    </Col>
                  </Row>}
                </Row>
              </div>
              <div className='d-flex float-end mt-3'>
                <button type='button' className='btn btn-success mx-4' onClick={()=>generateFeasibilityReport()}>
                  Generate feasibility report
                </button>
                <button className='btn btn-danger' onClick={()=>discardOrganization()}>
                  Discard
                </button>
              </div>
            </div>
          )
        }
      }
      </FieldArray>
    )
})

export { SiteDetails as default }

