import React, { useRef } from 'react';

import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Row, Col, Input, Button, Table, Form } from 'reactstrap';
import { Formik } from 'formik';
import * as Yup from 'yup';

import SiteDetails from '../../../../../shared_components/stepzilla/SiteDetails/SiteDetails.component';

export default function AddNewSite({
  dataDisable,
  toggle,
  modalOpen,
  localData,
  accessEnv,
  submitNewSiteRequirements,
  environment
}){

  const formikRef = useRef(null)
  const siteRef = useRef(null);

  Yup.addMethod(Yup.object, 'unique', function (propertyName, message) {
    return this.test('unique', message, function (value) {
      if (!value || !value[propertyName]) {
        return true;
      }

      const { path } = this;
      const options = [...this.parent];
      const currentIndex = options.indexOf(value);

      const subOptions = options.slice(0, currentIndex);

      if (subOptions.some((option) => option[propertyName] === value[propertyName])) {
        throw this.createError({
          path: `${path}.${propertyName}`,
          message,
        });
      }

      return true;
    });
  });

  const conditionalSchema = Yup.object().shape({
    url: Yup.string().when("rss_urls_crawl_limit", {
      is: (rss_urls_crawl_limit) => rss_urls_crawl_limit?.length > 0 && accessEnv.includes(environment),
      then:() => Yup.string()
        .required("This field is required if rss urls crawl limit has value!")
        .min(1, "This field is required if rss urls crawl limit has value!")
        .matches(/^((www\.)|(http(s*):\/\/))/i, "Please enter a valid URL"),
      otherwise: () => Yup.string().matches(/^((www\.)|(http(s*):\/\/))/i, {message:"Please enter a valid URL", excludeEmptyString: true}),
    }),
    rss_urls_crawl_limit: Yup.string().when("url", {
      is: (url) => url?.length > 0 && accessEnv.includes(environment),
      then:() => Yup.string()
        .required('This field is required if url has value!')
        .min(1, 'This field is required if url has value!')
        .matches(/^\d+$/, "This field should be integer!"),
      otherwise: () => Yup.string(),
    })
  },['rss_urls_crawl_limit','url'])

  const site_details_schema = Yup.array().of(conditionalSchema.unique("url", "Entered URL is already present"))

  const handleSubmit = (formikBag) =>{
    const { setSubmitting } = formikBag
    siteRef.current.isValidated()
    .then(() => {
      console.log('Form is valid');
      submitNewSiteRequirements(formikBag.values)
    })
    .catch((errors) => {
      console.error('Form validation errors:', errors);
    })
    .finally(() => {
      setSubmitting(false);
    });
  }

  return (
    <div>
      {!dataDisable && (
        <Button color='success' className='btn-sm d-flex mx-auto mt-3' onClick={() => toggle()}>
          Add new site
        </Button>
      )}
      <Modal isOpen={modalOpen} toggle={() => toggle()}  unmountOnClose={false} backdrop='static' size='xl'>
        <Formik
          innerRef={formikRef}
          initialValues={{
            site_details: [
              { url: "", rss_urls_crawl_limit: "" },
              { url: "", rss_urls_crawl_limit: "" },
              { url: "", rss_urls_crawl_limit: "" },
              { url: "", rss_urls_crawl_limit: "" },
            ],
          }}
          validationSchema={Yup.object({
            site_details: site_details_schema,
          })}
        >
          {( formikBag ) => (
            <Form>
              <ModalHeader toggle={() => toggle()}>
                Submit requirements
              </ModalHeader>
              <ModalBody>
                <Row className='my-2 ps-4'>
                  <Row>
                    <h6>
                      <b>Sitegroup name: </b>
                      {localData?.sitegroup_name || ''}
                    </h6>
                  </Row>
                  <SiteDetails ref={siteRef} accessEnv={accessEnv} formikRef={formikRef} />
                </Row>
              </ModalBody>
              <ModalFooter>
                <Button color="danger" onClick={() => toggle()} className="me-2">
                  Discard
                </Button>
                <Button color="success" onClick={()=>handleSubmit(formikBag)}>
                  Add sites
                </Button>
              </ModalFooter>
            </Form>
          )}
        </Formik>
      </Modal>
    </div>
  );
}