import React, {useState, useEffect, useContext} from 'react'
import { CardBody, CardHeader, Container, Card, Table, TabContent, TabPane,NavLink,
  Nav, NavItem, CardFooter, Modal, ModalHeader, ModalBody, ModalFooter, Row, Col, Button } from 'reactstrap'
import axios from 'axios'
import { useParams } from "react-router-dom";
import Breadcrumb from '../../global/Breadcrumb';
import { toast } from 'react-toastify';
import SweetAlert from 'react-bootstrap-sweetalert';
import {useHistory} from 'react-router-dom'
import Loader from 'react-loader-spinner';
import DataTable from "react-data-table-component";
import Select from "react-select";
import ViewPayloadDetails from './ViewPayloadDetails';
import ResendWebhookJob from './ResendWebhookJob';
import ViewNotificationHash from './ViewNotificationHash';
import moment from "moment";
import DatePicker from "react-datepicker";

// custom hooks
import useOrgIdentifer from '@hooks/org_identifier';

export default function WebhookDashboard({current_user, current_organization, take_my_org_details}) {

  const org_identifier = useOrgIdentifer();
  let history = useHistory();

  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [webhookDetails, setWebhookDetails] = useState([]);
  const [siteNameOptions, setSiteNameOptions] = useState([]);
  const [eventNameOptions, setEventNameOptions] = useState([]);
  const [hookEnvOptions, setHookEnvOptions] = useState([]);
  const [crawlTypesOptions, setCrawlTypesOptions] = useState([]);
  const [toggle, setToggle] = useState(false);
  const [accessEnv, setAccessEnv] = useState(false);
  const [accessCrawlType, setAccessCrawlType] = useState(false);
  const [siteName, setSiteName] = useState({label: "All", value: ""});
  const [eventName, setEventName] = useState({label: "All", value: ""});
  const [crawlType, setCrawlType] = useState({label: "All", value: ""});
  const [hookEnv, setHookEnv] = useState({label: "All", value: ""});
  const [notificiationId, setNotificiationId] = useState("");
  const [crawlPushId, setCrawlPushId] = useState("");
  const now = new Date();
  const [fromDate, setFromDate] = useState(new Date(moment().subtract(3, 'days')));
  const [toDate, setToDate] = useState(now);

  const [page, setPage] = useState(0);
  const [rowSize, setRowSize] = useState(10);
  const countPerPage = 10;
  const [pageTotal, setPageTotal] = useState(0);

  useEffect(() => {
    take_my_org_details(org_identifier);
  }, [org_identifier]);

  useEffect(() => {
    if (org_identifier){
    setLoading(true)
    axios.get(`/api/v1/organizations/${org_identifier}/webhook_dashboard?site_name=${siteName ? siteName.value : ""}&event_name=${eventName ? eventName.value : ""}&notification_id=${notificiationId}&crawl_push_id]=${crawlPushId}&crawl_type=${crawlType ? crawlType.value : ""}&environment=${hookEnv ? hookEnv.value : ""}&from_date=${fromDate ? moment(fromDate).format('YYYY/MM/DD') : "" }&to_date=${toDate ? moment(toDate).format('YYYY/MM/DD') : ""}&page=${0}&size=${rowSize}`)
      .then(res => {
        setSearchResults(res.data?.webhook_details)
        setWebhookDetails(res.data?.webhook_details)
        setSiteNameOptions(res.data?.site_names)
        setEventNameOptions(res.data?.event_names)
        setHookEnvOptions(res.data?.environments)
        setCrawlTypesOptions(res.data?.crawl_types)
        setAccessEnv(res.data?.can_access_env)
        setAccessCrawlType(res.data?.can_access_crawl_type)
        setPageTotal(res.data?.total_count)
        setLoading(false)
      }).catch(err => {
        toast.error(err?.response?.data?.message)
        setLoading(false)
      })
    }
  }, [org_identifier]);

  const handleSearch = () => {
      setLoading(true)
      axios.get(`/api/v1/organizations/${org_identifier}/webhook_dashboard?site_name=${siteName ? siteName.value : ""}&event_name=${eventName ? eventName.value : ""}&notification_id=${notificiationId}&crawl_push_id]=${crawlPushId}&crawl_type=${crawlType ? crawlType.value : ""}&environment=${hookEnv ? hookEnv.value : ""}&from_date=${fromDate ? moment(fromDate).format('YYYY/MM/DD') : "" }&to_date=${toDate ? moment(toDate).format('YYYY/MM/DD') : ""}&page=${0}&size=${rowSize}`)
        .then(res => {
          setSearchResults(res.data?.webhook_details)
          setWebhookDetails(res.data?.webhook_details)
          setSiteNameOptions(res.data?.site_names)
          setEventNameOptions(res.data?.event_names)
          setHookEnvOptions(res.data?.environments)
          setCrawlTypesOptions(res.data?.crawl_types)
          setAccessEnv(res.data?.can_access_env)
          setAccessCrawlType(res.data?.can_access_crawl_type)
          setPageTotal(res.data?.total_count)
          setLoading(false)
        }).catch(err => {
          toast.error(err?.response?.data?.message)
          setLoading(false)
        })
  }

  const handleFormReset = () => {
    setSiteName({label: "All", value: ""});
    setEventName({label: "All", value: ""});
    setCrawlType({label: "All", value: ""});
    setHookEnv({label: "All", value: ""});
    setNotificiationId("");
    setCrawlPushId("");
    setFromDate(new Date(moment().subtract(7, 'days')));
    setToDate(now);
  }

  const columns = [
    { 
      name: 'S.No', 
      selector: "", 
      width: "5%",
      cell: (row, index) => (index + 1)
    },
    { name: 'URL', selector: 'url', sortable: true },
    { name: 'Notification ID', selector: 'notification_id', sortable: true },
    { name: 'Site Name', selector: 'site_name', sortable: true },
    { name: 'Event Name', selector: 'event_name', sortable: true },
    { name: 'Status & Code', selector: 'status_and_code' },
    { name: 'Sent By', selector: 'sent_by', sortable: true },
    { 
      name: 'Created At', 
      selector: (row)=>(formatDate(row.created_at) + " UTC"), 
      sortable: true 
    },
    { 
      name: 'Action', 
      selector: '', 
      button: true,
      width: "30%",
      cell: (row, index) => (
        <>
          <ViewPayloadDetails data={row} key={index} setToggle={setToggle} org_identifier={org_identifier} />
          <ResendWebhookJob data={row} key={index} setToggle={setToggle} org_identifier={org_identifier} />
          {
            current_user.is_admin ? 
            <ViewNotificationHash data={row} key={index} setToggle={setToggle} />
              : ""
          }
        </>
      )
    },
  ]

  const handleInputChange = event => {
    if (event.target.value == '') {
      setSearchResults(webhookDetails)
    }
    else {
      setSearchResults(webhookDetails.filter(item => item.url? item.url.toString().includes(event.target.value) : "" || item.notification_id.toString().includes(event.target.value) || item.site_name.includes(event.target.value) || item.event_name.includes(event.target.value) || item.status_and_code.includes(event.target.value) || item.created_at.toString().includes(event.target.value) || item.sent_by.toString().includes(event.target.value)))
    }
    setSearchText(event.target.value);
  }

  const handlePageChange = pageNum => {
    setLoading(true)
    setPage(pageNum)
    axios.get(`/api/v1/organizations/${org_identifier}/webhook_dashboard?site_name=${siteName ? siteName.value : ""}&event_name=${eventName ? eventName.value : ""}&notification_id=${notificiationId}&crawl_push_id]=${crawlPushId}&crawl_type=${crawlType ? crawlType.value : ""}&environment=${hookEnv ? hookEnv.value : ""}&from_date=${fromDate ? moment(fromDate).format('YYYY/MM/DD') : "" }&to_date=${toDate ? moment(toDate).format('YYYY/MM/DD') : ""}&page=${pageNum}&size=${rowSize}`)
      .then(res => {
        setSearchResults(res.data?.webhook_details)
        setWebhookDetails(res.data?.webhook_details)
        setSiteNameOptions(res.data?.site_names)
        setEventNameOptions(res.data?.event_names)
        setHookEnvOptions(res.data?.environments)
        setCrawlTypesOptions(res.data?.crawl_types)
        setAccessEnv(res.data?.can_access_env)
        setAccessCrawlType(res.data?.can_access_crawl_type)
        setPageTotal(res.data?.total_count)
        setLoading(false)
      }).catch(err => {
        toast.error(err?.response?.data?.message)
        setLoading(false)
      })
  }

  const handleRowChange = rowNum => {
    setLoading(true)
    setRowSize(rowNum)
    axios.get(`/api/v1/organizations/${org_identifier}/webhook_dashboard?site_name=${siteName ? siteName.value : ""}&event_name=${eventName ? eventName.value : ""}&notification_id=${notificiationId}&crawl_push_id]=${crawlPushId}&crawl_type=${crawlType ? crawlType.value : ""}&environment=${hookEnv ? hookEnv.value : ""}&from_date=${fromDate ? moment(fromDate).format('YYYY/MM/DD') : "" }&to_date=${toDate ? moment(toDate).format('YYYY/MM/DD') : ""}&page=${page}&size=${rowNum}`)
      .then(res => {
        setSearchResults(res.data?.webhook_details)
        setWebhookDetails(res.data?.webhook_details)
        setSiteNameOptions(res.data?.site_names)
        setEventNameOptions(res.data?.event_names)
        setHookEnvOptions(res.data?.environments)
        setCrawlTypesOptions(res.data?.crawl_types)
        setAccessEnv(res.data?.can_access_env)
        setAccessCrawlType(res.data?.can_access_crawl_type)
        setPageTotal(res.data?.total_count)
        setLoading(false)
      }).catch(err => {
        toast.error(err?.response?.data?.message)
        setLoading(false)
      })
  }

  // Helper function to fromat date into more readable format
  function formatDate(dateString){
    const formattedDate = moment(dateString).format('YYYY-MM-DD h:mm A');
    return formattedDate;
  }

  return (
    <Container fluid>
      <>
        <Breadcrumb parent="Webhooks Dashboard" title="Webhook Dashboard" />
        <Card>
          <CardBody>
            <Row>
              <Col sm="4">
                <label className="fw-bold">Site Name</label>
                <Select 
                  options={siteNameOptions} 
                  value={siteName} 
                  isSearchable
                  isClearable
                  onChange={option => setSiteName(option)} />
              </Col>
              <Col sm="4">
                <label className="fw-bold">Event Name</label>
                <Select 
                  options={eventNameOptions} 
                  value={eventName} 
                  isSearchable
                  isClearable
                  onChange={option => setEventName(option)} />
              </Col>
              <Col sm="4">
                <label className="fw-bold">Notification ID</label>
                <input type="text" className='form-control' value={notificiationId} placeholder="Ex: 2c825f644b41350a9b5f28ef17c38912"
                  onChange={(e) => setNotificiationId(e.target.value)} />
              </Col>
            </Row>
            <Row className="mt-3 align-items-end">
              <Col sm="4">
                <label className="fw-bold">Crawl Push ID</label>
                <input type="text" className='form-control' value={crawlPushId} placeholder="Ex: 20200824103624"
                  onChange={(e) => setCrawlPushId(e.target.value)} />
              </Col>
              <Col sm="4">
                <label className="fw-bold">Created From</label>
                <DatePicker
                  className="form-control"
                  placeholderText="Select Date"
                  selected={fromDate ? new Date(fromDate) : fromDate}
                  dateFormat={"YYY/MM/dd"}
                  maxDate={new Date()}
                  onChange={(date) => setFromDate(date)} />
              </Col>
              <Col sm="4">
                <label className="fw-bold">Created To</label>
                <DatePicker
                  className="form-control"
                  placeholderText="Select Date"
                  selected={toDate ? new Date(toDate) : toDate}
                  dateFormat={"YYY/MM/dd"}
                  minDate={fromDate}
                  maxDate={new Date()}
                  onChange={(date) => setToDate(date)} />
              </Col>
            </Row>
            <Row className="mt-3 align-items-end">
              {
                accessCrawlType ?
                <>
                  <Col sm="4">
                    <label className="fw-bold">Crawl Type</label>
                    <Select 
                      options={crawlTypesOptions} 
                      value={crawlType} 
                      isSearchable
                      isClearable
                      onChange={option => setCrawlType(option)} />
                  </Col>
                </>
                  : ""
              }
              {
                accessEnv ?
                <Col sm="4">
                  <label className="fw-bold">Environment</label>
                  <Select 
                    options={hookEnvOptions} 
                    value={hookEnv} 
                    isSearchable
                    isClearable
                    onChange={option => setHookEnv(option)} />
                </Col>
                  : ""
              }
            </Row>
            <Row className="mt-3 align-items-end">
              <Col sm="3">
                <button className='btn btn-success w-100'
                  onClick={handleSearch}>Search</button>
              </Col>
              <Col sm="3">
                <button className='btn btn-danger w-100'
                  onClick={handleFormReset}>Form Reset</button>
              </Col>
            </Row>
          </CardBody>
        </Card>
        <Card>
          <CardBody>
            <h5><center><strong>Webhook Dashboard</strong></center></h5>
            {/* <Row className="my-3">
              <Col>
                <div className="float-end">
                  <Row className="align-items-center">
                    <Col sm="3">Search</Col>
                    <Col>
                      <input type="text" className="form-control" value={searchText} onChange={handleInputChange} />
                    </Col>
                  </Row>
                </div>
              </Col>
          </Row> */}
            <DataTable 
              columns={columns} data={searchResults} 
              highlightOnHover pagination paginationServer progressPending={loading}
              paginationTotalRows={pageTotal} paginationPerPage={countPerPage}
              onChangePage={handlePageChange} onChangeRowsPerPage={handleRowChange}
            />
          </CardBody>
        </Card>
      </>
    </Container>
  )
}
