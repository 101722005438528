import React from 'react';
import { useEffect, useState, useRef, useContext} from 'react';
import { useParams, useHistory} from "react-router-dom";


import { Card, Container, CardBody, CardHeader, Row, Col, Alert } from 'reactstrap';
import { toast } from 'react-toastify';
import { Paperclip } from 'react-feather';
import Dropzone from 'react-dropzone-uploader';
import axios from 'axios';


import Breadcrumb from '../../../Common/BreadCrumb/BreadCrumbs.component';
import BannerNotifications from '../../../Common/BannerNotification/BannerNotifications.component';
import SpinnerLoader from '../../../Common/LoadingAnimation/SpinnerLoader.component';

// custom hooks
import useOrgIdentifer from '@hooks/org_identifier';

export default function TicketDetails({ banner_notifications, current_user, current_organization, take_my_org_details }) {

  const history = useHistory()

  const org_identifier = useOrgIdentifer();

  const [data, setData] = useState({});
  const [imageUpload, setImageUpload] = useState({});
  const [toggle, setToggle] = useState('');
  const [loading, setLoading] = useState(true);

  const [accessibleModules, setAccessibleModules] = useState();

  const replyText = useRef("");
  const { issue_id } = useParams();

  useEffect(() => {
    take_my_org_details(org_identifier);
  }, [org_identifier]);

  useEffect(() => {
    let accessible_modules = current_user?.part_of_org_list?.filter(x => x.identifier == org_identifier).map((y) => y?.accessible_modules).flat();
    setAccessibleModules(accessible_modules);
  }, [current_user]);

  useEffect(() => {
    axios.get(`/api/v1/organizations/${org_identifier}/issues/${issue_id}?page_type=client&controller_name=issues`).then(res => {
      setData(res.data?.issue)
      setLoading(false)
    }).catch(err => {
      setLoading(false)
      // Refactor later
      if (err?.response?.data?.page_auth_failed) {
        history.push("/")
        toast.error(err?.response?.data?.message)
      }
      else if (!err?.response?.data?.org_auth_failed){
        toast.error(err?.response?.data?.message)
      }
    })
  }, [toggle]);

  // Helper function to upload file
  const uploadFile = (meta, file) => {
    const body= new FormData()
    body.append('file', file)
    body.append('filename', meta.name)
    body.append('page_type', "client")
    body.append('controller_name', "issues")
    axios.post(`/api/v1/organizations/${org_identifier}/issues/upload_file`, body, {
    }).then(res => {
      toast.success(res.data?.message)
      setImageUpload({ ...res.data?.upload, content_type: 'image/png', filename: meta.name })
    }).catch(err => {
      toast.error(err.response.data?.message)
    })
  }

  // Helper function to remove uploaded file
  const removeUploadedFile = (attachment_id) => {
    axios.delete(`/api/v1/organizations/${org_identifier}/issues/${attachment_id}/remove_file?page_type=client&controller_name=issues`)
      .then(res => {
        toast.success(res.data?.message)
        setImageUpload({})
      }).catch(err => {
        toast.error(err.response.data?.message)
      })
  }

  // Dropzone status change handler
  const handleChangeStatus = ({ meta, file }, status) => {
    if (status == 'done') {
      uploadFile(meta, file)
    }
    if (status == 'removed') {
      removeUploadedFile(imageUpload?.id)
    }
  }

  function handleUpload() {
    axios.put(`api/v1/organizations/${org_identifier}/issues/${issue_id}`, {
      issues: {
        uploads: [imageUpload]
      },
      page_type: "client",
      controller_name: "issues"
    }).then(res => {
      toast.success(res.data?.message)
      setImageUpload({});
      setToggle(curr => !curr)
    })
  };

  function handleSubmit() {
    axios.put(`/api/v1/organizations/${org_identifier}/issues/${issue_id}/reply_to_ticket`, {
      issues: { notes: replyText.current.value },
      page_type: "client",
      controller_name: "issues"
    }).then(res => {

      toast.success(res.data?.message)
      setToggle(curr => !curr)
    }).catch(err => {
      toast.error(err.response.data?.message)
    })
  }

  function gotoReply() {
    replyText.current.focus()
  }

  return (
    <div>
      <Container fluid>
        <Breadcrumb parent={"Issues"} title={`Issue ${issue_id}`}
          childrenObj={[{ name: 'Issues', url: `/organizations/${org_identifier}/issues` }]} />
        <BannerNotifications notificationsArray={banner_notifications} />
        <SpinnerLoader loading={loading && data?.id}>
          <Card>
            <CardHeader className='p-4'>
              <div className='d-flex align-items-center justify-content-between'>
                <div className='d-flex align-items-center'>
                  <p className='badge bg-light text-dark mx-2 my-0'
                    style={{ fontSize: 14, padding: '10px 16px' }}>
                    {data?.status_name}
                  </p>
                  <p className='badge bg-primary mx-2 my-0'
                    style={{ fontSize: 14, padding: '10px 16px' }}>
                    {data?.id}
                  </p>
                </div>

                {((current_user?.id && current_user?.is_admin) ||
                  accessibleModules?.includes("data_access")) &&
                    (data?.closed ? null : <button className='btn btn-success' onClick={()=>gotoReply()}>
                      Reply
                  </button>)}
                </div>
              </CardHeader>

              <CardBody className='p-4'>
                <h4 className='fw-lighter mb-3'>
                  {data?.subject}
                </h4>
                <Row className='px-4'>
                  <Col lg={6} className='px-3'>
                    <div className='d-flex align-center my-3'>
                      <h6 className='fw-bold my-0 mx-2'>
                        Created by:
                      </h6>
                      <h6 className='m-0'>
                        {data?.created_by}
                      </h6>
                    </div>
                    <div className='d-flex align-center my-3'>
                      <h6 className='fw-bold my-0 mx-2'>
                        Assigned to:
                      </h6>
                      <h6 className='m-0'>
                        {data?.assigned_to}
                      </h6>
                    </div>
                    <div className='d-flex align-center my-3'>
                      <h6 className='fw-bold my-0 mx-2'>
                        Priority:
                      </h6>
                      <h6 className='m-0'>
                        {data?.priority}
                      </h6>
                    </div>
                  </Col>
                  <Col lg={6} className='px-3'>
                    <div className='d-flex align-center my-3'>
                      <h6 className='fw-bold my-0 mx-2'>
                        Last updated:
                      </h6>
                      <h6 className='m-0'>
                        {data?.last_updated_at}
                      </h6>
                    </div>
                    <div className='d-flex align-center my-3'>
                      <h6 className='fw-bold my-0 mx-2'>
                        Created:
                      </h6>
                      <h6 className='m-0'>
                        {data?.created_at}
                      </h6>
                    </div>
                    <div className='d-flex align-center my-3'>
                      <h6 className='fw-bold my-0 mx-2'>
                        Due Date:
                      </h6>
                      <h6 className='m-0'>
                        {data?.due_date}
                      </h6>
                    </div>
                  </Col>
                </Row>
                {data?.description && <div className='mt-4 p-4 bg-light text-dark'
                  dangerouslySetInnerHTML={{ __html: data?.description }} />}
                  {data?.attachments?.length > 0 && <div className='mb-4 p-4 bg-light text-dark'>
                    <h6 className='fw-bold'>Attachments</h6>
                    {data?.attachments.map(each => (
                      <div className='d-flex align-center my-2' key={each.filename}>
                        <Paperclip size={12} />
                        <a href={each.download_url} download target='_blank' className='text-primary mx-2'>
                          {each.filename}
                        </a>
                        <p className='m-0 text-muted'>
                          ({each.file_size})
                        </p>
                      </div>
                    ))}
                  </div>}
                    {data?.replies?.length > 0 &&
                    <div className='my-4'>
                      <h5 className='mt-5 mb-3 fw-bold'>Activities: </h5>
                      {data?.replies.map(each => (
                        <div key={each.id} className='pb-3 pt-2 my-3 px-4' style={{ borderBottom: '2px solid #eee' }}>
                          {each?.changes?.length > 0 ? (
                            each.changes.map((eachChange, index) => (
                              <div key={index} className='d-flex justify-content-between align-items-center my-2'>
                                <div>
                                  <div dangerouslySetInnerHTML={{ __html: eachChange.changes_in_the_text }} />
                                  <p className='m-0 text-muted' style={{ fontSize: 10 }}>{eachChange.created_on}</p>
                                </div>
                                <div>
                                  <p className='m-0 text-muted' style={{ fontSize: 10 }}>{eachChange.time_ago_in_words}</p>
                                </div>
                              </div>
                            ))
                          ) : (
                            <div className='d-flex justify-content-between align-items-center my-2 mb-3'>
                              <div>
                                <div>
                                  <b>{each.user_name}</b> replied
                                </div>
                                <p className='m-0 text-muted' style={{ fontSize: 10 }}>{each.created_on}</p>
                              </div>
                              <div>
                                <p className='m-0 text-muted' style={{ fontSize: 10 }}>{each.time_ago_in_words}</p>
                              </div>
                            </div>
                          )}

                          {each?.notes !== "" &&
                          <div className='p-4 mx-3 bg-light text-dark'
                            dangerouslySetInnerHTML={{ __html: each.notes }} />}
                          </div>
                      ))}
                    </div>}
                  </CardBody>
                </Card>

                {((current_user?.id && current_user?.is_admin) ||
                  accessibleModules?.includes("data_access")) &&
                    (data?.closed ? null : <Card className='p-4 my-3'>
                      <div className='d-flex justify-content-between align-items-center'>
                        <textarea className='form-control my-2' rows='4'
                          placeholder='Enter your reply here' ref={replyText} />
                        <button className='btn btn-success mx-2'
                          onClick={handleSubmit}>Submit</button>
                      </div>
                      <div className='d-flex justify-content-between align-items-center my-2'>
                        <Dropzone maxFiles={1}
                          maxSizeBytes={14680064} accept="image/png"
                          onChangeStatus={handleChangeStatus} multiple={false}
                          canCancel={true} inputContent="Browse / Drop a File"
                          styles={{
                            dropzone: { height: 50 },
                            dropzoneActive: { borderColor: 'green' },
                          }}
                        />
                        <button className='btn btn-primary mx-2'
                          disabled={!('token' in imageUpload)}
                          onClick={handleUpload}>Upload</button>
                      </div>
                  </Card>)}
                </SpinnerLoader>
              </Container>
            </div>
  )
}
