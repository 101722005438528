import React, {useState, useEffect, useRef, useContext} from 'react'
import { CardBody, CardHeader, Container, Card, Table, TabContent, TabPane,NavLink, Nav, NavItem } from 'reactstrap'
import SyntaxHighlighter from 'react-syntax-highlighter';
import { xcode } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import '../../stylesheets/custom.scss';

import Loader from 'react-loader-spinner';

import PromptCloudLogo from '../sidebar/logo/logo.png';

export default function ApiDetails() {
  const [BasicTab, setBasicTab] = useState('1');

  const [loading, setLoading] = useState(false);

  const codeString1 = `
  <root>
    <updated_ts>2012-09-17 07:22:38 +0000</updated_ts>
    <updated>1347866558160978794</updated>
    <status>200</status>
    <status_desc>ok</status_desc>
    <entry>
      <url>
            https://datav2.promptcloud.com/data/<clientId>/amazon_camera_us-20101206.tx_dedup.xml.gz
            </url>
            <md5sum/>
            <updated>1347866558160978794</updated>
            <updated_ts>2012-09-17 07:22:38 +0000</updated_ts>
          </entry>
        </root>
`

const codeString2 = `
require 'unirest'
    response = Unirest.get "https://api.promptcloud.com/v2/data/info?id=<clientId>&client_auth_key=<clientAuthKey>&api_res_type=json&amp;days=2"
    response.code # Status code
    response.headers # Response headers
    response.body # Parsed body
    response.raw_body # Unparsed body
    if response.body["root"]["entry"].length > 0
        response.body["root"]["entry"].each do |entry|
            filename = entry["url"].split("/").last
            file_response = Unirest.get entry["url"]
            open(filename, 'wb') do |file|
                file << file_response.raw_body
            end
        end
    end

`

const codeString3 = `
import unirest
response = unirest.get("https://api.promptcloud.com/v2/data/info?id=<clientId>&client_auth_key=<clientAuthKey>&api_res_type=json&days=2")
response.code # Status code
response.headers # Response headers
response.body # Parsed body
response.raw_body # Unparsed body
if len(response.body["root"]["entry"]) > 0:
    for entry in response.body['root']['entry']:
    arr = entry["url"].split("/")
    filename = arr[-1]
    file_response = unirest.get(entry["url"])
    with open(filename, "w") as file:
        file.write(file_response.raw_body)    
`

const codeString4 = `
const fs = require('fs');
const unirest = require('unirest');
const download = require('download');

var Request = unirest.get("https://api.promptcloud.com/v2/data/info?id=<clientId>&client_auth_key=<clientAuthKey>&api_res_type=json&days=2");

Request.end(function (response) {
    resp = response.body;
    resp.root.entry.forEach(function(obj) {
        download(obj.url, 'dist').then(function() {
            console.log("Downloaded : " +obj.url);
        })
    })
})    
`

const codeString5 = `
<?php 
    $response = UnirestRequest::get('https://api.promptcloud.com/v2/data/info?id=<clientId>&client_auth_key=<clientAuthKey>&api_res_type=json&days=2');
    $response->code;        // HTTP Status code
    $response->headers;     // Headers
    $response->body;        // Parsed body
    echo $response->code;
    $response->raw_body;    // Unparsed body
    $arr = $response->body->root->entry;
    if (sizeof($arr) > 0) {
        foreach ($arr as &$entry) {
        $filename = end(explode('/',$entry->url));
        $file_response = UnirestRequest::get($entry->url);
        $file = fopen($filename,'w');
        fwrite($file,$file_response->raw_body);
        fclose($file);
        }
    }
?>    
`

const codeString6 = `
import java.io.File;
import java.io.FileOutputStream;
import java.io.IOException;
import java.net.MalformedURLException;
import java.net.URL;

import org.json.JSONArray;
import org.json.JSONObject;
import com.mashape.unirest.http.HttpResponse;
import com.mashape.unirest.http.JsonNode;
import com.mashape.unirest.http.Unirest;
import com.mashape.unirest.http.exceptions.UnirestException;

public class App {	
    public static void downloadFileFromUrl(URL sub_url2, File file) {
    try {
        java.io.InputStream input = sub_url2.openStream();
        FileOutputStream output = new FileOutputStream(file);
        byte[] buffer = new byte[4096];
        int n = 0;
        while (-1 != (n = input.read(buffer))) {
        output.write(buffer, 0, n);
        }
        input.close();
        output.close();
        System.out.println("File '" + file + "' downloaded successfully!");
        }
    catch(IOException ioEx) {
        ioEx.printStackTrace();
        }
    }
    public static void main(String[] args) throws UnirestException{
    String query_url = https://api.promptcloud.com/v2/data/info?id=<clientId>&client_auth_key=<clientAuthKey>&api_res_type=json&days=2;
    HttpResponse getResponse = Unirest.get(query_url).asJson();
    JSONObject getObject = getResponse.getBody().getObject();
    JSONObject root = getObject.getJSONObject("root");
    JSONArray entry = root.getJSONArray("entry");
    if (entry.length()>0) {
        for (int i = 0; i < entry.length(); i++) {
        JSONObject row = entry.getJSONObject(i);
        String sub_url1 = row.getString("url");
        String[] data = sub_url1.split("/");
        String fileName = data[data.length-1];
        try {
            URL sub_url2 = new URL(sub_url1);
            String home = System.getProperty("user.home");
            String dir = home+"/Downloads/my_data";
            File directory = new File(dir);
            if (directory.exists()) {
            System.out.println("Directory already exists ...");
            } 
            else {
            System.out.println("Directory not exists, creating now");
            boolean success = directory.mkdir();
            if (success) {
                System.out.printf("Successfully created new directory : %s%n", dir);
            }
            else {
                System.out.printf("Failed to create new directory: %s%n", dir);
                }
            }
            /* File where to be download*/
            File file = new File(dir+"/"+fileName);
            App.downloadFileFromUrl(sub_url2, file);
        }
        catch (MalformedURLException e) {
            e.printStackTrace();
        }
        }
    }  
    }
}    
`
const apiExampleRef = useRef(null)
const serverResponses = useRef(null)
const apiDemoVideo = useRef(null)
const queryParams = useRef(null)
const apiUsage = useRef(null)
const backupAPIServer = useRef(null)
const apiEndpoint = useRef(null)

const gotoApiExamples = () => {
  window.scrollTo({top: apiExampleRef.current.getBoundingClientRect().top, 
    behavior: "smooth"})
}

const gotoServerResponses = () => {
  window.scrollTo({top: serverResponses.current.getBoundingClientRect().top, 
    behavior: "smooth"})
}

const gotoApiDemo = () => {
  window.scrollTo({top: apiDemoVideo.current.getBoundingClientRect().top, 
    behavior: "smooth"})
}

const gotoQueryParam = () => {
  window.scrollTo({top: queryParams.current.getBoundingClientRect().top, 
    behavior: "smooth"})
}

const gotoApiUsage = () => {
  window.scrollTo({top: apiUsage.current.getBoundingClientRect().top, 
    behavior: "smooth"})
}

const gotoBackup = () => {
  window.scrollTo({top: backupAPIServer.current.getBoundingClientRect().top, 
    behavior: "smooth"})
}

const gotoApiEndpoint = () => {
  window.scrollTo({top: apiEndpoint.current.getBoundingClientRect().top, 
    behavior: "smooth"})
}

return (
  <Container fluid className='p-0'>
    <div>
      <img src={PromptCloudLogo} 
        className='d-flex mx-auto' style={{height: 100}} />
    </div>
    <div style={{background: '#081B22', width: '100%'}} className='py-4 px-5'>
      <Card className='d-flex'>
        <CardBody>
          <button className='btn btn-outline-primary btn-xs me-3' onClick={gotoApiEndpoint}>API Endpoint</button>
          <button className='btn btn-outline-primary btn-xs me-3' onClick={gotoBackup}>Backup API Server</button>
          <button className='btn btn-outline-primary btn-xs me-3' onClick={gotoApiUsage}>API Usage</button>
          <button className='btn btn-outline-primary btn-xs me-3' onClick={gotoQueryParam}>Query Parameters</button>
          <button className='btn btn-outline-primary btn-xs me-3' onClick={gotoApiDemo}>API Demo Video</button>
          <button className='btn btn-outline-primary btn-xs me-3' onClick={gotoServerResponses}>Server Responses</button>
          <button className='btn btn-outline-primary btn-xs me-3' onClick={gotoApiExamples} >API Examples</button>
        </CardBody>
      </Card>

      <Card>
        <CardHeader className='d-flex justify-content-between internal-dash-card-header'>
          <h6 className='my-0 fw-bold'>API Endpoint</h6>
          <input type='text' ref={apiEndpoint} className='form-control' 
            style={{width: 1, height: 1, border: 'none'}}/>
        </CardHeader>
        <CardBody>
          <a href={`https://api.promptcloud.com/v2/data/info?id=promptcloud_demo_TMWNqZ8YMUg&client_auth_key=rhc0eQj61x7Lya23RT1AGqc9GCdaeD2hx0jXdjAc2qU&count=true&days=2&sample=true`}>
            {"https://api.promptcloud.com/v2/data/info?id=promptcloud_demo_TMWNqZ8YMUg&client_auth_key=rhc0eQj61x7Lya23RT1AGqc9GCdaeD2hx0jXdjAc2qU&count=true&days=2&sample=true"}
          </a>
        </CardBody>
      </Card>

      <Card>
        <CardHeader className='d-flex justify-content-between internal-dash-card-header'>
          <h6 className='my-0 fw-bold'>Backup API server</h6>
          <input type='text' ref={backupAPIServer} className='form-control' 
            style={{width: 1, height: 1, border: 'none'}}/>
        </CardHeader>
        <CardBody>
          <div>
            <h6>
              You can access our second high availability server(bcp server). This is the corresponding bcp server url for the above link for example.
            </h6>
            <p className="code-red" style={{fontSize: "14px"}}>
              {"https://api-bcp.promptcloud.com/v2/data/info?id=<clientId>&client_auth_key=<clientAuthKey>"}
            </p>
            <h6 className="mt-1">
              We encourage our clients to use https://api.promptcloud.com and fallback to bcp server temporarily in case of unavailability of https://api.promptcloud.com
            </h6>
          </div>
        </CardBody>
      </Card>

      <Card>
        <CardHeader className='internal-dash-card-header'>
          <h6 className='my-0 fw-bold'>API usage</h6>
          <input type='text' ref={apiUsage} className='form-control' 
            style={{width: 1, height: 1, border: 'none'}}/>
        </CardHeader>
        <CardBody>
          <h6 className='mb-3'>PromptCloud data API can be best described in two phases</h6>
          <div className='ps-2'>
            <ol className='d-flex flex-column'>
              <li className="my-3">
                <h6 className="fw-bold">
                  Get the list of new files uploaded
                </h6>
                <div className='ps-2'>
                  <h6>
                    All PromptCloud API requests are composed of light-weight XML delivered as an HTTP POST request to the endpoint URL. All responses are wrapped in a top-level response element. The status attribute will let you know whether the request succeeded or failed.
                  </h6>
                  <ul style={{listStyleType: "circle"}} className='ps-2'>
                    <li>
                      <h6>
                        All XML should be UTF-8 encoded.
                      </h6>
                    </li>
                    <li>
                      <h6>
                        Date and time values are of the form YYYY-MM-DD HH:MM:SS GMT
                      </h6>
                    </li>
                  </ul>
                  <div className="mt-3">
                    <h6 className="fw-bold">Promptcloud Sample API:</h6>
                    <div className='ps-3 mb-3'>
                      <p className="code-red" style={{fontSize: "14px"}}>
                        {"https://api-bcp.promptcloud.com/v2/data/info?id=<clientId>&client_auth_key=<clientAuthKey>"}
                      </p>
                      <h6 className="my-2">By clicking the above API link, following details will appear.</h6>
                      <SyntaxHighlighter language="javascript" style={xcode}>
                        {codeString1}
                      </SyntaxHighlighter>
                    </div>
                    <h6>
                      As you can see above, you will find entries like: <a href="/organizations/promptcloud/api_details">https://datav2.promptcloud.com/data/*</a> listed within the XML rendered.
                    </h6>
                    <h6>
                      If you get data for multiple categories say blogs, forums, news then category is part of the url after your client authentication key. For example it could look like-
                    </h6>
                    <div className="bg-light text-danger my-3">
                      <h6 className="p-4">
                        {"https://datav2.promptcloud.com/data/<clientId>/blog/fixed_site_name_deduped_n-20130226_2503711328087_20130226042503.xml.gz"}
                      </h6>
                    </div>
                    <div className="bg-light text-danger my-3">
                      <h6 className="p-4">
                        {"https://datav2.promptcloud.com/data/<clientId>/news/fixed_site_name_deduped_n-20130226_2503711328087_20130226042503.xml.gz"}
                      </h6>
                    </div>
                  </div>
                </div>
              </li>
              <li className="my-3">
                <h6 className="fw-bold">
                  Download the files
                </h6>
                <div className='ps-2'>
                  <h6 className="my-2">
                    Above are compressed files and you will have to download all such files either by copy-pasting these links in a web browser or using a program. Once files are downloaded you will have to decompress them using a program (like 7zip) that'd give you the CSV or XML or JSON files.
                  </h6>
                  <h6 className="my-2">
                    In order to setup your API for continuous download, you’ll need to pass the highest updated time you got after the last call to the API back to the API in the next call. That way it will list all the files which were uploaded after the given timestamp. This can be done programmatically as detailed below under the various requests you can make.
                  </h6>
                </div>
              </li>
            </ol>
          </div>
        </CardBody>
      </Card>

      <Card>
        <CardHeader className='d-flex justify-content-between internal-dash-card-header'>
          <h6 className='my-0 fw-bold'>Query Parameters</h6>
          <input type='text' ref={queryParams} className='form-control' 
            style={{width: 1, height: 1, border: 'none'}}/>
        </CardHeader>
        <CardBody>
          <h6 className='mb-3'>
            You can see that this api contains some parameters like ( updated_ts, updated, status etc). We provide a very flexible and customized api where you can define your own parameters according to your usages. Details of the parameters are listed below.
          </h6>
          <h6>
            The distance between <span className="code-red">from_ts</span> and <span className="code-red">to_ts</span> cannot be more than a month. Even if you have not mentioned <span className="code-red">to_ts</span> in the url, the <span className="code-red">next_query</span> parameter in response will contain to_ts which defaults timestamp after next 2 days.
          </h6>

          <Table className="my-2">
            <thead>
              <tr>
                <th className="fw-bold">
                  Parameter
                </th>
                <th className="fw-bold">
                  Description
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <span className="code-red"> client_auth_key </span>
                </td>
                <td>
                  This is your basic unique authentication key <span className="code-red">{"<clientAuthKey>"}</span> which makes up your API endpoint above.
                  </td>
                </tr>
                <tr>
                  <td className='no-border'>
                    <span className="code-red">from_crawldate</span>
                  </td>
                  <td className='no-border'>
                    This parameter allows user to check the data from the date provided to the current date. If we want the data from 31st Jan 2016 then we can append the parameter <span className="code-red">“&from_crawldate=20160131”</span> as shown in the link below.
                  </td>
                </tr>
                <tr>
                  <td colSpan="2">
                    <p className="code-red">
                      {"https://api.promptcloud.com/v2/data/info?id=<clientId>&client_auth_key=<clientAuthKey>&from_crawldate=20160131"}
                    </p>
                  </td>
                </tr>
                <tr>
                  <td className='no-border'>
                    <span className="code-red">to_crawldate</span>
                  </td>
                  <td className='no-border'>
                    This parameter allows user to check the data from crawl starting date to the date provided. If we want the data till 30th Aug 2016 then we can append the parameter <span className="code-red"> “&to_crawldate=20160830” </span> as shown in the link below.
                  </td>
                </tr>
                <tr>
                  <td colSpan="2">
                    <p className="code-red">
                      {"https://api.promptcloud.com/v2/data/info?id=<clientId>&client_auth_key=<clientAuthKey>&to_crawldate=20160830"}
                    </p>
                  </td>
                </tr>
                <tr>
                  <td className='no-border'>
                    <span className="code-red">
                      from_date
                    </span>
                  </td>
                  <td className='no-border'>
                    The parameter will help you to answer the following query: Give me all the files ‘uploaded’ from this particular date given in yyyymmdd format. If we want the data from 30th Aug 2016 then we can append the parameter <span className='code-red'> “&from_date=20160830” </span> as shown in the link below.
                  </td>
                </tr>
                <tr>
                  <td colSpan="2">
                    <p className='code-red my-0'>
                      {"https://api.promptcloud.com/v2/data/info?id=<clientId>&client_auth_key=<clientAuthKey>&from_date=20160830"}
                    </p>
                  </td>
                </tr>
                <tr>
                  <td className="no-border">
                    <span className='code-red'>
                      to_date
                    </span>
                  </td>
                  <td className="no-border">
                    The parameter will help you to answer the following query: Give me all the files ‘uploaded’ to this particular date given in yyyymmdd format. If we want the data till 10th Sept 2016 then we can append the parameter <span className='code-red'> “&to_date=20160910” </span> as shown in the link below.
                  </td>
                </tr>
                <tr>
                  <td colSpan='2' className='no-border'>
                    <p className='code-red my-0'>
                      {"https://api.promptcloud.com/v2/data/info?id=<clientId>&client_auth_key=<clientAuthKey>&to_date=20160910"}
                    </p>
                  </td>
                </tr>
                <tr>
                  <td colSpan='2'>
                    <p>To use both the queries, pass <span className='code-red'> &from_date = yyyymmdd&to_date=yyyymmdd </span>. This query outputs all the files uploaded in between these two given ranges.</p>
                    <p className='code-red my-0'>
                      {"https://api.promptcloud.com/v2/data/info?id=<clientId>&client_auth_key=<clientAuthKey>&to_date=20160910"}
                    </p>
                  </td>
                </tr>
                <tr>
                  <td className='no-border'>
                    <span className='code-red'>days</span>
                  </td>
                  <td className='no-border'>
                    This parameter allows user to check the data for the number of days provided. If we want the data for last 3 days then we can append the parameter <span className='code-red'> “&days=3” </span> as shown in the link below.
                  </td>
                </tr>
                <tr>
                  <td colSpan='2'>
                    <p className='code-red my-0'>
                      {"https://api.promptcloud.com/v2/data/info?id=<clientId>&client_auth_key=<clientAuthKey>&days=3"}
                    </p>
                  </td>
                </tr>
                <tr>
                  <td className='no-border'>
                    <span className='code-red'>minutes</span>
                  </td>
                  <td className='no-border'>
                    This parameter allows user to check the data uploaded for the minutes provided. If we want the data for last 45 minutes then we can append the parameter <span className='code-red'>“&minutes=45”</span> as shown in the link below.
                  </td>
                </tr>
                <tr>
                  <td colSpan='2'>
                    <p className='code-red my-0'>
                      {"https://api.promptcloud.com/v2/data/info?id=<clientId>&client_auth_key=<clientAuthKey>&minutes=45"}
                    </p>
                  </td>
                </tr>
                <tr>
                  <td className='no-border'>
                    <span className='code-red'>hours</span>
                  </td>
                  <td className='no-border'>
                    This parameter allows user to check the data uploaded for the hours provided. If we want the data for last 5 hours then we can append the parameter <span className='code-red'> “&hours=5” </span> as shown in the link below.
                  </td>
                </tr>
                <tr>
                  <td colSpan='2'>
                    <p className='code-red my-0'>
                      {"https://api.promptcloud.com/v2/data/info?id=<clientId>&client_auth_key=<clientAuthKey>&hours=5"}
                    </p>
                  </td>
                </tr>
                <tr>
                  <td className='no-border'>
                    <span className='code-red'>ts</span>
                  </td>
                  <td className='no-border'>
                    This parameter allows user to check the data uploaded from the particular timestamp provided. If we want the data from timestamp 1347866558160978794 then we can append the parameter <span className='code-red'>“&ts=1347866558160978794”</span> as shown in the link below.
                  </td>
                </tr>
                <tr>
                  <td colSpan='2' className='no-border'>
                    <p className='code-red my-0'>
                      {"https://api.promptcloud.com/v2/data/info?id=<clientId>&client_auth_key=<clientAuthKey>&&ts=1347866558160978794"}
                    </p>
                  </td>
                </tr>
                <tr>
                  <td colSpan='2'>
                    <p>In order to check all data uploaded so far, append the base link with “&ts=0”.</p>
                    <p className="code-red">
                      {"https://api.promptcloud.com/v2/data/info?id=<clientId>&client_auth_key=<clientAuthKey>&ts=0"}
                    </p>
                  </td>
                </tr>
                <tr>
                  <td className='no-border'>
                    <span className='code-red'>from_ts</span>
                  </td>
                  <td className='no-border'>
                    This parameter allows user to check the data uploaded upto a particular timestamp provided. If we want the data from timestamp 1585570510246313335 to 1585699200000000000 then we can append the parameter <span className='code-red'> “&from_ts=1585570510246313335&to_ts=1585699200000000000” </span> as shown in the link below.
                  </td>
                </tr>
                <tr>
                  <td colSpan='2' className='no-border'>
                    <p className='code-red my-0'>
                      {"https://api.promptcloud.com/v2/data/info?id=<clientId>&client_auth_key=<clientAuthKey>&&from_ts=1347866558160978794"}
                    </p>
                  </td>
                </tr>
                <tr>
                  <td colSpan='2'>
                    <p>In order to check all data uploaded so far, append the base link with “&ts=0”.</p>
                    <p className='code-red my-0'>
                      {"https://api.promptcloud.com/v2/data/info?id=<clientId>&client_auth_key=<clientAuthKey>&from_ts=0"}
                    </p>
                  </td>
                </tr>
                <tr>
                  <td className='no-border'>
                    <span className='code-red'>to_ts</span>
                  </td>
                  <td className='no-border'>
                    This parameter allows user to check the data uploaded upto a particular timestamp provided. If we want the data from timestamp 1585570510246313335 to 1585699200000000000 then we can append the parameter <span className='code-red'>“&from_ts=1585570510246313335&to_ts=1585699200000000000”</span> as shown in the link below.
                  </td>
                </tr>
                <tr>
                  <td colSpan='2'>
                    <p className='code-red my-0'>
                      {"https://api.promptcloud.com/v2/data/info?id=<clientId>&client_auth_key=<clientAuthKey>&from_ts=1585570510246313335&to_ts=1585699200000000000"}
                    </p>
                  </td>
                </tr>
                <tr>
                  <td className='no-border'><span className='code-red'>site</span></td>
                  <td className='no-border'>This parameter allows user to check the data uploaded for the particular site or sites provided. If we want the data for site = abc or sites = abc, bcd, def then we can append the parameter <span className='code-red'>“&site=abc”</span> or <span className='code-red'>“&site=abc,bcd,def”</span> as shown in the link below.</td>
                </tr>
                <tr>
                  <td colSpan='2'>
                    <p className='code-red my-0'>
                      {"https://api.promptcloud.com/v2/data/info?id=<clientId>&client_auth_key=<clientAuthKey>&ts=0&site=abc"}
                    </p>
                    <p className='code-red my-0'>
                      {"https://api.promptcloud.com/v2/data/info?id=<clientId>&client_auth_key=<clientAuthKey>&ts=0&site=abc,bcd,def"}
                    </p>
                  </td>
                </tr>
                <tr>
                  <td className='no-border'><span className='code-red'>folder</span></td>
                  <td className='no-border'>
                    This parameter allows user to check the data uploaded for the particular folder or folders provided. If we want the data for folder = abc or folders = abc, bcd, def then we can append the parameter <span className='code-red'>“&folder=abc”</span> or <span className='code-red'>“&folder=abc,bcd,def”</span> as shown in the link below.
                  </td>
                </tr>
                <tr>
                  <td colSpan='2'>
                    <p className='code-red my-0'>
                      {"https://api.promptcloud.com/v2/data/info?id=<clientId>&client_auth_key=<clientAuthKey>&ts=0&folder=abc"}
                    </p>
                    <p className='code-red my-0'>
                      {"https://api.promptcloud.com/v2/data/info?id=<clientId>&client_auth_key=<clientAuthKey>&ts=0&folder=abc,bcd,def"}
                    </p>
                  </td>
                </tr>
                <tr>
                  <td className='no-border'><span className='code-red'>cat</span></td>
                  <td className='no-border'>This parameter allows user to check the data uploaded for the particular category provided. If we want the data for category = client_id then we can append the parameter <span className='code-red'>“&cat=demo”</span> as shown in the link below.</td>
                </tr>
                <tr>
                  <td colSpan='2'>
                    <p className='code-red my-0'>
                      {"https://api.promptcloud.com/v2/data/info?id=<clientId>&client_auth_key=<clientAuthKey>&ts=0&cat=demo"}
                    </p>
                  </td>
                </tr>
                <tr>
                  <td className='no-border'>
                    <span className='code-red'>count</span>
                  </td>
                  <td className='no-border'>
                    This parameter allows user to check the count of data uploaded. If we want the check the count then we can append the parameter <span className='code-red'>“&count=true”</span> as shown in the link below.
                  </td>
                </tr>
                <tr>
                  <td colSpan='2'>
                    <p className='code-red'>
                      {"https://api.promptcloud.com/v2/data/info?id=<clientId>&client_auth_key=<clientAuthKey>&ts=0&count=true"}
                    </p>
                  </td>
                </tr>
                <tr>
                  <td className='no-border'>
                    <span className='code-red'>api_res_type</span>
                  </td>
                  <td className='no-border'>
                    This parameter allows user to get query response in JSON format. If we want the JSON format response then we can append the parameter <span className='code-red'>“&api_res_type=json”</span> as shown in the link below.
                  </td>
                </tr>
                <tr>
                  <td colSpan='2'>
                    <p className='code-red'>
                      {"https://api.promptcloud.com/v2/data/info?id=<clientId>&client_auth_key=<clientAuthKey>&ts=0&api_res_type=json"}
                    </p>
                  </td>
                </tr>
              </tbody>
            </Table>

            <p className='mt-3'>
              <b>Note:</b>  Priority of the timestamp related parameters are in this order. <br />
              ts &gt; (minutes = hours = days) &gt; (from_date = to_date). <br />
              If we do not pass any of those, then last 2 days data will be displayed. <br />
            </p>
          </CardBody>
        </Card>

        <Card>
          <CardHeader className='internal-dash-card-header'>
            <h6 className="my-0 fw-bold">
              API Demo Video
            </h6>
            <input type='text' ref={apiDemoVideo} className='form-control' 
              style={{width: 1, height: 1, border: 'none'}}/>
          </CardHeader>
          <CardBody className='mx-auto'>
            <iframe width="800" height="450" src="https://www.youtube.com/embed/6tYV_hXtGDU" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
          </CardBody>
        </Card>

        <Card>
          <CardHeader className='internal-dash-card-header'>
            <h6 className="my-0 fw-bold">
              Description of Server Responses
            </h6>
            <input type='text' ref={serverResponses} className='form-control' 
              style={{width: 1, height: 1, border: 'none'}}/>
          </CardHeader>
          <CardBody>
            <Table>
              <thead>
                <tr>
                  <td className='fw-bold'>Response Code</td>
                  <td className='fw-bold'>Meaning</td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    200
                  </td>
                  <td>
                    It simply means all is OK. Standard response for successful HTTP requests.
                  </td>
                </tr>
                <tr>
                  <td>301</td>
                  <td>Page has been moved permanently, and the new URL is available.</td>
                </tr>
                <tr>
                  <td>302</td>
                  <td>Page has been moved temporarily, and the new URL is available.</td>
                </tr>
                <tr>
                  <td>400</td>
                  <td>Bad Request. The server cannot or will not process the request due to an apparent client error.</td>
                </tr>
                <tr>
                  <td>401</td>
                  <td>Unauthorized. The header in your request did not contain the correct authorization codes.</td>
                </tr>
                <tr>
                  <td>403</td>
                  <td>Forbidden. You are forbidden to see the document you requested. It can also mean that the server doesn't have the ability to show you what you want to see.</td>
                </tr>
                <tr>
                  <td>404</td>
                  <td>Document not found. The page you want is not on the server nor has it ever been on the server. Most likely you have misspelled the title or used an incorrect capitalization pattern in the URL.</td>
                </tr>
                <tr>
                  <td>502</td>
                  <td>Bad Gateway. The request was not completed. The server received an invalid response from the upstream server.</td>
                </tr>
                <tr>
                  <td>503</td>
                  <td>The format or service you are requesting is temporarily unavailable.</td>
                </tr>
                <tr>
                  <td>504</td>
                  <td>The gateway as timed out. The server was acting as a gateway or proxy and did not receive a timely response from the upstream server.</td>
                </tr>
              </tbody>
            </Table>
          </CardBody>
        </Card>

        <Card>
          <CardHeader className='internal-dash-card-header'>
            <h6 className="my-0 fw-bold">API Examples</h6>
            <input type='text' ref={apiExampleRef} className='form-control' 
              style={{width: 1, height: 1, border: 'none'}}/>
          </CardHeader>
          <CardBody>
            <p>Please install the Unirest library before proceeding with the following steps.</p>
            <Nav tabs>
              <NavItem>
                <NavLink href="#" className={BasicTab === '1' ? 'active' : ''}
                  onClick={() => setBasicTab('1')}>
                  Ruby
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="#" className={BasicTab === '2' ? 'active' : ''} 
                  onClick={() => setBasicTab('2')}>
                  Python    
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="#" className={BasicTab === '3' ? 'active' : ''} 
                  onClick={() => setBasicTab('3')}>
                  NodeJS
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="#" className={BasicTab === '4' ? 'active' : ''} 
                  onClick={() => setBasicTab('4')}>
                  PHP
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="#" className={BasicTab === '5' ? 'active' : ''} 
                  onClick={() => setBasicTab('5')}>
                  Java
                </NavLink>
              </NavItem>     
            </Nav>

            <TabContent activeTab={BasicTab}>
              <TabPane className="fade show" tabId="1">
                <SyntaxHighlighter language="ruby" style={xcode}>
                  {codeString2}
                </SyntaxHighlighter>
                <h6 className="my-2">
                  We have a Ruby gem for programmable access to our API <a href="https://rubygems.org/gems/promptcloud_data_api">https://rubygems.org/gems/promptcloud_data_api</a>
                </h6>
              </TabPane>
              <TabPane tabId="2">
                <SyntaxHighlighter language="python" style={xcode}>
                  {codeString3}
                </SyntaxHighlighter>
                <h6 className="my-2">
                  We have a Python client programmable access to our API <a href="https://github.com/promptcloud/promptcloud_data_api_python">https://github.com/promptcloud/promptcloud_data_api_python</a>
                </h6>
              </TabPane>
              <TabPane tabId="3">
                <SyntaxHighlighter language="node-repl" style={xcode}>
                  {codeString4}
                </SyntaxHighlighter>
                <h6 className="my-2">
                  This script uses one more npm package along with unirest <a href="https://www.npmjs.com/package/download">https://www.npmjs.com/package/download</a>
                </h6>
              </TabPane>
              <TabPane tabId="4">
                <SyntaxHighlighter language="php" style={xcode}>
                  {codeString5}
                </SyntaxHighlighter>

              </TabPane>
              <TabPane tabId="5">
                <SyntaxHighlighter language="java" style={xcode}>
                  {codeString6}
                </SyntaxHighlighter>
                <h6 className="my-2">
                  We have implemented this using <b>Eclipse and Maven Tool</b>
                </h6>
              </TabPane>
            </TabContent> 
          </CardBody>
        </Card>
      </div>
    </Container>
)
}
