/******************************************************************************************************
 *                    This component is rendereed in the AllInvoices.index.jsx                        *                  
 *        It shows the names of the organizations as well as links to invoices for each product       *
 ******************************************************************************************************
 * CREATED BY: Aniket Atram                                                                           *
 * CREATED ON: 4 April 2023                                                                           *
 * UPDATED ON: 6 April 2023                                                                           *
 * **************************************************************************************************** 
 * ******************************************* PROPS **************************************************
 * *** Name Of Prop ***|***********************  Description  *****************************************
 * data                | The json object used for displaying data in data table                       *
 * pageNumber          | The page number used for server side paginations                             *
 * totalCount          | The total number of invoices present in the DB                               *
 * customSort          | Method used for sorting columns in ascending or descending order             *
 * sortedBy            | The name of the field by which the columns is sorted displayed in the title  *
 * paginationHandler   | Method to handle server side pagination                                      *
 * ***************************************************************************************************/

import React from 'react';
import { useState } from 'react'
import { Card, CardBody, Button } from 'reactstrap';
import moment from 'moment';
import DataTable from 'react-data-table-component'
import { toast } from 'react-toastify';
import axios from 'axios';

import SpinnerLoader from '../../../V2/Common/LoadingAnimation/SpinnerLoader.component'; // Loading animation from common components

export default function AllInvoicesTable(props) {

  const [loading, setLoading] = useState(false)

  const handleButtonClick = (e, invoice_id) => {
    e.preventDefault();
    setLoading(true);
    axios.get(`/api/v1/admins/invoices/${invoice_id}/resend_payment_job`).then(res => {
      setLoading(false)
      toast.success(res?.data?.message)
    }).catch(err => {
      setLoading(false)
      toast.error(err?.response?.data?.message)
    })
  };

  const allPaymentSynced = (row) => {
    switch(row?.all_payments_synced) {
      case true:
        return "Yes";
      case false:
        return <Button color='danger' className='m-1' size='xs' onClick={(e) => handleButtonClick(e, row?.invoice_id)}>Sync Invoice</Button>;
      default:
        return "--";
    }
  };

  // Table column definitions
  const columns = [
    {
      name: "Name",
      selector: "name",
      center: true,
      sortable: true
    },
    {
      name: "Identifier",
      selector: "identifier",
      center: true,
      sortable: true
    },
    {
      name: "Invoice ID",
      selector:"invoice_Id",
      cell: (row) => (
        row?.invoice_id === 'N/A'?'N/A':
        <><a href={`/organizations/${row?.identifier}/invoices/${row?.invoice_id}`} target='_blank'>{row?.invoice_id}</a></>
      ),
      center: true,
      sortable: true
    },
    {
      name: "FB or QB Invoice ID",
      selector:"fb_or_qb_invoice_Id",
      cell: (row) => (
        row?.fb_or_qb_invoice_Id === 'N/A'?'N/A':
        <><a href={row?.fb_or_qb_invoice_link} target='_blank'>{row?.fb_or_qb_invoice_Id}</a></>
      ),
      center: true,
      sortable: true
    },
    {
      name: "Invoice Number",
      selector: "invoice_number",
      center: true,
      sortable: true
    },
    {
      name: "Service Type",
      selector: "service_type",
      center: true,
    },
    {
      name: "Product Type",
      selector: "product_type",
      center: true,
    },
    {
      name: "Invoice Status",
      selector: "status_from_api",
      center: true,
    },
    {
      name: "Created At",
      selector: 'created_at',
      center: true,
    },
    {
      name: "All Payments Synced",
      selector: 'all_payments_synced',
      cell: (row) => (allPaymentSynced(row)),
      center: true,
    },
  ];

  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <SpinnerLoader loading={loading}>
            <DataTable
              title={`Displaying Website ${((props?.pageNumber - 1) * 20) + 1} - ${(props?.pageNumber * 20) > props?.totalCount ? props?.totalCount : props?.pageNumber * 20} of ${props?.totalCount} in total | Sort By ${props?.sortedBy.split('_').join(' ')}`}
              data={props?.data}
              columns={columns}
              highlightOnHover
              pagination
              paginationServer
              paginationPerPage={20}
              paginationTotalRows={props?.totalCount}
              paginationComponentOptions={{
                noRowsPerPage: true
              }}
              onChangePage={props?.paginationHandler}
              sortFunction={props?.customSort}
            />
          </SpinnerLoader>
        </CardBody>
      </Card>
    </React.Fragment>
  )
}
