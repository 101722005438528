// React imports
import React from 'react';
import { useMemo, useRef } from 'react';
import { useHistory } from 'react-router-dom';


// Third party imports
import { Row, Col, Table } from 'reactstrap';
import { toast } from 'react-toastify';
import axios from 'axios';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';

// Components and scripts imports
import PaymentUnderProcessNotifier from './PaymentUnderProcessNotifier/PaymentUnderProcessNotifier.component';
import OrganizationAddress from './OrganizationAddress/OrganizationAddress.Component';
import InvoiceTable from './InvoiceTable/InvoiceTable.component';


export default function PendingInvoice({ data, organization_name, invoiceId, setToggle, setLoading, razorpayKey }) {

  // API to which the POST request is sent to apply cupon
  const CUPON_APPLY_API_ENDPOINT = useMemo(() => `/api/v1/organizations/${organization_name}/redeem_coupons?page_type=client&controller_name=redeem_coupons`, [organization_name]);

  // API ENDPOINT FOR REMOVING THE CUPON APPLIED
  const REMOVE_CUPON_APPLIED_API_ENDPOINT = useMemo(() => `/api/v1/organizations/${organization_name}/redeem_coupons/${data?.org_redeem_coupon?.id}?page_type=client&controller_name=redeem_coupons`, [data]);
  
  const history = useHistory();

  const cuponCode = useRef(data?.org_redeem_coupon?.coupon_code || ''); // stores the cupon code entered by the user or pre-applied


  // Helper function to handle input chages in the cupon input field
  function handleCuponFieldInputChange(event) {
    event.preventDefault(); // Avoid any default behaviour 
    const { value } = event.target; // The cupon code entered by user

    // Store it in the ref 
    cuponCode.current = value;
  }

  // Helper function to apply a cupon code for discounts
  async function applyCupon(event) {
    event.preventDefault(); // Avoid any default behaviour of form when submit button  is clicked
    const applyRequestParameters = {
      redeem_coupons: {
        coupon_code: cuponCode.current,
        invoice_id: invoiceId
      }
    }
    try {
      const response = await axios.post(CUPON_APPLY_API_ENDPOINT, applyRequestParameters);
      toast.success(response?.data?.message);
      setToggle(curr => !curr);
    }
    catch (error) {
      toast.error(error?.response?.data?.message);
    }
  }

  //ValidationSchema for Coupon
  const validationSchema = Yup.object().shape({
    coupon_code: Yup.string()
  });

  // Submit Handler
  const applyCoupon = async (values, { setSubmitting, setFieldError }) => {
    const applyRequestParameters = {
      redeem_coupons: {
        coupon_code: values.coupon_code,
        invoice_id: invoiceId
      }
    };

    if(values?.coupon_code?.length == 0){
      setFieldError('coupon_code', "Coupon code is required..!")
      return
    }

    try {
      const response = await axios.post(CUPON_APPLY_API_ENDPOINT, applyRequestParameters);
      toast.success(response?.data?.message);
      setToggle(curr => !curr);
    } catch (error) {
      const errorMessage = error?.response?.data?.message || 'An error occurred';
      setFieldError('coupon_code', errorMessage);  
    }
    setSubmitting(false);
  };


  // Helper function to remove the applied cupon code
  async function removeAppliedCupon() {
    try {
      const response = await axios.delete(REMOVE_CUPON_APPLIED_API_ENDPOINT);
      toast.success(response?.data?.message);
      await setToggle(curr => !curr);
    }
    catch (error) {
      toast.error(error?.response?.status?.message);
    }
  }

  // Helper function to make payment for the invoice genetrated
  const makePayment = async (token) => {
    setLoading(true)
    axios.post(`/api/v1/organizations/${organization_name}/invoices/${invoiceId}/payments`, {
      razorpay_payment_id: token,
      page_type: "client",
      controller_name: "new_payments"
    }).then(res => {
      toast.success(res?.data?.message)
      setLoading(false)
      setToggle(curr => !curr)
      history.push(`/organizations/${organization_name}/invoices/${invoiceId}`)
    }).catch(err => {
      toast.error(err?.response?.data?.message)
      setToggle(curr => !curr)
      setLoading(false)
    })
  }

  // Helper function to make payment using RazorPay API
  const handleRPPayment = () => {
    var options = {
      "key": razorpayKey,
      "amount": data?.payable_amount_in_paise,
      "currency": "INR",
      "name": "Promptcloud Technologies",
      "description": `Payment for invoice #${data?.id}`,
      // TODO LOGO Here
      "image": "https://appv2.promptcloud.com/assets/PromptLogoNew12-ad045423017cb12f23b6c2ccb746518586f0cb77080d7cb38b1800af6a4b4c73.png",
      "theme": {
        "color": "black"
      },
      "handler": async function (res) {
        await makePayment(res?.razorpay_payment_id)
      }
    };
    var rzp1 = new Razorpay(options);
    rzp1.open();
    rzp1.on('payment.failed', function (response) {
      toast.error(`Payment failed - ${response?.error?.description}`)
      setToggle(curr => !curr)
      rzp1.close();
    })
  }

  return (
    <div>
      <div className='card'>
        <div className='card-body'>
          <div className='invoice'>
            <PaymentUnderProcessNotifier underProcess={data?.any_payments_being_processed} />
            <div>
              <div>
                <Row>
                  <Col sm="7">
                    <OrganizationAddress serviceToUse={data?.service_to_use} />
                  </Col>
                  <Col sm="5">
                    <div className='text-md-end text-xs-center'>

                      <h6 className="media-heading">To: {data?.to_address?.length === 2 ? data?.to_address[0] : data?.to_address[0]}</h6>

                      {
                        data?.to_address?.length !== 2 && data?.to_address.map((each, index) => {
                          return (
                            index !== 0 && <React.Fragment key={index}> <span> {each} </span> <br /> </React.Fragment>
                          )
                        }
                        )
                      }

                      {
                        data?.to_address?.length === 2 && data?.to_address[1].map((each, index) => {
                          return (
                            <React.Fragment key={index}> <span> {each} </span> <br /> </React.Fragment>
                          )
                        }
                        )
                      }

                    </div>

                  </Col>
                </Row>
              </div>
              <InvoiceTable data={data} />
              <div className="col-sm-12 text-center mt-4 mb-5">
                <Table responsive className='float-right w-auto'>
                  <tbody>
                    {data?.sub_total && <tr>
                      <td className="right-text fw-bold mx-5">
                        Subtotal:
                      </td>
                      <td className="right-text mx-5 ps-5">
                        {data?.sub_total}
                      </td>
                    </tr>}
                    {data?.gst && <tr>
                      <td className="right-text fw-bold mx-5">
                        GST:
                      </td>
                      <td className="right-text mx-5 ps-5">
                        {data?.gst}
                      </td>
                    </tr>}
                    {data?.total && <tr>
                      <td className="right-text fw-bold mx-5">
                        Total:
                      </td>
                      <td className="right-text mx-5 ps-5">
                        {data?.total}
                      </td>
                    </tr>}
                    {data?.tds_to_be_deposited_by_client && <tr>
                      <td className="right-text fw-bold mx-5">
                        TDS to be deposited by client :
                      </td>
                      <td className="right-text mx-5 ps-5">
                        {data?.tds_to_be_deposited_by_client}
                      </td>
                    </tr>}
                    {data?.amount_received && <tr>
                      <td className="right-text fw-bold mx-5">
                        Amount Received :
                      </td>
                      <td className="right-text mx-5 ps-5">
                        {data?.amount_received}
                      </td>
                    </tr>}
                    {data?.balance_due && <tr>
                      <td className="right-text fw-bold mx-5">
                        Balance Due :
                      </td>
                      <td className="right-text mx-5 ps-5">
                        {data?.balance_due}
                      </td>
                    </tr>}
                  </tbody>
                </Table>
              </div>
              <hr />
              <div className="row my-4">
                <div className="col-md-5">
                  {!(data?.freeze_discounts) && ((data?.is_coupon_applied) ?
                    <form onSubmit={() => removeAppliedCupon()} className="d-flex">
                      <input
                        type="text"
                        className="form-control me-1"
                        value={data?.org_redeem_coupon?.coupon_code}
                        disabled />
                      <button type="submit" className='btn btn-danger white-space-no-wrap'>
                        Remove Coupon
                      </button>
                    </form>
                    :  
                    <Formik
                    initialValues={{ coupon_code: '' }}
                    validationSchema={validationSchema}
                    onSubmit={applyCoupon}
                  >
                    {({ isSubmitting }) => (
                      <Form className="d-flex flex-row">
                        <div className="flex-grow-1">
                          <Field
                            type="text"
                            name="coupon_code"
                            className="form-control me-1"
                            placeholder="Enter coupon code"
                            style={{ height: '40px' }}
                          />
                          <ErrorMessage name="coupon_code" component="div" className='m-0 error-msg mt-1 mb-2' />
                        </div>
                        <button type="submit" className="btn btn-primary white-space-no-wrap" disabled={isSubmitting} style={{ height: '40px', marginLeft:"6px" }}>
                          {isSubmitting ? 'Applying...' : 'Apply Coupon'}
                        </button>
                      </Form>
                    )}
                  </Formik>)}
                </div>
                <div className="col-md-7">
                  {(data?.service_to_use === "freshbooks" && !(data?.any_payments_being_processed)) &&
                    <a href={`/organizations/${organization_name}/invoices/${invoiceId}/payments/new`}
                      className="btn btn-success text-white float-right">
                      Pay {data?.balance_due && data?.balance_due}
                    </a>}
                    {((data.service_to_use === "quickbooks") || (data.service_to_use === "zohobooks")) && !(data.any_payments_being_processed) && <button
                    onClick={() => handleRPPayment()} className="btn btn-success text-white float-right">
                    Pay {data?.balance_due && data?.balance_due}
                  </button>}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
