import React from 'react';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Container } from 'reactstrap';
import SuperUserContext from '../../Context/Providers/SuperUserPrivilege/SuperUserContext.component';
import BannerNotification from '../../Common/BannerNotification/BannerNotifications.component';
import BreadCrumbs from '../../Common/BreadCrumb/BreadCrumbs.component';
import OrganizationDetailsCard from './Cards/OrganizationDetialsCard.component';
import AdminOperationsCard from './Cards/AdminOperationsCard.component';

// custom hooks
import useOrgIdentifer from '@hooks/org_identifier';

export default React.memo(function Organization({ banner_notifications, current_user, current_organization, take_my_org_details }) {

  const history = useHistory();
  const org_identifier = useOrgIdentifer();

  useEffect(() => {
    take_my_org_details(org_identifier);
  }, [org_identifier]);

  return (
    <>
      <SuperUserContext>
        <Container fluid>
          <BreadCrumbs title={"Organization"} parent={"Organization Details"} />
          <BannerNotification notificationsArray={banner_notifications} />
          <OrganizationDetailsCard organization_name={org_identifier} />
          <AdminOperationsCard organization_name={org_identifier} />
        </Container>
      </SuperUserContext>
    </>
    )
})
