import React, {Fragment, forwardRef, useImperativeHandle,
  useState, useEffect, useRef} from 'react';
import { Card, CardBody, Row, Col, CardHeader} from 'reactstrap';
import axios from 'axios';
import Loader from 'react-loader-spinner';
import DataTable from 'react-data-table-component';
import { toast } from 'react-toastify';
import ScheduleCrawls from '../../sites/ScheduleCrawls';
import SetCrawlNotifications from '../../sites/SetCrawlNotifications';
import SetBillingAlarms from '../../sites/SetBillingAlarms';

const SitesTable = forwardRef(({orgName, current_organization, sitegroupNames, country, siteNames}, ref) => {
  const [siteData, setSiteData] = useState([]);
  const [siteStatus, setSiteStatus] = useState('All');
  const [loading, setLoading] = useState(true);
  const [searchVal, setSearchVal] = useState('');
  const [sitegroupId, setSitegroupId] = useState('');

  const scheduleCrawlsRef = useRef();
  const setCrawlNotificationsRef = useRef();

  const [page, setPage] = useState(1);
  const countPerPage = 10;
  const [pageTotal, setPageTotal] = useState(0);

  const columns = [
    {name: 'Site Name',selector: 'site_name',sortable: true},
    {name: 'Status',selector: 'site_status',sortable: true,},
    {name: 'Uploads in last 30 days',selector: 'uploads_in_last_30_days',sortable: true},
    {name: 'Uploads in last 24 hours',selector: 'uploads_in_last_24_hours',sortable: true},
    {name: 'Last Run Date',selector: 'last_run_date',sortable: true},
    {name: 'Next Scheduled Run',selector: 'next_scheduled_run_date',sortable: true},
    {name: 'Queue count (Urls in queue)',selector: 'queue_count', sortable: true},
  ];

  const handleChange = ({selectedRows}) => {
    scheduleCrawlsRef.current.handleRowChange(selectedRows);
    setCrawlNotificationsRef.current.handleRowChange(selectedRows);
  };

  const handleSiteStatus = (e) => {
    let siteStatusVal = e.target.value;
    setSiteStatus(siteStatusVal);
    let siteUrl;
    if (orgName && sitegroupId != ''){
      siteUrl = `/api/v1/organizations/${orgName}/sites?filterrific[filter_by_site_status]=${siteStatusVal}&sitegroup_id=${sitegroupId}&page=${1}&page_type=client&controller_name=sites`;
    }
    else{
      siteUrl = `/api/v1/organizations/${orgName}/sites?filterrific[filter_by_site_status]=${siteStatusVal}&page=${1}&page_type=client&controller_name=sites`;
    }
    setLoading(true);
    axios.get(siteUrl)
      .then(res => {
        setSiteData(res.data.sites);
        setPageTotal(res.data?.total_sites_count);
        setLoading(false)
      })
      .catch(err => {
        toast.error(err.response.data.message)
        setLoading(false)
      }) 
  };

  const handleSearch = (e) => {
    let siteSearchVal = e.target.value;
    setSearchVal(siteSearchVal);
    let siteUrl;
    if (orgName && sitegroupId != ''){
      siteUrl = `/api/v1/organizations/${orgName}/sites?filterrific[search_by_site_name]=${siteSearchVal}&sitegroup_id=${sitegroupId}&page=${1}&page_type=client&controller_name=sites`;
    }
    else{
      siteUrl = `/api/v1/organizations/${orgName}/sites?filterrific[search_by_site_name]=${siteSearchVal}&page=${1}&page_type=client&controller_name=sites`;
    }
    setLoading(true);
    axios.get(siteUrl)
      .then(res => {
        setSiteData(res.data.sites);
        setPageTotal(res.data?.total_sites_count);
        setLoading(false)
      })
      .catch(err => {
        toast.error(err.response.data.message)
        setLoading(false)
      }) 
  };

  const handlePageChange = (pageNum) => {
    setPage(pageNum)
    let siteUrl;
    if (orgName && sitegroupId != ''){
      siteUrl = `/api/v1/organizations/${orgName}/sites?filterrific[filter_by_site_status]=${siteStatus}&filterrific[search_by_site_name]=${searchVal}&sitegroup_id=${sitegroupId}&page=${pageNum}&page_type=client&controller_name=sites`;
    }
    else{
      siteUrl = `/api/v1/organizations/${orgName}/sites?filterrific[filter_by_site_status]=${siteStatus}&filterrific[search_by_site_name]=${searchVal}&page=${pageNum}&page_type=client&controller_name=sites`;
    }
    setLoading(true);
    axios.get(siteUrl)
      .then(res => {
        setSiteData(res.data.sites);
        setPageTotal(res.data?.total_sites_count);
        setLoading(false);
      })
      .catch(err => {
        toast.error(err.response.data.message);
        setLoading(false);
      }) 
  };

  useEffect(() => {
    setLoading(true);
    axios.get(`/api/v1/organizations/${orgName}/sites?page=${1}&page_type=client&controller_name=sites`)
      .then(res => {
        setSiteData(res.data.sites);
        setPageTotal(res.data?.total_sites_count);
        setLoading(false)
      })
      .catch(err => {
        toast.error(err.response.data.message)
        setLoading(false)
      }) 
  }, [orgName])

  useImperativeHandle(ref, () => ({
    getSitesBySitegroupId(sitegroupId){
      setSitegroupId(sitegroupId)
      if (orgName && sitegroupId != ''){
        setLoading(true);
        axios.get(`/api/v1/organizations/${orgName}/sites?sitegroup_id=${sitegroupId}&page=${1}&page_type=client&controller_name=sites`)
          .then(res => {
            setSiteData(res.data.sites);
            setPageTotal(res.data?.total_sites_count);
            setLoading(false)
          })
          .catch(err => {
            toast.error(err.response.data.message)
            setLoading(false)
          }) 
      }
    }
  }));

  const rowDisableCriteria = (each) => each?.site_status != "active"

  return (
    <Card className='my-2'>
      <CardBody>
        <CardHeader className='m-0 p-4 d-flex align-center'>
          Bulk Actions:
          <ScheduleCrawls orgName={orgName} sitegroupNames={sitegroupNames} siteNames={siteNames} ref={scheduleCrawlsRef} />
          <SetCrawlNotifications orgName={orgName} sitegroupNames= {sitegroupNames} ref={setCrawlNotificationsRef} />
          <SetBillingAlarms orgName={orgName} country={country} current_organization={current_organization} />
        </CardHeader>
        <div>
          <Row className='my-4'>
            <Col lg={4} className='d-flex align-center'>
              <h6 className='m-0 p-0 me-2'> Status: </h6>
              <select defaultValue={siteStatus} className='form-control show-arrow'
                onChange={(e) => handleSiteStatus(e)} >
                <option value="all">All</option>
                <option value="under_review">Under Review</option>
                <option value="report_generated">Report Generated</option>
                <option value="payment_pending">Payment Pending</option>
                <option value="queued">Queued</option>
                <option value="setup_in_progress">Setup In Progress</option>
                <option value="waiting_on_client">Waiting On Client</option>
                <option value="active">Active</option>
                <option value="pause_requested">Pause Requested</option>
                <option value="paused">Paused</option>
                <option value="archived">Archived</option>
              </select>
            </Col>
            <Col lg={3}>

            </Col>
            <Col lg={5} className='d-flex align-center'>
              <h6 className='m-0 p-0 me-2'> Search: </h6>
              <input className='form-control' type='text' value={searchVal}
                onChange={(e) => handleSearch(e)} />
            </Col>
          </Row>
          <DataTable data={siteData} columns={columns} 
            onSelectedRowsChange={handleChange}
            selectableRowDisabled={rowDisableCriteria}
            responsive noHeader selectableRows className='mydatatable' 
            pagination paginationServer progressPending={loading}
            paginationTotalRows={pageTotal} paginationPerPage={countPerPage}
            paginationComponentOptions={{
              noRowsPerPage: true
            }} onChangePage={handlePageChange}
          />
        </div>
      </CardBody>
    </Card>
  )
});

export {SitesTable as default}
