import React from 'react';
import { useMemo } from 'react';
import { Form, FormGroup, Input, Label, Button } from 'reactstrap';
import countryList from 'react-select-country-list';
export default function BillingDetailsForm({ error, setError, address, setAddress, handleBilling, buttonLoading, indianStates}) {

	// List of countries
	const countries = useMemo(() => countryList().getData(), []);

	return (
		<div>
			<div className='login-card'>
				<div className='login-main login-tab'>
					<Form className="theme-form needs-validation" noValidate=""
						onSubmit={handleBilling}>
						<h4>Update Billing Address</h4>
						<p>Please enter your billing address to proceed</p>
						<FormGroup>
							<Label className="col-form-label">Organization Name*</Label>
							<Input className="form-control" name="organization_name"
								value={address.organization_name || ""}
								onChange={(e) => {
									setAddress({ ...address, organization_name: e.target.value })
									e.target.value.length > 0 ? setError({ ...error, organization_name: false }) : setError({ ...error, organization_name: true })
								}}
								type="text" />
							<span className="error-msg">
								{error?.organization_name && 'Organization Name is required'}
							</span>
						</FormGroup>

						{(address.country && address.country === "IN") &&
							<FormGroup>
								<Label className="col-form-label">GSTIN</Label>
								<Input className="form-control" type="text" name="gstin"
									value={address.gstin_id || ""}
									onChange={(e) => setAddress({ ...address, gstin_id: e.target.value })}
									placeholder="Please enter your 15 character GSTIN ID" />
                <span className="error-msg">
                  {error?.gstin_id && 'GST number should be a valid number!'}
                </span>
							</FormGroup>}

						<FormGroup>
							<Label className="col-form-label">Line 1*</Label>
							<Input className="form-control" type="text" name="line1"
								value={address.line1 || ""}
								onChange={(e) => {
									setAddress({ ...address, line1: e.target.value })
									e.target.value.length > 0 ? setError({ ...error, line1: false }) : setError({ ...error, line1: true })
								}} />
							<span className="error-msg">
								{error?.line1 && 'This field is required'}
							</span>
						</FormGroup>

						<FormGroup>
							<Label className="col-form-label">Line 2</Label>
							<Input className="form-control" type="text" name="line2"
								value={address.line2 || ""}
								onChange={(e) => setAddress({ ...address, line2: e.target.value })} />
						</FormGroup>

						<FormGroup>
							<Label className="col-form-label">City*</Label>
							<Input className="form-control" type="text" name="city"
								value={address.city || ""}
								onChange={(e) => {
									e.target.value.length > 0 ? setError({ ...error, city: false }) : setError({ ...error, city: true })
									setAddress({ ...address, city: e.target.value })
								}} />
							<span className="error-msg">
								{error?.city && 'City is required'}
							</span>
						</FormGroup>

						{(address.country && address.country == "IN") &&
							<FormGroup>
								<Label className="col-form-label">State*</Label>
								<select value={address.state || "none"}
									onChange={(e) => {
										setAddress({ ...address, state: e.target.value })
										e.target.value.length > 0 ? setError({ ...error, state: false }) : setError({ ...error, state: true })
									}}
                  name="state"
									className='form-control show-arrow'>
									{indianStates?.map(each => {
									return (
										<option value={each.value} key={each.value}>{each.label}</option>
									)
								})}
								</select>
								<span className="error-msg">
									{error?.state && 'State is required'}
								</span>
							</FormGroup>}

						<FormGroup>
							<Label className="col-form-label">Country*</Label>
							<select value={address.country || "none"}
								onChange={(e) => setAddress({ ...address, country: e.target.value })}
								className='form-control show-arrow' name="country" disabled>
								<option value="none">
									select a country
								</option>
								{countries?.map(each => {
									return (
										<option value={each.value} key={each.value}>
											{each.label}
										</option>
									)
								})}
							</select>
							<span className="error-msg">
								{error?.country && 'country is required'}
							</span>
						</FormGroup>
						<FormGroup>
							<Label className="col-form-label">ZIP CODE </Label>
							<Input className="form-control" type="text" name="zipcode"
								value={address.zip || ""}
								onChange={(e) => setAddress({ ...address, zip: e.target.value })} />
						</FormGroup>

						<div className="form-group mb-0">
							<Button color="success" disabled={buttonLoading} className="btn-block w-100 mt-3">
								Submit
							</Button>
						</div>
					</Form>
				</div>
			</div>
		</div>
	)
}
