import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Row, Col } from 'reactstrap';
import { Container } from 'react-bootstrap';
import { useState, useEffect, useMemo, useContext } from 'react';
import Breadcrumbs from '../../../global/Breadcrumb';
import BannerNotifications from './BannerNotifications/BannerNotifications.component';
import Loader from 'react-loader-spinner';
import UploadsCountChart from './Charts/UploadsCountChart.component';
import UploadedFilesCard from './Cards/UploadedFilesCard.component';
import GettingStartedCard from './Cards/GettingStartedCard.component';
import axios from 'axios';
import { toast } from 'react-toastify';

// custom hooks
import useOrgIdentifer from '@hooks/org_identifier';

export default function ClientHome({ banner_notifications, current_organization, current_user, take_my_org_details }) {

  const history = useHistory()
  const org_identifier = useOrgIdentifer();

  const [data, setData] = useState([]);
  const [series, setSeries] = useState([]);
  const [loading, setLoading] = useState(false);
  const [chartCategory, setChartCategory] = useState([]);

  useEffect(() => {
    take_my_org_details(org_identifier);
  }, [org_identifier]);

  // Populate the data for each organization  
  useEffect(() => {
    if (org_identifier) {
      setLoading(true)
      axios.get(`/api/v1/organizations/${org_identifier}/dashboard/fetch_dashboard_details_from_es?page_type=client&controller_name=dashboard`).then(res => {
        setData(res?.data?.dashboard_details)
        setSeries([{
          name: 'upload count',
          data: res?.data?.dashboard_details?.uploads_count
        }])
        setChartCategory(res?.data?.dashboard_details?.days);
        setLoading(false)
      }).catch(err => {
        setLoading(false)
      })
    }
  }, [org_identifier]);

  const chartOptions = useMemo(() => {
    return {
      chart: {
        height: 240,
        type: 'area',
        toolbar: {
          show: false
        },
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: 'smooth'
      },
      xaxis: {
        type: 'category',
        low: 0,
        offsetX: 0,
        offsetY: 0,
        show: false,
        categories: chartCategory,
        labels: {
          low: 0,
          offsetX: 0,
          show: false,
        },
        axisBorder: {
          low: 0,
          offsetX: 0,
          show: false,
        },
        axisTicks: {
          show: false,
        },
      },
      markers: {
        strokeWidth: 3,
        colors: "#ffffff",
        strokeColors: ['#f73164'],
        hover: {
          size: 6,
        }
      },
      yaxis: {
        low: 0,
        offsetX: 0,
        offsetY: 0,
        show: false,
        labels: {
          low: 0,
          offsetX: 0,
          show: false,
        },
        axisBorder: {
          low: 0,
          offsetX: 0,
          show: false,
        },
      },
      grid: {
        show: false,
        padding: {
          left: 0,
          right: 0,
          bottom: -15,
          top: -40
        }
      },
      colors: ['#f73164'],
      fill: {
        type: 'gradient',
        gradient: {
          shadeIntensity: 1,
          opacityFrom: 0.7,
          opacityTo: 0.5,
          stops: [0, 80, 100]
        }
      },
      legend: {
        show: false,
      },
      tooltip: {
        x: {
          format: 'MM'
        },
      },
    }
  }, [chartCategory]);


  return (
    <div>
      <Container fluid>
        <Breadcrumbs parent={"Overview"} title="Welcome to CrawlBoard" />
        <BannerNotifications notifications={banner_notifications} />
        <Container fluid>
          <UploadsCountChart data={data} chartOptions={chartOptions} chartSeries={series} loading={loading} />
          <Row>
            <Col md='6'>
              <UploadedFilesCard />
            </Col>
            <Col md='6'>
              <GettingStartedCard />
            </Col>
          </Row>
        </Container>
      </Container>
    </div>
  )
}
