import React, {useState, useEffect, useCallback} from 'react'
import { useParams } from "react-router-dom";
import { CardBody, Col, Card, Container, Row, Button } from 'reactstrap';
import axios from 'axios'
import { toast } from 'react-toastify';
import Loader from 'react-loader-spinner';
import DatePicker from 'react-datepicker'
import {useHistory} from 'react-router-dom'
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';

import Breadcrumb from '../../global/Breadcrumb'
import MultiSelectField from './shared_components/MultiSelectField';

export default function EditCoupon() {

  let { id } = useParams()
  const history = useHistory()
  const [data, setData] = useState({})
  const [loading, setLoading] = useState(true)
  const [disableButton, setDisableButton] = useState(false)
  const [organizationOptions, setOrganizationOptions] = useState([{
    value: 'All', label: 'All'
  }])
  const [productOptions, setProductOptions] = useState([{value:'All', label:"All"}])
  const [dataStockOptions, setDataStockOptions] = useState([])
  const [jobspikrOptions, setJobspikrOptions] = useState([])
  const [frequencyOptions, setFrequencyOptions] = useState([])
  const [allowedPlansOptions, setAllowedPlansOptions] = useState([])

  useEffect(() => {
    if(id){
      setLoading(true)
      axios.get(`/api/v2/admins/coupons/${id}/edit`).then(res => {
        if (res.data?.coupon_data?.organizations?.length > 0){
          setOrganizationOptions([{ value: 'All', label: 'All'},...res.data?.coupon_data?.organizations])
        } else {
          setOrganizationOptions([{ value: 'All', label: 'All'}])
        }
        setProductOptions(res.data?.options?.all_products)
        setDataStockOptions(res.data?.options?.ds_product_categories?.map(each => ({value: each, label: each})))
        setJobspikrOptions(res.data?.options?.jp_product_categories?.map(each => ({value: each[1], label: each[0]})))
        setFrequencyOptions(res.data?.options?.jp_frequencies?.map(each => ({value: each[1], label: each[0]})))
        setAllowedPlansOptions(res.data?.options?.jp_current_plans?.map(each => ({value: each[1], label: each[0]})))
        setData(res.data.coupon_data)
        setLoading(false)
      }).catch(err => {
        console.log('This is Error -->',err)
        toast.error(err?.data?.message)
        setLoading(false)
      })
    }
  }, [id])

  const checkIsSelected = useCallback((array, string) => {
    return array?.some(item => item?.value === string) || false
  },[]) 

  const filterSelectedValues = useCallback((items = []) => items.map(item => item.value).filter(item => item !== 'All'), []) 

  const validationSchema = Yup.object().shape({
    coupon_code: Yup.string().required("Please enter coupon code to continue"),
    allowed_on_organizations_ids: Yup.array().of(
      Yup.object({})
    ),
    max_usage_per_org: Yup.string().required("Please enter 0 if there is no restriction").matches(/^\d+(\.\d+)?$/, 'Please enter valid max usage'),
    selected_products: Yup.array().of(
      Yup.object({})
    ),
    selected_data_stocks : Yup.array().of(
      Yup.object({})
    ),
    selected_jobspikr_products: Yup.array().of(
      Yup.object({})
    ),
    selected_frequency: Yup.array().of(
      Yup.object({})
    ),
    selected_plans: Yup.array().of(
      Yup.object({})
    ),
    selected_jp_frequency: Yup.array().of(
      Yup.object({})
    ),
    selected_jp_plans: Yup.array().of(
      Yup.object({})
    ),
    coupon_type: Yup.string().required("Coupon type required"),
    discount_value: Yup.string()
      .required('Discount value is required')
      .matches(/^\d+(\.\d+)?$/, 'Please enter valid discount value'),
    start_at: Yup.date().required('Start date is required'),
    expire_at:Yup.date().required('End date is required') 
  })

  const handleSubmit = (values) => {

    let payload = {
      coupon_code: values?.coupon_code,
      discount_type: values?.coupon_type,
      discount_value: Number(values?.discount_value).toFixed(2),
      max_usage_per_org: values?.max_usage_per_org,
      start_at: values?.start_at,
      expire_at: values?.expire_at,
      allowed_on_organizations_ids: values?.allowed_on_organizations_ids
        .filter(item => Number.isInteger(item.value))
        .map(item => item.value),
      allowed_on_products: filterSelectedValues(values?.selected_products),
      for_new_org_only: values?.allow_only_for_new_org,
    }
  
    const selectedProductValues = filterSelectedValues(values?.selected_products);
    const selectedDataStockValues = filterSelectedValues(values?.selected_data_stocks);
    const selectedJobspikrValues = filterSelectedValues(values?.selected_jobspikr_products);
    const selectedFrequencyValues = filterSelectedValues(values?.selected_frequency);
    const selectedPermissionsValues = filterSelectedValues(values?.selected_plans);
    const selectedJpFrequencyValues = filterSelectedValues(values?.selected_jp_frequency);
    const selectedJpPlansValues = filterSelectedValues(values?.selected_jp_plans);
  
  
    let resObj1 = {}
  
    if (selectedProductValues.includes("data_stocks") && selectedDataStockValues.length > 0) {
      resObj1["data_stocks"] = { product_categories: selectedDataStockValues };
    }
  
    if (selectedProductValues.includes("jobspikr")) {
      if (selectedJobspikrValues.length > 0) {
        resObj1.jobspikr = {
          ...resObj1.jobspikr,
          product_categories: selectedJobspikrValues,
        };
      }
  
      if (selectedJobspikrValues.includes("job_data")) {
        if (selectedFrequencyValues.length > 0) {
          resObj1['jobspikr'] = { ...resObj1.jobspikr, frequencies: selectedFrequencyValues };
        }
        if (selectedPermissionsValues.length > 0) {
          resObj1['jobspikr'] = { ...resObj1.jobspikr, plans: selectedPermissionsValues };
        }
      }
  
      if (selectedJobspikrValues.includes("jobspikr_insights")) {
        if (selectedJpFrequencyValues.length > 0) {
          resObj1['jobspikr'] = { ...resObj1.jobspikr, jobspikr_insights_frequencies: selectedJpFrequencyValues };
        }
        if (selectedJpPlansValues.length > 0) {
          resObj1['jobspikr'] = { ...resObj1.jobspikr, jobspikr_insights_plans: selectedJpPlansValues };
        }
      }
    }

    payload["allowed_product_categories"] = resObj1

    axios.put(`/api/v2/admins/coupons/${id}`, { coupon: payload}).then(res => {
        toast.success(res.data?.message)
        history.push('/admins/coupons')
        setDisableButton(false)
    }).catch(err => {
        toast.error(err?.response?.data?.message)
        setDisableButton(false)
    })
  
  }

  const mapItemsToOptions = (items, defaultLabel = 'All') => {
    let values =  items?.map(item => ({ value: item, label: item }))  ||  []
    let defaultValues = [{ value: defaultLabel, label: defaultLabel }]
    if(values?.length > 0){
      return values
    } else {
      return defaultValues
    }
  }

  
  return(
      <Container fluid>
        <Breadcrumb title={"New Coupon"} parent={"Discount Coupon"}
        childrenObj={[{name: 'Coupons', url: '/admins/coupons'}]} />
        <Formik
        initialValues={{
          coupon_code: data?.coupon_code || '',
          allowed_on_organizations_ids: data?.allowed_on_organizations_ids?.length > 0 ? data?.allowed_on_organizations_ids : [{label:"All",value:"All"}],
          max_usage_per_org: data?.max_usage_per_org || 0,
          selected_products: mapItemsToOptions(data?.allowed_on_products),
          selected_data_stocks: mapItemsToOptions(data?.allowed_product_categories?.data_stocks?.product_categories, 'All'),
          selected_jobspikr_products: mapItemsToOptions(data?.allowed_product_categories?.jobspikr?.product_categories, 'All'),
          selected_frequency: mapItemsToOptions(data?.allowed_product_categories?.jobspikr?.frequencies, 'All'),
          selected_plans: mapItemsToOptions(data?.allowed_product_categories?.jobspikr?.plans, 'All'),
          selected_jp_frequency: mapItemsToOptions(data?.allowed_product_categories?.jobspikr?.jobspikr_insights_frequencies, 'All'),
          selected_jp_plans: mapItemsToOptions(data?.allowed_product_categories?.jobspikr?.jobspikr_insights_plans, 'All'),
          discount_value: data?.discount_value || '',
          coupon_type: data?.discount_type || '',
          allow_only_for_new_org: data?.for_new_org_only || false,
          start_at: new Date(data?.start_at),
          expire_at: new Date(data?.expire_at)
        }}
        validationSchema={validationSchema}
        enableReinitialize={true}
        onSubmit={(values)=> handleSubmit(values)}
        >
        {({ values })=>{
            return(
                <Form>
                  <Card>
                    <CardBody>
                      {
                        loading 
                        ?
                        <Loader type="Puff" color="#00BFFF" height={60} width={60} className='pc-loader' /> 
                        :
                        <>
                          <Row className='my-4'>
                            <Col sm={3}>
                              <h6 className='fw-bold my-2 float-end'>Coupon Code*</h6>
                            </Col>
                            <Col sm={9}>
                              <Field
                                type="text"
                                name="coupon_code"
                                placeholder="required"
                                className="form-control"
                                disabled={true}
                              />
                              <ErrorMessage name="coupon_code" component="p" className='m-0 error-msg mt-1 mb-2' />
                            </Col>
                          </Row>
                          <Row className='my-4'>
                            <Col sm={3}>
                                <h6 className='fw-bold my-2 float-end'>Allowed on Organizations</h6>
                            </Col>
                            <Col sm={9}>
                                <MultiSelectField name="allowed_on_organizations_ids" options={organizationOptions} />
                                <p className='text-muted'>If nothing is selected it will be applied to everything</p>
                            </Col>
                          </Row>
                          <Row className='my-4'>
                              <Col sm={3}>
                                  <h6 className='fw-bold my-2 float-end'>Max usage per Organization*</h6>
                              </Col>
                              <Col sm={9}>
                                <Field 
                                type='text'
                                className='form-control'
                                name="max_usage_per_org" 
                                placeholder='if there is no restriction enter 0'
                                />
                                <p className='text-muted my-0'>if there is no restriction enter 0</p>
                                <ErrorMessage name="max_usage_per_org" component="p" className='m-0 error-msg mt-1 mb-2' />
                              </Col>
                          </Row>
                          <Row className='my-4'>
                              <Col sm={3}>
                                  <h6 className='fw-bold my-2 float-end'>Products</h6>
                              </Col>
                              <Col sm={9}>
                                <MultiSelectField name="selected_products" options={productOptions}/>
                                <p className='text-muted my-0'>If nothing is selected it will be applied to everything</p>
                              </Col>
                          </Row>
                          {
                            checkIsSelected(values?.selected_products, 'data_stocks') && 
                            <Row className='my-4'>
                              <Col sm={3}>
                                  <h6 className='fw-bold my-2 float-end'>DataStock Product Categories</h6>
                              </Col>
                              <Col sm={9}>
                                  <MultiSelectField name="selected_data_stocks" options={dataStockOptions}/>
                                  <p className='text-muted my-0'>If nothing is selected it will be applied to everything</p>
                              </Col>
                            </Row>
                          }
                          {
                            checkIsSelected(values?.selected_products, 'jobspikr') && 
                            <Row className='my-4'>
                              <Col sm={3}>
                                  <h6 className='fw-bold my-2 float-end'>Jobspikr Product Categories</h6>
                              </Col>
                              <Col sm={9}>
                                <MultiSelectField name='selected_jobspikr_products' options={jobspikrOptions}/>
                                <p className='text-muted my-0'>If nothing is selected it will be applied to everything</p>
                              </Col>
                            </Row>
                          }
                          {
                            ( checkIsSelected(values?.selected_products, 'jobspikr') && checkIsSelected(values?.selected_jobspikr_products, 'job_data')) && 
                            <Row className='my-4'>
                              <Col sm={3}>
                                  <h6 className='fw-bold my-2 float-end'>Allowed on Frequencies</h6>
                              </Col>
                              <Col sm={9}>
                                  <MultiSelectField name="selected_frequency" options={frequencyOptions}/>
                                  <p className='text-muted my-0'>If nothing is selected it will be applied to everything</p>
                              </Col>
                            </Row>
                          }
                          {
                            ( checkIsSelected(values?.selected_products, 'jobspikr') && checkIsSelected(values?.selected_jobspikr_products, 'job_data')) && 
                            <Row className='my-4'>
                              <Col sm={3}>
                                <h6 className='fw-bold my-2 float-end'>Allowed on Plans</h6>
                              </Col>
                              <Col sm={9}>
                                <MultiSelectField name="selected_plans" options={allowedPlansOptions}/>
                                <p className='text-muted my-0'>If nothing is selected it will be applied to everything</p>
                              </Col>
                            </Row>
                          }
                          {
                            (checkIsSelected(values?.selected_products, 'jobspikr') && checkIsSelected(values?.selected_jobspikr_products, 'jobspikr_insights')) && 
                              <Row className='my-4'>
                                <Col sm={3}>
                                    <h6 className='fw-bold my-2 float-end'>Allowed on Jp Insight Frequencies</h6>
                                </Col>
                                <Col sm={9}>
                                  <MultiSelectField name="selected_jp_frequency" options={frequencyOptions}/>
                                  <p className='text-muted my-0'>If nothing is selected it will be applied to everything</p>  
                                </Col>
                            </Row>
                           }
                           {
                              (checkIsSelected(values?.selected_products, 'jobspikr') && checkIsSelected(values?.selected_jobspikr_products, 'jobspikr_insights')) && 
                              <Row className='my-4'>
                                <Col sm={3}>
                                    <h6 className='fw-bold my-2 float-end'>Allowed on JP Insights Plans</h6>
                                </Col>
                                <Col sm={9}>
                                  <MultiSelectField name="selected_jp_plans" options={allowedPlansOptions}/>
                                  <p className='text-muted my-0'>If nothing is selected it will be applied to everything</p>                                 
                                </Col>
                              </Row>
                            }
                          <Row className='my-4'>
                            <Col sm={3}>
                                <h6 className='fw-bold my-2 float-end'>Coupon Type</h6>
                            </Col>
                            <Col sm={9}>
                              <Field
                                name="coupon_type"
                                as="select"
                                className="form-control"
                              >
                                <option value="fixed">Fixed</option>
                                <option value="percent">Percent</option>
                              </Field>
                              <ErrorMessage name="coupon_type" component="p" className='m-0 error-msg mt-1 mb-2' />
                            </Col>
                          </Row>
                          <Row className='my-4'>
                            <Col sm={3}>
                                <h6 className='fw-bold my-2 float-end'>Discount Value*</h6>
                            </Col>
                            <Col sm={9}>
                              <Field 
                               type='text'
                               className='form-control'
                               name="discount_value"
                              />
                              <ErrorMessage name="discount_value" component="p" className='m-0 error-msg mt-1 mb-2' />
                            </Col>
                          </Row>
                          <Row className='my-4'>
                            <Col sm={3}>
                                <h6 className='fw-bold my-2 float-end'>Allow only for new Org</h6>
                            </Col>
                            <Col sm={9}>
                              <Field
                                name="allow_only_for_new_org"
                                type="checkbox"
                                className="form-check form-checkbox-align-top"
                              />
                            </Col>
                          </Row>
                          <Row className='my-4'>
                            <Col sm={3}>
                                <h6 className='fw-bold my-2 float-end'>Start At*</h6>
                            </Col>
                            <Col sm={9}>
                              <Field name="start_at">
                                {({ field }) => (
                                  <DatePicker
                                    id="freqMonthly"
                                    {...field}
                                    className="form-control digits"
                                    selected={field.value || new Date}
                                    minDate={new Date(Date.now())}
                                    onChange={(date) => {
                                      field.onChange({ target: { name: field.name, value: date } });
                                    }}
                                  />
                                )}
                              </Field>
                              <ErrorMessage name="start_at" component="p" className='m-0 error-msg mt-1 mb-2' />
                            </Col>
                          </Row>
                          <Row className='my-4'>
                            <Col sm={3}>
                                <h6 className='fw-bold my-2 float-end'>Expire At*</h6>
                            </Col>
                            <Col sm={9}>
                              <Field name="expire_at">
                                {({ field }) => (
                                  <DatePicker
                                    id="freqMonthly"
                                    {...field}
                                    className="form-control digits"
                                    selected={field.value}
                                    minDate={values?.start_at}
                                    onChange={(date) => {
                                      field.onChange({ target: { name: field.name, value: date } });
                                    }}
                                  />
                                )}
                              </Field>
                              <ErrorMessage name="expire_at" component="p" className='m-0 error-msg mt-1 mb-2' />
                            </Col>
                          </Row>
                          <Button color='success' className='float-end' type='submit'
                            disabled={disableButton}>
                            Submit
                          </Button>
                        </>
                      }
                    </CardBody>
                  </Card>
                </Form>
            )
        }}
        </Formik>
      </Container>
  )
}


