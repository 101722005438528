import React, {useState, useEffect} from 'react'
import { Form, FormGroup, Label } from 'reactstrap'
import { setItem, userLoggedin, userRegistered } from  '../localStorageMethods'
import { toast } from 'react-toastify'
import {useForm} from 'react-hook-form'
import { useHistory } from "react-router-dom";
import axios from 'axios'
import Loader from 'react-loader-spinner'
import ReCAPTCHA from "react-google-recaptcha";

export default function Users_signup() {
    const { register, handleSubmit, formState: { errors } } = useForm({
        mode: 'onChange'
    });
    const [mail, setEmail] = useState('')
    const [messge, setMessge] = useState('')
    const [loading, setLoading] = useState(true);
    const [buttonDisable, setButtonDisable] = useState(false)
    let history = useHistory();

  const [recaptcha, setRecaptcha] = useState("")
  const [recaptchaSiteKey, setRecaptchaSiteKey] = useState("")
  const [recaptchaEnabled, setRecaptchaEnabled] = useState("")

  useEffect(() => {
    axios.get('/api/v1/google_recaptcha_site_key?page_type=client&controller_name=users').then((response) => {
      setRecaptchaSiteKey(response?.data?.recaptcha_site_key)
      setRecaptchaEnabled(response?.data?.recaptcha_enabled)
    });
  }, [])

  function onChange(value) {
    setRecaptcha(value);
  }

    const onSubmit = data => {
        if (data !== '') {
            handleSignUp(data)
        } else {
            errors.showMessages();
        }
    };

    useEffect(() => {
        if (userLoggedin()){
            toast.warning('You are loggedin already')
            history.push('/')
        }
        else if (userRegistered()){
            toast.warning('Please complete your signup')
            history.push('/users/confirm_email')
        }
        setLoading(false)
    }, [])

    const handleSignUp = (data) => {
        setButtonDisable(true)
        axios.post('/api/v1/users/create', {
            user: {
                first_name: data.firstname,
                last_name: data.lastname,
                designation: data.role,
                email: data.email, 
                request_url: `${window.location.href}`
            },
          "g-recaptcha-response": recaptcha,
          controller_name: "users",
          page_type: "client"
        })
        .then(res => {
            let data1 = res.data
            if(data1.status == 'success'){
                setItem('authEmail', data1.email).then(() => {
                    let fullname = data.firstname + " " + data.lastname
                    setItem('user_name', fullname).then(() => {
                        toast.success(`${data1.message}`)
                    })
                })
                history.push('/users/confirm_email')
            }
            else{
                toast.error(data1.message)
            }
            setButtonDisable(false)
        })
        .catch(err => {
            if (err.response?.status == 422){
                window.location.reload(true)
            }
            toast.error(err.response.data.message)
            setButtonDisable(false)
        })
    }

    const isSame = (email2) => {
        return mail === email2
    }

    const checkEmail = async (email) => {
        setEmail(email)
        let result = false
        await axios.post('/api/v1/users/validate_uniqness', {
          email,
          page_type: "client",
          controller_name: "users"
        }).then((res) => res.data)
        .then(data => {
            if (data.status == 'success'){
                result = true
            }
            else{
                setMessge('An account with this email already exists')
            }
        })
        return result
    }

    return (
        <>
        {loading ? <Loader type="Puff" color="#00BFFF" height={60} width={60} className='pc-loader' />
        : (<div className='login-card'>
            <div className='login-main login-tab'>
            <Form className="theme-form needs-validation" noValidate="" onSubmit={handleSubmit(onSubmit)}>
                    <h4>Register here</h4>
                    <p>Please enter all details to get registered</p>
                    <FormGroup>
                        <Label className="col-form-label">First Name</Label>
                        <input className="form-control" id="firstname"
                            {...register("firstname", { required: true })}
                            type="text" placeholder="John"/>
                        <span className="error-msg">
                            { errors.firstname && 'First Name is required'}
                        </span>
                    </FormGroup>
                    <FormGroup>
                        <Label className="col-form-label">Last Name</Label>
                        <input className="form-control" id="lastname"
                            {...register("lastname", { required: true })}
                            type="text" placeholder="Doe"/>
                        <span className="error-msg">
                            { errors.lastname && 'Last Name is required'}
                        </span>
                    </FormGroup>
                    <FormGroup>
                        <Label className="col-form-label">Choose your role</Label>
                        <div></div>
                        <select name="role" id="role" className="select-primary show-arrow"
                        {...register("role", { required: true })}>
                            <option value=''> Select a role </option>
                            <option value="CXO">CXO</option>
                            <option value="CTO">CTO</option>
                            <option value="Product Head">Product Head</option>
                            <option value="Technical Head">Technical Head</option>
                            <option value="Business Owner">Business owner</option>
                            <option value="Analyst">Analyst</option>
                            <option value="Other">Other</option>
                        </select>
                        <span className="error-msg">
                            { errors.role && 'Role is required'}
                        </span>
                    </FormGroup>
                    <FormGroup>
                        <Label className="col-form-label">Company email</Label>
                        <input className="form-control" id="email"
                            {...register("email", { required: true, 
                                validate: checkEmail })}
                            type="email" placeholder="johndoe@promptcloud.com"/>
                        <span className="error-msg">
                            { errors.email && (errors.email.type == 'validate'
                                ? `${messge}`
                                : 'Email is required')}
                        </span>
                    </FormGroup>
                    <FormGroup>
                        <Label className="col-form-label">Re-enter email</Label>
                        <input className="form-control" id="email2"
                            {...register("email2", { required: true, validate: isSame })}
                            type="email" placeholder="johndoe@promptcloud.com"/>
                        <span className="error-msg">
                            { errors.email2 && (errors.email2.type == 'validate'
                                ? 'Both emails must be matched'
                                : 'Email is required')}
                        </span>
                    </FormGroup>
                  { (recaptchaSiteKey && recaptchaEnabled ) &&
                  <FormGroup>
                    <ReCAPTCHA
                      sitekey={recaptchaSiteKey}
                      onChange={onChange}
                    />
                  </FormGroup>
                  }
                    <div className="form-group mb-0">
                        <div className="checkbox ml-3">
                            <input id="checkbox1" type="checkbox"
                                {...register("agreement", { required: true })} />
                            <Label className="text-muted" for="checkbox1"> Agree to terms and policy </Label>
                            <div></div>
                            <span className="error-msg">
                                { errors.agreement && 'Please agree to Terms & Policy'}
                            </span>
                        </div>
                        <button type="submit" className="btn btn-primary btn-block w-100 m-t-5"
                            disabled={buttonDisable}>
                            Register
                        </button>
                    </div>
                    <p className="mt-4 mb-0">Already have an account?
                        <a className="ml-2" href="/users/sign_in"> Login here </a>
                    </p>
                </Form>
            </div>
        </div>)}
        </>
    )
}
