import React from 'react';
import { forwardRef, useImperativeHandle, useState } from 'react';

import { Row, Col, Form, Input } from 'reactstrap';
import { Field, useFormikContext } from 'formik';
import { useHistory } from "react-router-dom";
import { toast } from 'react-toastify';
import Dropzone from 'react-dropzone-uploader';

import axios from 'axios';
import moment from 'moment';

const AdditionalDetails = forwardRef(({ formikRef, handleSubmission, accessEnv, environment, buttonLoading}, ref) => {

  const history = useHistory();
  const { values } = useFormikContext()

  const [attachmentIds, setAttachmentIds] = useState([]);

  const getUploadParams = ({ meta }) => { return { url: 'https://httpbin.org/post' } }


  const handleChangeStatus = ({ meta, file }, status) => {
    if (status == 'done') {
      uploadFile(file)
    }
    if (status == 'removed') {
      setAttachmentIds([])
    }
  }

  const uploadFile = (file) => {
    let fd = new FormData();
    fd.append('file', file)
    axios.post(`/api/v1/new_attachments`, fd, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }).then(res => {
      let tempIds = [res.data.attachment.id]
      setAttachmentIds(tempIds)
      toast.success(res.data.message)
    }).catch(err => {
      toast.error(err.response.data.message)
    })
  }

  useImperativeHandle(ref, () => ({
    isValidated() {
      return true
    }
  }));

  const handleDataSubmit = () => {
    let freq = values?.frequency
    let freqObj = {}
    if (freq == 'weekly') {
      freqObj = { "days": values?.weekly }
    }
    else if (freq == 'other') {
      freqObj = { "other_details": values?.details }
    }
    else if (freq == 'monthly') {
      freqObj = { "date": moment(values?.monthly || new Date()).format("MM/DD/YYYY") }
    }
    let new_urls = []
    var rss_urls = accessEnv.includes(environment) ? new RegExp(/^\d+$/) : new RegExp(/.*/)
    new_urls = values?.site_details.filter(site => site?.url?.length > 0 && rss_urls.test(site?.rss_urls_crawl_limit))
    let inputData = {
      "submit_requirements": {
        "sitegroup_name": values?.sitegroup_name,
        "frequency": values?.frequency,
        "data_delivery": values?.data_delivery,
        "data_format": values?.data_format,
        "crawl_type": values?.crawl_type,
        "additional_information": values?.additional_information,
        "attachment_ids": attachmentIds,
        "schema_fields_details": values?.schema_fields_details,
        "rss_urls_crawl_limit": values?.rss_urls_crawl_limit,
        "additional_details": { 
          "merge_files": values?.merge_files ? 1 : 0, 
          "image_downloads": values?.image_downloads ? 1 : 0 
        },
        "delivery_frequency_details": freqObj,
        "site_details": new_urls
      }
    }
    handleSubmission(inputData)
  }

  const handleSubmit = (files, allFiles) => {
    allFiles.forEach(f => f.remove())
    toast.success("Dropzone successfully submitted !");
  }

  return(
    <>
      <Row className='my-5 py-3'>
        <Col sm={12}>
          <Row>
            <Col sm={4} className='fw-bold d-flex justify-content-end'>
              <h6 className='fw-bold'>
                Any further description
              </h6>
            </Col>
            <Col sm={6}>
              <Field
                as="textarea"
                rows={6}
                name="additional_information"
                id="description"
                className="w-100 form-control"
              />
            </Col>
            <Col sm={12}>
              <Row className='my-3'>
                <Col sm={4}>
                  <h6 className='fw-bold d-flex justify-content-end'>
                    Additional Requirements
                  </h6>
                </Col>
                <Col sm={6}>
                  <div className='mb-3'>
                    <Field
                      id="merge_files"
                      name="merge_files"
                      type="checkbox"
                      as={Input}
                    />
                    {' '}
                    Enable Merge
                  </div>
                  <div className='mb-3'>
                    <Field
                      id="image_downloads"
                      name="image_downloads"
                      type="checkbox"
                      as={Input}
                    />
                    {' '}
                    Enable Image / File Downloads
                  </div>
                </Col>
              </Row>
            </Col>
            <Col lg={4}>
              <h6 className='fw-bold d-flex justify-content-end'>
                Attach File
              </h6>
            </Col>
            <Col lg={6}>
              <Form onSubmit={handleSubmit}>
                <div className="dz-message needsclick">
                  <Dropzone 
                    getUploadParams={getUploadParams} 
                    maxFiles={1}
                    onChangeStatus={handleChangeStatus} 
                    multiple={false}
                    canCancel={true} 
                    inputContent="Browse / Drop a File"
                    styles={{
                      dropzone: { height: 70 },
                      dropzoneActive: { borderColor: 'green' },
                    }} 
                    maxSizeBytes={14680064}
                  />
                </div>
              </Form>
            </Col>
          </Row>
        </Col>
      </Row>
      <button 
      className='btn btn-success mb-2' 
      type="button" 
      style={{ float: 'right', height: 39 }}
      disabled={buttonLoading}
      onClick={()=> handleDataSubmit()}
      >
        Send for final feasibility check
      </button>
    </>
  )

})

export default AdditionalDetails;
