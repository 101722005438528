import React, { useState, useEffect } from 'react'
import { useParams } from "react-router-dom";
import axios from 'axios'
import { useHistory } from "react-router-dom";
import { toast } from 'react-toastify'
import Breadcrumbs from '../../../Common/BreadCrumb/BreadCrumbs.component';
import MasterServiceAgreement from './TermsAndConditionsText/MasterServiceAgreement/MasterServiceAgreement.component';
import StatementOfWork from './TermsAndConditionsText/StatementOfWork/StatementOfWork.component';

// custom hooks
import useOrgIdentifer from '@hooks/org_identifier';

export default function ClientUserAgreement({current_user, current_organization, take_my_org_details}) {
  let { feas_id } = useParams();
  const [checked, setChecked] = useState(false)
  const [data, setData] = useState({});
  let history = useHistory();
  const org_identifier = useOrgIdentifer();

  const [buttonLoading, setButtonLoading] = useState(false);

  useEffect(() => {
    take_my_org_details(org_identifier);
  }, [org_identifier]);

  useEffect(() => {
    if (org_identifier) {
      axios.get(`/api/v1/organizations/${org_identifier}/feasibility_reports/${feas_id}/user_agreements?page_type=client&controller_name=feasibility_reports`).then(res => {
        setData(res.data);
        if (res?.data?.feasibility_report?.feasibility_check_status == "payment_pending") {
          setChecked(true);
        }
        if (res.data.feasibility_report.skip_agreements) {
          toast.error("Agreements are skipped for this feasibility report.")
          history.push(`/organizations/${org_identifier}/feasibility_reports/${feas_id}`)
        }
      }).catch(err => {
        // Refactor later
        if (err?.response?.data?.page_auth_failed) {
          history.push("/")
          toast.error(err?.response?.data?.message)
        }
        else if (!err?.response?.data?.org_auth_failed){
          history.push(`/organizations/${org_identifier}/feasibility_reports/${feas_id}`)
          toast.error(err?.response?.data?.message)
        }
      })
    }
  }, [org_identifier]);

  const handleClick = () => {
    setButtonLoading(true)
    if (data.feasibility_report?.address_contain_nessary_values) {
      axios.put(`/api/v1/organizations/${org_identifier}/feasibility_reports/${feas_id}`, {
        organization: data?.address?.country == "IN"
        ? { name: data?.address?.organization_name, gstin_id: data?.address?.gstin_id }
        : { name: data?.address?.organization_name },
        address: {
          line1: data?.address?.line1, line2: data?.address?.line2, city: data?.address?.city,
          state: data?.address?.state, zip: data?.address?.zip
        },
        page_type: "client", controller_name: "feasibility_reports"
      }).then(res => {
        toast.success(res.data.message)
        if (data?.feasibility_report?.skip_invoice) {
          history.push('/')
        }
        else {
          history.push(`/organizations/${org_identifier}/invoices/${res.data.invoice_id}`)
        }
      }).catch(err => {
        setButtonLoading(false)
        toast.error(err.response.data.message)
      })
    }
    else {
      history.push(`/organizations/${org_identifier}/feasibility_reports/${feas_id}/billing_details`)
    }
  }
  return (
    <>
      <Breadcrumbs title={"Feasibility Report"} />
      <div className='container container-fluid'>
        <MasterServiceAgreement data={data} />
        <StatementOfWork 
          data={data} 
          handleClick={handleClick} 
          buttonLoading={buttonLoading}
          checked={checked} 
          setChecked={setChecked} />
      </div>
    </>
  )
}
