import React, { Fragment, useEffect, useState, useContext } from "react";
import { Card, CardBody, Container, Nav, NavItem, NavLink, Row, TabContent, TabPane, Col, Breadcrumb } from "reactstrap";
import axios from "axios";
import { useParams, useHistory } from 'react-router-dom';
import { Home } from "react-feather";
import Loader from "react-loader-spinner";

import OrganizationLevel from './OrganizationLevel';
import SiteLevel from './SiteLevel';

// custom hooks
import useOrgIdentifer from '@hooks/org_identifier';

export default function index({current_user, current_organization, take_my_org_details}) {

  const [activeSites, setActiveSites] = useState([]);
  const [country, setCountry] = useState();

  const [activeTab, setActiveTab] = useState("organization_level");

  const [loading, setLoading] = useState(false);

  const history = useHistory()

  const org_identifier = useOrgIdentifer();

  useEffect(() => {
    take_my_org_details(org_identifier);
  }, [org_identifier]);

  useEffect(() => {
    setLoading(true);
    axios.get(`/api/v2/organizations/${org_identifier}/organization_billing_alerts`)
      .then(res => {
        setActiveSites(res?.data?.active_sites)
        setCountry(res?.data?.currency_code)
        setLoading(false)
      })
      .catch(err => {
        setLoading(false)
        // Refactor later
        if (err?.response?.data?.page_auth_failed) {
          history.push("/")
          toast.error(err?.response?.data?.message)
        }
        else if (!err?.response?.data?.org_auth_failed){
          toast.error(err?.response?.data?.message)
        }
      })
  }, [org_identifier]);

  return <Container fluid>
    <Container fluid>
      <Breadcrumb parent={"Set Billing Alarms"} title={'Set Billing Alarms'} />
    </Container>
    {loading ? <Loader type="Puff" color="#00BFFF" height={60} width={60} className='pc-loader' /> :
    <Fragment>
      <Card>
        <CardBody>
          <Nav tabs>
            <NavItem>
              <NavLink href="#" className={activeTab == "organization_level" ? 'active' : ''} onClick={() => setActiveTab("organization_level")}>
                Organization Level
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="#" className={activeTab == "site_level" ? 'active' : ''} onClick={() => setActiveTab("site_level")}>
                Site Level
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent activeTab={activeTab}>
            <TabPane tabId={"organization_level"} className="mt-4">
              <div className="fw-bold">
                <OrganizationLevel orgName={org_identifier} country={country} />
              </div>
            </TabPane>
            <TabPane tabId={"site_level"} className="mt-4">
              <div className="fw-bold">
                <SiteLevel orgName={org_identifier} country={country} activeSites={activeSites} />
              </div>
            </TabPane>
          </TabContent>
        </CardBody>
      </Card>
    </Fragment>
    }
  </Container >
}
