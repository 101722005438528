import React from 'react'
import { useState, useEffect, useContext } from 'react';
import { Card, CardHeader, Container, CardBody } from 'reactstrap';
import { toast } from 'react-toastify';
import { useParams, useHistory } from 'react-router-dom';
import Breadcrumb from '../../global/Breadcrumb';
import Loader from 'react-loader-spinner';
import axios from 'axios';
import CrawlNotificationPreferenceTable from './CrawlNotificationsPreferenceTable/CrawlNotificationPreferenceTable.component';
import Search from './CrawlNotificationsPreferenceTable/Search/Search.component';

// custom hooks
import useOrgIdentifer from '@hooks/org_identifier';

export default function CrawlNotificationPreferences({current_user, current_organization, take_my_org_details}) {
  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [siteName, setSiteName] = useState('');
  const [pageNumber, setPageNumber] = useState(1);

  const history = useHistory()

  const org_identifier = useOrgIdentifer();

  useEffect(() => {
    take_my_org_details(org_identifier);
  }, [org_identifier]);

  const API_ENDPOINT = `/api/v1/organizations/${org_identifier}/website_notification_preferences?page=${pageNumber}&filterrific[search_by_site_name]=${siteName}&page_type=client&controller_name=website_notification_preferences`;

  // Use Effect for when the component mounts
  useEffect(async () => {
    setLoading(true);

    const controller = new AbortController();

    await fetchDataFromServer(controller);

    setLoading(false);

    // cleanup 
    return () => {
      setLoading(false);
      controller.abort();
    }
  }, [pageNumber, siteName]);


  // Helper function to fetch data from the server
  async function fetchDataFromServer(signalController) {
    try {
      let response = await axios.get(API_ENDPOINT, { signal: signalController.signal });
      let dataFromServer = await response?.data?.website_notification_preferences;
      setTableData(dataFromServer);
      setTotalRows(dataFromServer[0].total_page_count);
    }
    catch (err) {
      // Refactor later
      if (err?.response?.data?.page_auth_failed) {
        history.push("/")
        toast.error(err?.response?.data?.message)
      }
      else if (!err?.response?.data?.org_auth_failed){
        toast.error(err?.response?.data?.message)
      }
    }
  };


  return (
    <>
      <Container fluid>
        <Breadcrumb parent={"Crawl Notification Preferences"} title={'Site Crawl Notifications'} />
        <Card>
          <CardHeader>
            <Search siteNameSetter={setSiteName} loadingAnimation={setLoading} />
          </CardHeader>
          <CardBody>
            <CrawlNotificationPreferenceTable
              progressPending={loading}
              data={tableData}
              paginationTotalRows={totalRows}
              apiEndpoint={API_ENDPOINT}
              pageSetter={setPageNumber}
            />
          </CardBody>
        </Card>
      </Container>
    </>
  )
};
