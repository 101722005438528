import axios from 'axios';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { Modal, ModalBody, ModalHeader, Row, Col, ModalFooter, Button } from 'reactstrap';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import Loader from 'react-loader-spinner';
import * as Yup from 'yup';

export default function EditSiteDetailsForm({ data, setToggle, siteStatuses, feasibilityStatuses }) {
  const [modal, setModal] = useState(false);
  const [loadingBtns, setLoadingBtns] = useState(false);

  const toggleFunc = () => {
    setModal(curr => !curr);
  };

  const closeBtn = <button className="close" onClick={() => setModal(false)}> &times; </button>;

  const updateSiteDetails = (values) => {
    setLoadingBtns(true);
    axios.put(`/api/v1/admins/feasibility_reports/${data.site_id}`, {
      site: { 
        feasibility_status: values.feasibility_status, 
        site_status: values.site_status,
        redmine_ticket_id: values.redmine_ticket_id,
        pricing_detail_attributes: {
          id: data?.pricing_detail_id,
          site_setup_fee: values.site_setup_fee, 
          monthly_maintenance_fee: values.monthly_maintenance_fee, 
          volume_charges: values.volume_charges
        }
      }
    }).then(res => {
      toast.success(res.data.message);
      setModal(false);
      setToggle(curr => !curr);
      setLoadingBtns(false);
    }).catch(err => {
      toast.error(err.response.data.message);
      setLoadingBtns(false);
    });
  }

  const validationSchema = Yup.object({
    feasibility_status: Yup.string().required("Feasibility status is required"),
    site_status: Yup.string().required("Site status is required")
  })
  

  return (
    <>
      <Modal isOpen={modal} toggle={toggleFunc} backdrop='static' size='lg'>
        <ModalHeader toggle={toggleFunc} close={closeBtn}>
          Edit Member: {data.site_name}
        </ModalHeader>
        <ModalBody className='px-5'>
          <Formik
            initialValues={{
              feasibility_status: data.feasibility_status.toString(),
              site_status: data.site_status,
              redmine_ticket_id: data.redmine_ticket_id,
              site_setup_fee: data.site_setup_fee,
              monthly_maintenance_fee: data.monthly_maintenance_fee,
              volume_charges: data.volume_charges
            }}
            validationSchema={validationSchema}
            onSubmit={(values) => updateSiteDetails(values)}
          >
            {({ isSubmitting }) => (
              <Form>
                <Row className='my-2'>
                  <Col lg={4}>
                    <h6 className='my-0'>Feasibility Status</h6>
                  </Col>
                  <Col md={8}>
                    <Field as="select" name="feasibility_status" className="form-control">
                      <option value=""></option>
                      {feasibilityStatuses.map(each => (
                        <option value={each[1]} key={each[0]}>
                          {each[0]}
                        </option>
                      ))}
                    </Field>
                    <ErrorMessage name={"feasibility_status"} component="p" className='m-0 error-msg mt-1 mb-2'/>
                  </Col>
                </Row>
                <Row className='my-2'>
                  <Col lg={4}>
                    <h6 className='my-0'>Site Status</h6>
                  </Col>
                  <Col lg={8}>
                    <Field as="select" name="site_status" className="form-control">
                      <option value=""></option>
                      {siteStatuses.map(each => (
                        <option value={each[1]} key={each[0]}>
                          {each[0]}
                        </option>
                      ))}
                    </Field>
                    <ErrorMessage name={"site_status"} component="p" className='m-0 error-msg mt-1 mb-2'/>
                  </Col>
                </Row>
                <Row className='my-2'>
                  <Col lg={4}>
                    <h6 className='my-0'>Redmine Ticket ID#</h6>
                  </Col>
                  <Col lg={8}>
                    <Field type="text" name="redmine_ticket_id" className="form-control" />
                    <ErrorMessage name="redmine_ticket_id" component="p" className="m-0 error-msg mt-1 mb-2"/>
                  </Col>
                </Row>
                <Row className='my-2'>
                  <Col lg={4}>
                    <h6 className='my-0'>Site Setup Fee</h6>
                  </Col>
                  <Col lg={8}>
                    <Field type="text" name="site_setup_fee" className="form-control" />
                    <ErrorMessage name="site_setup_fee" component="p" className="m-0 error-msg mt-1 mb-2"/>
                  </Col>
                </Row>
                <Row className='my-2'>
                  <Col lg={4}>
                    <h6 className='my-0'>Monthly Maintenance Fee</h6>
                  </Col>
                  <Col lg={8}>
                    <Field type="text" name="monthly_maintenance_fee" className="form-control" />
                    <ErrorMessage name="monthly_maintenance_fee" component="p" className="m-0 error-msg mt-1 mb-2"/>
                  </Col>
                </Row>
                <Row className='my-2'>
                  <Col lg={4}>
                    <h6 className='my-0'>Volume Charges</h6>
                  </Col>
                  <Col lg={8}>
                    <Field type="text" name="volume_charges" className="form-control" />
                    <ErrorMessage name="volume_charges" component="p" className="m-0 error-msg mt-1 mb-2"/>
                  </Col>
                </Row>
                <ModalFooter>
                  <Button color='light' onClick={() => setModal(false)}>Close</Button>
                  <Button color="success" type="submit" disabled={loadingBtns || isSubmitting}>Update</Button>
                </ModalFooter>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </Modal>
      <button className='btn btn-xs btn-primary mx-1' onClick={toggleFunc}>Edit</button>
    </>
  );
}
