import React, {useState, useEffect} from 'react'
import { Switch, Route, useRouteMatch, Redirect } from "react-router-dom";
import Loader from "react-loader-spinner";
import InternalDashboardSidebar from './sidebar/InternalDashboardSidebar';
import SiteFeasibilityCheck from './site-feasibility-check/SiteFeasibilityCheck';
import AmazonRequestTypePerformanceTracker from './amazon-request-type-performance-tracker/AmazonRequestTypePerformanceTracker';
import HostWiseLoadStats from './devops/HostWiseLoadStats';
import ApiInformation from './api-information/ApiInformation';
import SitewiseFailedAndSucceededUrlStats from './sitewise-failed-and-succeeded-url-stats/SiteWiseFailedAndSucceededUrlStats';
import FailedSegmentsCount from './failed-segments-count/FailedSegmentsCount';
import RunForceSegmentStatus from './run-force-segment-status/RunForceSegmentStatus';
import OverallFailedUrlsCount from './overall-failed-urls-count/OverAllFailedUrlsCount';
import ResqueReportsForBlockedSites from './resque-reports-for-blocked-sites/ResqueReportsForBlockedSites';
import HighRecordDropInExtractionOrDedup from './high-record-drop-in-extraction-or-dedup/HighRecordDropInExtractionOrDedup';
import SdfConfigServicesAndServerMappings from './devops/SdfConfigServicesAndServerMappings';
import FailedUrls from './failed-urls/FailedUrls';
import InternalDashboardHeader from './sidebar/InternalDashboardHeader';
import { useHistory } from "react-router-dom";
import axios from 'axios'
import { toast } from 'react-toastify';
import RabbitmqDuplicateQueueReport from './rabbitmq-deplicate-queue-report/RabbitmqDeplicateQueueReport';
import SeleniumUsedAndFreeBrowserCountsAndWaitingRequestCounts from './selenium/SeleniumUsedAndFreeBrowserCountsAndWaitingRequestCounts';
import SeleniumSiteWiseFailedAndSucceededUrlStats from './selenium/SiteWiseFailedAndSucceededUrlStats';
import SeleniumClusterWiseFailureAndRetryCount from './selenium/SeleniumClusterWiseFailureAndRetryCount';
import AjaxIntializationTime from './selenium/AjaxIntializationTime';
import AjaxIntializationTries from './selenium/AjaxIntializationTries';
import DependentDataUploadFailed from './extended-upload-failed-stats/DependentDataUpload';
import MultiTargetUploadFailed from './extended-upload-failed-stats/MultiTargetUploadFailed';
import MergeFilesFailed from './extended-upload-failed-stats/MergeFilesFailed';
import DependentDataUploadSuccess from './extended-upload-success-stats/DependentDataUploadSuccess';
import MultiTargetUploadSuccess from './extended-upload-success-stats/MultiTargetUploadSuccess';
import MergeFilesSuccess from './extended-upload-success-stats/MergeFilesSuccess';
import SiteWiseSeleniumBrowserReqCount from './selenium/SiteWiseSeleniumBrowserReqCount';
import SiteWiseFailureAndRetryCount from './selenium/SiteWiseFailureAndRetryCount';
import PipeLineStageWiseSeleniumBrowserReqCount from './selenium/PipelineStageWiseSeleniumBrowserReqCount';
import SeleniumClusterDiagnostics from './selenium/SleniumClusterDiagnostics';
import SeleniumClusterWiseBrowserReqCount from './selenium/SeleniumClusterWiseBrowserReqCount';
import EsStoreConnectionsTracker from './es-stores/EsStoreConnectionsTracker';
import OverallRecordsCountBySystemWide from './overall-system-reports/OverallRecordsCountBySystemWide';
import OverallRecordsCountBySiteWise from './overall-system-reports/OverallRecordsCountBySiteWise';
import OverallRecordsCountByHostWise from './overall-system-reports/OverallRecordsCountByHostWise';
import MergeFilesAndUpload from './extended-upload-process-status/MergeFilesAndUpload';
import ShowFileList from './extended-upload-process-status/ShowFileList';
import DependentDataUpload from './extended-upload-process-status/DependentDataUpload';
import MultiTargetUpload from './extended-upload-process-status/MultiTargetUpload';
import SegmentsCreatedByAcrossSystem from './overall-system-reports/overall-segments-report/SegmentsCreatedByAcrossSystem';
import OverallSuccessfulSegments from './overall-system-reports/overall-segments-report/OverallSuccessfulSegments';
import OverallFailedSegments from './overall-system-reports/overall-segments-report/OverallFailedSegments';
import OverallPendingSegments from './overall-system-reports/overall-segments-report/OverallPendingSegments';
import HostWiseAverageTimeToPickSegment from './overall-system-reports/overall-segments-report/HostWiseAverageTimeToPickSegment';
import AggregateUrlsCrawledByRequestType from './overall-system-reports/AggregateUrlsCrawledByRequestType';
import SiteOrProjectOrClientVsNoOfWorkers from './proxy-and-selenium-usage-graph/SiteOrProjectOrClientVsNoOfWorkers';
import SiteOrProjectOrClientVsNoOfSeleniumWorkers from './proxy-and-selenium-usage-graph/SiteOrProjectOrClientVsNoOfSeleniumWorkers';
import SiteOrProjectOrClientVsSeleniumCluster from './proxy-and-selenium-usage-graph/SiteOrProjectOrClientVsSeleniumCluster';
import SiteOrProjectOrClientVsProxySource from './proxy-and-selenium-usage-graph/SiteOrProjectOrClientVsProxySource';
import SiteOrProjectOrClientVsTotalRequest from './proxy-and-selenium-usage-graph/SiteOrProjectOrClientVsTotalRequest';
import SiteOrProjectOrClientVsAvgTimeForCrawl from './proxy-and-selenium-usage-graph/SiteOrProjectOrClientVsAvgTimeForCrawl';
import FailureAndSuccessRates from './proxy-and-sources/FailureAndSuccessRates';
import LogstashStats from './log-stats/LogstashStats';
import SdfMetricsStats from './log-stats/SdfMetricsStats';
import IndexingErrors from './log-stats/IndexingErrors';
import SystemWideResqueJobs from './resque-reports/SystemWideResqueJobs';
import SystemWideResqueWorkers from './resque-reports/SystemWideResqueWorkers';
import SiteWiseResqueRunning from './resque-reports/SiteWiseResqueRunning';
import SiteWiseResqueWaiting from './resque-reports/SiteWiseResqueWaiting';
import AvgAvailableWorkersInResqueJobs from './resque-reports/AvgAvailableWorkersInResqueJobs';
import ScraperApiCrawlTracker from './scraper-api-crawl-tracker/ScraperApiCrawlTracker';
import AvgTimeStatsGraphsPerPipelineStage from './overall-system-reports/average-time-stats/AvgTimeStatsGraphsPerPipelineStage';
import AvgTimePerPipelineStageGeoWise from './overall-system-reports/average-time-stats/AvgTimePerPipelineStageGeoWise';
import AvgTimePerPipelineStageHostWise from './overall-system-reports/average-time-stats/AvgTimePerPipelineStageHostWise';
import AvgTimePerPipelineStageSiteWise from './overall-system-reports/average-time-stats/AvgTimePerPipelineStageSiteWise';
import CrawlboardRequestLogs from './rails-internal-dashboard/CrawlboardRequestLogs';
import JobspikrRequestLogs from './rails-internal-dashboard/JobspikrRequestLogs';
import DatastockRequestLogs from './rails-internal-dashboard/DatastockRequestLogs';
import RailsResqueLogs from './rails-internal-dashboard/RailsResqueLogs';
import AvgTimeStatsTablePerPipelineStage from './overall-system-reports/average-time-stats/AvgTimeStatsTablePerPipelineStage';
import AwsAndGceCurrentlyRunningMachines from './aws-and-gce-machine-info/AwsAndGceCurrentlyRunningMachines';
import CloudMachinesCountEstimation from './aws-and-gce-machine-info/CloudMachinesCountEstimation';
import ManuallyPushToQueueTracker from './manually-push-to-queue-tracker/Index';
import IndexEdnuProcesses from './ednu-processes/Index';
import ShowEdnuProcesses from './ednu-processes/Show';

import ElasticAlerts from './elastic-alerts/ElasticAlerts';
import ESJP from './es-cluster-health/ESJP';
import ESBE from './es-cluster-health/ESBE';
import ESPLM from './es-cluster-health/ESPLM';
import ES42S from './es-cluster-health/ES42S';


import RssBlockingUrlStats from './blocking-url-stats/RssStats';
import DiskfetcherBlockingUrlStats from './blocking-url-stats/DiskfetcherStats';
import BlockingSolutionConfigFiles from './blocking-solution-config-files/Index';
import RabbitmqQueueSizes from './rabbitmq-reports/RabbitmqQueueSizes';
import CommandDashboard from './common-dashboard-qa-page/CommandDashboard';
import RabbitmqQueueSearch from './rabbitmq-reports/RabbitmqQueueSearch';
import RabbitmqQueueSpecific from './rabbitmq-reports/RabbitmqQueueSpecific';
import RabbitmqQueueSearchDate from './rabbitmq-reports/RabbitmqQueueSearchDate';
import OurStack from './our-stack/OurStack';
import SiteUploadSpikeAndDrop from './overall-system-reports/SiteUploadSpikeAndDrop';
import EndToEndUrlTracker from './end-to-end-url-tracker/EndToEndUrlTracker';
import KilledAndFailedStatsGraphs from './resque-reports/KilledAndFailedStatsGraphs';
import GodReportsByMachine from './god-reports/GodReportByMachine';
import GodReportsByJob from './god-reports/GodReportByJob';
import ClusterwiseWorkerAndJobsInfo from './clusterwise-worker-and-jobs-info/index';
import MissingFields from './missing-fields/index';
import TotalRSSTotalFeedcrawlCounts from './overall-system-reports/TotalRSSTotalFeedcrawlCounts';
import FieldUploadSpikeAndDrop from './overall-system-reports/FieldUploadSpikeAndDrop';
import SiteWiseWorkerCount from './site-wise-worker-count/index';
import ProxyLogReports from './proxy-log-reports/Index';
import SiteSummary from './site-summary/Index';

// es pages
import V2UploadsHome from './v2/uploads/index';
import V2HighVolumeClientsIndex from './v2/HighVolumeClientPage/Index';
import V2EndToEndCrawlTracker from '../V2/InternalDashboardPages/EndToEndCrawlTracker/EndToEndCrawlTracker.index';
import V2NewRssProcesses from './v2/InternalRSSProcess/index';
// import V2SegmentsTakeMoreTimeByEdnuStageWise from './v2/SegmentsTakeMoreTime/index';
import V2SegmentLevelRssStats from './v2/SegmentLevelRssStats/index';
import V2SegmentLevelCrawlExtractionStats from './v2/SegmentLevelCrawlExtraction/index';
import V2SegmentsTakeMoreTimeByEdnuStageWise from '../V2/InternalDashboardPages/SegmentsTakeMoreTimeByEDNUStageWise/SegmentsTakeMoreTimeByEDNUStageWise.index';
import V2TestSiteStats from './v2/TestSiteStats/index';
import V2InternalDiskfetcherProcess from './v2/InternalDiskfetcherProcess/index';
import V2VolumeFluctuations from './v2/volume-fluctuations/index';
import V2QualityAnalysis from '../V2/InternalDashboardPages/CommandDashboardAndQualitiyAnalysis/QualityAnalysis/QualityAnalysis.index';
import V2QualityAnalysisReports from '../V2/InternalDashboardPages/CommandDashboardAndQualitiyAnalysis/QualityAnalysisReports/QualityAnalysisReports.index'
import V2EachQualityAnalysisPage from '../V2/InternalDashboardPages/CommandDashboardAndQualitiyAnalysis/QualityAnalysis/EachQualityAnalysisPage';

// New QA Reports Pages
import AllNewQaReports from './common-dashboard-qa-page/NewQaReports/AllQaReports';
import PendingNewQaReports from './common-dashboard-qa-page/NewQaReports/PendingQaReports';
import SearchNewQaReports from './common-dashboard-qa-page/NewQaReports/SearchQaReports';
import SiteSpecificNewQaReport from './common-dashboard-qa-page/NewQaReports/SiteSpecificQaReport';

// Chat GPT Related Pages
import XpathResults from '../V2/InternalDashboardPages/ChatGpt/XpathResults/XpathResults.index';
import NewQaTool from '../Beta/NewQATool/NewQaTool.index';

export default function InternalDashboardRoutes() {
  const { path } = useRouteMatch();
  const history = useHistory();
  const [loading, setLoading] = useState(false)
  const [user, setUser] = useState({})

  const token = localStorage.getItem("authToken");
  const email = localStorage.getItem("authEmail");

  useEffect(() => {
    setLoading(true)
    axios.get(`/api/v1/user/details?page_type=internal&controller_name=dashboard&email=${email}`).then(res => {
      setUser(res.data.user)
      setLoading(false)
    }).catch(err => {
      setLoading(false)
      if (err?.response?.data?.message === "User is not present!"){
        handleLogout()
      }
      else if (err?.response?.data?.message === "Authorization token has expired"){
        handleLogout()
      }
      else if (err?.response?.status == 500){
        history.push("/server-error")
      }
    })
  }, [email])    

  const handleLogout = () => {
    sessionStorage.setItem('redirectUrl', window.location.href)
    axios.delete(`/api/v1/users/logout?page_type=internal&controller_name=sessions`, {
      user: {
        email: localStorage.getItem("authEmail"),
        authenticity_token: localStorage.getItem("authToken")
      }
    }).then(res => {
      toast.success(res.data?.message)
    }).catch(err => {
      console.log('err', err);
    })
    localStorage.removeItem('authEmail'); 
    localStorage.removeItem('authToken');
    localStorage.removeItem('user_name');
    history.push("/users/sign_in")
  }

  useEffect(() => {
    if (user?.is_admin === false){
      history.push('/')
    }
  }, [user])

  return (
    <div className="page-wrapper compact-wrapper" id="pageWrapper">
      {!loading && <InternalDashboardHeader />}
      {loading ? <Loader type="Puff" color="#00BFFF" height={100} width={100} className='pc-loader' />
          : <div className="page-body-wrapper">
            <InternalDashboardSidebar />
            <div className="page-body">
              <Switch>
                <Route path={`${path}/`} component={V2UploadsHome} exact />
                <Route path={`${path}/scraper_api_feasibility_check`} component={SiteFeasibilityCheck} exact />
                <Route path={`${path}/site_feasibility_check`} render={()=><Redirect to={`${path}/scraper_api_feasibility_check`} />} />
                <Route path={`${path}/amazon_request_type_performance_tracker`} component={AmazonRequestTypePerformanceTracker} exact />
                <Route path={`${path}/api_information`} component={ApiInformation} exact />
                <Route path={`${path}/api_information/search`} component={ApiInformation} exact />
                <Route path={`${path}/devops/host_wise_load_stats`} component={HostWiseLoadStats} exact />
                <Route path={`${path}/diskfetcher_segment_stats/sitewise_failed_and_succeeded_url_stats`} component={SitewiseFailedAndSucceededUrlStats} exact />                    
                <Route path={`${path}/diskfetcher_segment_stats/failed_segments_count`} component={FailedSegmentsCount} exact />       
                <Route path={`${path}/runforce_segment_status`} component={RunForceSegmentStatus} exact />
                <Route path={`${path}/diskfetcher_segment_stats/overall_failed_urls_count`} component={OverallFailedUrlsCount} exact />       
                <Route path={`${path}/pipeline_stage/high_record_drop_in_extraction_or_dedup`} component={HighRecordDropInExtractionOrDedup} exact />
                <Route path={`${path}/resque_info/resque_reports_for_blocked_sites`} component={ResqueReportsForBlockedSites} exact />
                <Route path={`${path}/devops/sdf_config_services_and_server_mappings`} component={SdfConfigServicesAndServerMappings} exact />       
                <Route path={`${path}/failed_urls`} component={FailedUrls} exact />     
                <Route path={`${path}/devops/sdf_config_services_and_server_mappings`} component={SdfConfigServicesAndServerMappings} exact />       
                <Route path={`${path}/failed_urls`} component={FailedUrls} exact />   
                <Route path={`${path}/rabbitmq_queue_report/duplicate_queue_report`} component={RabbitmqDuplicateQueueReport} exact />   
                <Route path={`${path}/selenium/selenium_used_and_free_browser_counts_and_waiting_request_counts`} component={SeleniumUsedAndFreeBrowserCountsAndWaitingRequestCounts} exact />
                <Route path={`${path}/selenium/sitewise_failed_and_succeeded_url_stats`} component={SeleniumSiteWiseFailedAndSucceededUrlStats} exact />   
                <Route path={`${path}/selenium/selenium_cluster_wise_failure_and_retry_count`} component={SeleniumClusterWiseFailureAndRetryCount} exact />  
                <Route path={`${path}/selenium/ajax_initialization_time`} component={AjaxIntializationTime} exact />  
                <Route path={`${path}/selenium/ajax_initialization_tries`} component={AjaxIntializationTries} exact />
                <Route path={`${path}/extended_upload_failed_stats/dependent_data_upload_failed`} component={DependentDataUploadFailed} exact />
                <Route path={`${path}/extended_upload_failed_stats/multi_target_upload_failed`} component={MultiTargetUploadFailed} exact />                 
                <Route path={`${path}/extended_upload_failed_stats/merge_files_failed`} component={MergeFilesFailed} exact />                 
                <Route path={`${path}/extended_upload_success_stats/dependent_data_upload_success`} component={DependentDataUploadSuccess} exact />
                <Route path={`${path}/extended_upload_success_stats/multi_target_upload_success`} component={MultiTargetUploadSuccess} exact />                 
                <Route path={`${path}/extended_upload_success_stats/merge_files_success`} component={MergeFilesSuccess} exact />                 
                <Route path={`${path}/selenium/site_wise_selenium_browser_req_count`} component={SiteWiseSeleniumBrowserReqCount} exact />  
                <Route path={`${path}/selenium/site_wise_failure_and_retry_count`} component={SiteWiseFailureAndRetryCount} exact />
                <Route path={`${path}/selenium/pipeline_stage_wise_selenium_browser_req_count`} component={PipeLineStageWiseSeleniumBrowserReqCount} exact />
                <Route path={`${path}/selenium/selenium_cluster_diagnostics`} component={SeleniumClusterDiagnostics} exact />
                <Route path={`${path}/selenium/selenium_cluster_wise_browser_req_count`} component={SeleniumClusterWiseBrowserReqCount} exact />
                <Route path={`${path}/es_stores/es_store_connections_tracker`} component={EsStoreConnectionsTracker} exact />
                <Route path={`${path}/overall_system_reports/overall_records_count_by_system_wide`} component={OverallRecordsCountBySystemWide} exact />
                <Route path={`${path}/overall_system_reports/overall_records_count_by_site_wise`} component={OverallRecordsCountBySiteWise} exact />
                <Route path={`${path}/overall_system_reports/overall_records_count_by_host_wise`} component={OverallRecordsCountByHostWise} exact />
                <Route path={`${path}/extended_upload_process_status/merge_files_and_upload`} component={MergeFilesAndUpload} exact />
                <Route path={`${path}/extended_upload_process_status/show_file_list`} component={ShowFileList} exact />
                <Route path={`${path}/extended_upload_process_status/dependent_data_upload`} component={DependentDataUpload} exact />
                <Route path={`${path}/extended_upload_process_status/multi_target_upload`} component={MultiTargetUpload} exact />
                <Route path={`${path}/overall_system_reports/overall_records_count_by_system_wide`} component={OverallRecordsCountBySystemWide} exact />              
                <Route path={`${path}/overall_system_reports/overall_records_count_by_site_wise`} component={OverallRecordsCountBySiteWise} exact />              
                <Route path={`${path}/overall_system_reports/overall_records_count_by_host_wise`} component={OverallRecordsCountByHostWise} exact />             
                <Route path={`${path}/overall_system_reports/segments_created_by_across_system`} component={SegmentsCreatedByAcrossSystem} exact />
                <Route path={`${path}/overall_system_reports/overall_successful_segments`} component={OverallSuccessfulSegments} exact />  
                <Route path={`${path}/overall_system_reports/overall_failed_segments`} component={OverallFailedSegments} exact />              
                <Route path={`${path}/overall_system_reports/host_wise_avg_time_to_pick_segment`} component={HostWiseAverageTimeToPickSegment} exact />              
                <Route path={`${path}/overall_system_reports/overall_pending_segments`} component={OverallPendingSegments} exact />             
                <Route path={`${path}/overall_system_reports/aggregate_urls_crawled_by_request_type`} component={AggregateUrlsCrawledByRequestType} exact />             
                <Route path={`${path}/site_or_project_or_client_vs_no_of_workers`} component={SiteOrProjectOrClientVsNoOfWorkers} exact />             
                <Route path={`${path}/site_or_project_or_client_vs_no_of_selenium_workers`} component={SiteOrProjectOrClientVsNoOfSeleniumWorkers} exact />             
                <Route path={`${path}/site_or_project_or_client_vs_selenium_cluster`} component={SiteOrProjectOrClientVsSeleniumCluster} exact />             
                <Route path={`${path}/site_or_project_or_client_vs_proxy_source`} component={SiteOrProjectOrClientVsProxySource} exact />             
                <Route path={`${path}/site_or_project_or_client_vs_total_request`} component={SiteOrProjectOrClientVsTotalRequest} exact />             
                <Route path={`${path}/site_or_project_or_client_vs_avg_time_for_crawl`} component={SiteOrProjectOrClientVsAvgTimeForCrawl} exact />             
                <Route path={`${path}/proxy_sources/failure_and_success_rates`} component={FailureAndSuccessRates} exact />             
                <Route path={`${path}/log_stats/logstash_stats`} component={LogstashStats} exact />             
                <Route path={`${path}/log_stats/sdf_metrics_stats`} component={SdfMetricsStats} exact />             
                <Route path={`${path}/log_stats/indexing_errors`} component={IndexingErrors} exact />             
                <Route path={`${path}/resque_info/system_wide_resque_jobs`} component={SystemWideResqueJobs} exact />             
                <Route path={`${path}/resque_info/system_wide_resque_workers`} component={SystemWideResqueWorkers} exact />             
                <Route path={`${path}/resque_info/site_wise_resque_running`} component={SiteWiseResqueRunning} exact />             
                <Route path={`${path}/resque_info/site_wise_resque_waiting`} component={SiteWiseResqueWaiting} exact />             
                <Route path={`${path}/resque_info/avg_available_workers_in_resque_jobs`} component={AvgAvailableWorkersInResqueJobs} exact />             
                <Route path={`${path}/scraper_api_crawl_tracker`} component={ScraperApiCrawlTracker} exact />             
                <Route path={`${path}/overall_system_reports/overall_avg_time_stats_per_pipeline_stage`} component={AvgTimeStatsGraphsPerPipelineStage} exact />             
                <Route path={`${path}/overall_system_reports/avg_time_per_pipeline_stage_geo_wise`} component={AvgTimePerPipelineStageGeoWise} exact />             
                <Route path={`${path}/overall_system_reports/avg_time_per_pipeline_stage_host_wise`} component={AvgTimePerPipelineStageHostWise} exact />             
                <Route path={`${path}/overall_system_reports/avg_time_per_pipeline_stage_site_wise`} component={AvgTimePerPipelineStageSiteWise} exact />             
                <Route path={`${path}/crawlboard_request_logs`} component={CrawlboardRequestLogs} exact />             
                <Route path={`${path}/jobspikr_request_logs`} component={JobspikrRequestLogs} exact />             
                <Route path={`${path}/datastock_request_logs`} component={DatastockRequestLogs} exact />             
                <Route path={`${path}/rails_resque_logs`} component={RailsResqueLogs} exact />             
                <Route path={`${path}/overall_system_reports/avg_time_stats_table_per_pipeline_stage`} component={AvgTimeStatsTablePerPipelineStage} exact />             
                <Route path={`${path}/aws_and_gce_machine_info/currently_running_machines`} component={AwsAndGceCurrentlyRunningMachines} exact />             
                <Route path={`${path}/aws_and_gce_machine_info/cloud_machines_count_estimation_new`} component={CloudMachinesCountEstimation} exact />             
                <Route path={`${path}/manually_push_to_queue_tracker`} component={ManuallyPushToQueueTracker} exact />             
                <Route path={`${path}/ednu_processes`} component={IndexEdnuProcesses} exact />             
                <Route path={`${path}/ednu_processes/:sitename`} component={ShowEdnuProcesses} exact />                         
                <Route path={`${path}/es_cluster_health/es_jp`} component={ESJP} exact />
                <Route path={`${path}/es_cluster_health/es_be`} component={ESBE} exact />
                <Route path={`${path}/es_cluster_health/es_plm`} component={ESPLM} exact />
                <Route path={`${path}/es_cluster_health/es_42signals`} component={ES42S} exact />
                <Route path={`${path}/elastic_alerts`} component={ElasticAlerts} exact />
                <Route path={`${path}/blocking_url_stats/rss_stats`} component={RssBlockingUrlStats} exact />
                <Route path={`${path}/blocking_url_stats/diskfetcher_stats`} component={DiskfetcherBlockingUrlStats} exact />
                <Route path={`${path}/blocking_solution_config_files`} component={BlockingSolutionConfigFiles} exact />
                <Route path={`${path}/queue_sizes/search_by_date`} component={RabbitmqQueueSearchDate} exact />
                <Route path={`${path}/queue_sizes/search`} component={RabbitmqQueueSearch} exact />
                <Route path={`${path}/queue_sizes/:queue_name`} component={RabbitmqQueueSpecific} exact />
                <Route path={`${path}/queue_sizes`} component={RabbitmqQueueSizes} exact />
                <Route path={`${path}/command_dashboard`} component={CommandDashboard} exact />
                <Route path={`${path}/stack`} component={OurStack} exact />
                <Route path={`${path}/overall_system_reports/site_upload_spike_and_drop`} component={SiteUploadSpikeAndDrop} exact />
                <Route path={`${path}/pipeline_stage/end_to_end_url_tracker`} component={EndToEndUrlTracker} exact />
                <Route path={`${path}/resque_info/killed_and_failed_stats`} component={KilledAndFailedStatsGraphs} exact />
                <Route path={`${path}/god_report/fetch_god_reports_by_machine`} component={GodReportsByMachine} exact />
                <Route path={`${path}/god_report/search_by_machine`} component={GodReportsByMachine} exact />
                <Route path={`${path}/god_report/fetch_god_reports_by_job_type`} component={GodReportsByJob} exact />
                <Route path={`${path}/god_report/search_by_job_type`} component={GodReportsByJob} exact />
                <Route path={`${path}/clusterwise_worker_and_jobs_info`} component={ClusterwiseWorkerAndJobsInfo} exact />
                <Route path={`${path}/missing_fields`} component={MissingFields} exact />
                <Route path={`${path}/overall_system_reports/total_rss_and_feed_crawl_count`} component={TotalRSSTotalFeedcrawlCounts} exact />             
                <Route path={`${path}/overall_system_reports/field_upload_spike_and_drop`} component={FieldUploadSpikeAndDrop} exact />             
                <Route path={`${path}/site_wise_worker_count`} component={SiteWiseWorkerCount} exact />             
                <Route path={`${path}/proxy_log_reports`} component={ProxyLogReports} exact />             
                <Route path={`${path}/site_summary`} component={SiteSummary} exact />             



                {/* es page routes */}
                <Route path={`${path}/upload`} component={V2UploadsHome} exact />
                <Route path={`${path}/upload/new`} component={V2UploadsHome} exact />
                <Route path={`${path}/high_volume_clients_and_sites`} component={V2HighVolumeClientsIndex} exact />
                <Route path={`${path}/end_to_end_crawl_tracker`} component={V2EndToEndCrawlTracker} exact />              
                <Route path={`${path}/rss_process/new`} component={V2NewRssProcesses} exact />             
                <Route path={`${path}/rss_process`} component={V2NewRssProcesses} exact />             
                <Route path={`${path}/overall_system_reports/segments_take_more_time_by_ednu_stage_wise`} component={V2SegmentsTakeMoreTimeByEdnuStageWise} exact />
                <Route path={`${path}/rss/new`} component={V2SegmentLevelRssStats} exact />             
                <Route path={`${path}/rss`} component={V2SegmentLevelRssStats} exact />             
                <Route path={`${path}/segment/new`} component={V2SegmentLevelCrawlExtractionStats} exact />             
                <Route path={`${path}/segment`} component={V2SegmentLevelCrawlExtractionStats} exact />             
                <Route path={`${path}/test_site_stats`} component={V2TestSiteStats} exact />  
                <Route path={`${path}/test_site_stats/search`} component={V2TestSiteStats} exact />       
                <Route path={`${path}/df/new`} component={V2InternalDiskfetcherProcess} exact />             
                <Route path={`${path}/df`} component={V2InternalDiskfetcherProcess} exact />             
                <Route path={`${path}/volume_fluctuations`} component={V2VolumeFluctuations} exact />
                <Route path={`${path}/qa/`} component={V2QualityAnalysis} exact />   
                <Route path={`${path}/qa/pending`} component={V2QualityAnalysis} exact />       
                <Route path={`${path}/qa/search`} component={V2QualityAnalysis} exact /> 
                <Route path={`${path}/qa/:sitename`} component={V2EachQualityAnalysisPage} exact /> 
                <Route path={`${path}/qa_report/`} component={V2QualityAnalysisReports} exact />   
                <Route path={`${path}/qa_report/pending`} component={V2QualityAnalysisReports} exact />       
                <Route path={`${path}/qa_report/search`} component={V2QualityAnalysisReports} exact /> 

                {/* New QA report Pages Routes */}          
                <Route path={`${path}/new_qa_reports/`} component={AllNewQaReports} exact />   
                <Route path={`${path}/new_qa_reports/pending`} component={PendingNewQaReports} exact />       
                <Route path={`${path}/new_qa_reports/search`} component={SearchNewQaReports} exact /> 
                <Route path={`${path}/temp/new_qa_reports/:site_name`} component={SiteSpecificNewQaReport} exact /> 

                {/* New revamped QA Tool Page */}
                <Route path={`${path}/new_qa_reports/:site_name`} component={NewQaTool} exact /> 
                
                {/* Chat GPT Related Path */}
                <Route path={`${path}/chat_gpt_xpath_results`} component={XpathResults} exact />
              </Switch>
            </div>
        </div>}
      </div>
  )
}
