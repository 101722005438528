import React, { useState } from 'react';
import { Button, FormGroup, Input, Label } from 'reactstrap';
import axios from 'axios';
import { toast } from 'react-toastify';
import getSymbolFromCurrency from 'currency-symbol-map';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';

export default function OrganizationLevel({ orgName, country }) {
  const [loading, setLoading] = useState(false);

  const initialValues = {
    alertType: 'alert_by_records_count',
    max_records_count: 0,
    max_bill_amount: 0
  };

  const validationSchema = Yup.object({
    alertType: Yup.string().required('Alert type is required'),
    max_records_count: Yup.number()
    .typeError('Records count must be a number')
    .when('alertType', {
      is: 'alert_by_records_count',
      then:()=> Yup.number().required('Records count is required').positive('Must be greater than zero'),
      otherwise:()=> Yup.mixed().strip()
    }),
    max_bill_amount: Yup.number()
    .typeError('Bill Amount must be a number')
    .when('alertType', {
      is: 'alert_by_bill_amount',
      then:()=> Yup.number().required('Bill amount is required').positive('Must be greater than zero'),
      otherwise:()=> Yup.mixed().strip()
    })
  });


  const handleSubmit = (values, { setSubmitting }) => {
    setLoading(true);

    const payload = {
      billing_alerts: {
        alert_status: "alerts_enabled",
        alert_level: "organization_level",
        alert_type: values.alertType,
        max_records_count: values.max_records_count,
        max_bill_amount: values.max_bill_amount
      },
      page_type: "client",
      controller_name: "organization_billing_alerts"
    };

    axios.post(`/api/v2/organizations/${orgName}/organization_billing_alerts`, payload)
      .then(res => {
        toast.success(res.data.message);
        setLoading(false);
        setSubmitting(false);
      })
      .catch(err => {
        toast.error(err.response.data.message);
        setLoading(false);
        setSubmitting(false);
      });
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ values, isSubmitting, errors }) => {
          return(
            <Form>
              <div>
                <h6 className='fw-bold mb-4'>
                  Notify me when:
                </h6>
                <FormGroup check className='d-flex my-3 align-items-start'>
                  <Field type="radio" name="alertType" value="alert_by_records_count" className='me-3 mt-1'  />
                  {' '}
                  <Label check className='px-1'>
                    total records count for the month exceeds
                  </Label>
                  <div className='d-flex flex-column mx-2'>
                    <Field
                      name="max_records_count"
                      type="text"
                      placeholder='100'
                      className='form-control ms-2 m-0'
                      disabled={values.alertType !== 'alert_by_records_count'}
                      style={{ width: 240 }}
                    />
                    <ErrorMessage name="max_records_count" component="p" className="m-0 error-msg mt-1 mb-2 ml-2" />
                  </div>
                </FormGroup>
                <FormGroup check className='d-flex my-3 align-items-start'>
                  <Field type="radio" name="alertType" value="alert_by_bill_amount" className='me-3 mt-1' />
                  {' '}
                  <Label check className='px-1'>
                    total charges for the month exceeds
                  </Label>
                  <div className='d-flex align-items-center ms-2'>
                    <button className='btn text-primary px-1'>{getSymbolFromCurrency(country)}</button>
                    <div className='d-flex flex-column mx-2'>
                      <Field
                        name="max_bill_amount"
                        type="number"
                        className='form-control ms-2 m-0'
                        disabled={values.alertType !== 'alert_by_bill_amount'}
                        style={{ width: 240 }}
                      />
                      <ErrorMessage name="max_bill_amount" component="p" className="m-0 error-msg mt-1 mb-2" />
                    </div>
                  </div>
                </FormGroup>
              </div>
              {loading ? (
                <Button className='primary mx-2' disabled>
                  Loading ...
                </Button>
              ) : (
                <Button color="primary" className='mx-2' type="submit" disabled={isSubmitting}>
                  Set Alarm
                </Button>
              )}
            </Form>
          )}}
        </Formik>
      </>
  );
}

