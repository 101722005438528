import React from 'react';
import { useState, useEffect, useMemo } from 'react'
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";
import SpinnerLoader from '../../../Common/LoadingAnimation/SpinnerLoader.component';
import BillingDetailsForm from './BillingDetailsForm/BillingDetailsForm.component';
import axios from 'axios';
import countryList from 'react-select-country-list'
import { toast } from 'react-toastify';

// custom hooks
import useOrgIdentifer from '@hooks/org_identifier';

export default function ClientBillingDetailsPage({current_user, current_organization, take_my_org_details}) {
  const [address, setAddress] = useState({})
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState({})
  let { feas_id } = useParams();
  let history = useHistory();

  const org_identifier = useOrgIdentifer();

  const [buttonLoading, setButtonLoading] = useState(false);

  const [indianStates, setIndianStates] = useState();

  useEffect(() => {
    take_my_org_details(org_identifier);
  }, [org_identifier]);

  const options = useMemo(() => countryList().getData(), [])

  useEffect(() => {
    if (org_identifier) {
      axios.get(`/api/v1/organizations/${org_identifier}/feasibility_reports/${feas_id}/billing_address?page_type=client&controller_name=feasibility_reports`).then(res => {
        setAddress(res.data.address)
        setIndianStates(res.data.list_of_indian_states)
        setLoading(false)
      }).catch(err => {
        setLoading(false)
        // Refactor later
        if (err?.response?.data?.page_auth_failed) {
          history.push("/")
          toast.error(err?.response?.data?.message)
        }
        else if (!err?.response?.data?.org_auth_failed){
          toast.error(err?.response?.data?.message)
        }
      })
    }
  }, [org_identifier])

  const validateGSTNumber = (value) => {
    const pattern = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/;
    return !pattern.test(value);
  };

  const handleBilling = (e) => {
    e.preventDefault()
    setError({})
    let tempErrs = {}
    if (address.organization_name.length < 1) {
      tempErrs = {
        ...tempErrs, organization_name: true
      }
    }
    if (address.line1.length < 1) {
      tempErrs = {
        ...tempErrs, line1: true
      }
    }
    if (address.city.length < 1) {
      tempErrs = {
        ...tempErrs, city: true
      }
    }
    if (address.is_it_indian_client && address.state.length < 1) {
      tempErrs = {
        ...tempErrs, state: true
      }
    }
    if (address.is_it_indian_client && address.gstin_id && validateGSTNumber(address.gstin_id)) {
      tempErrs = {
        ...tempErrs, gstin_id: true
      }
    }
    if (address.country.length < 1) {
      tempErrs = {
        ...tempErrs, country: true
      }
    }
    setError(tempErrs)
    if (Object.values(tempErrs).length === 0) {
      setButtonLoading(true)
      axios.put(`/api/v1/organizations/${org_identifier}/feasibility_reports/${feas_id}`, {
        organization: address.country == "IN"
        ? { name: address.organization_name, gstin_id: address.gstin_id }
        : { name: address.organization_name },
        address: {
          line1: address.line1, line2: address.line2, city: address.city,
          state: address.state, zip: address.zip
        },
        page_type: "client", controller_name: "feasibility_reports"
      }).then(res => {
        toast.success(res.data.message)
        if (address?.skip_invoice) {
          history.push('/')
        }
        else {
          history.push(`/organizations/${org_identifier}/invoices/${res.data.invoice_id}`)
        }
      }).catch(err => {
        setButtonLoading(false)
        toast.error(err.response.data.message)
      })
    }
  }
  return (
    <>
      <div className='mt-2'>
        <SpinnerLoader loading={loading}>
          <BillingDetailsForm
            error={error}
            setError={setError}
            address={address}
            setAddress={setAddress}
            handleBilling={handleBilling} 
            buttonLoading={buttonLoading}
            indianStates={indianStates}
          />
        </SpinnerLoader>
      </div>
    </>
  )
}
