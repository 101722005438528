import React from 'react';
import Select from 'react-select';
import { Field } from 'formik';

const MultiSelectField = ({ name, options }) => {
  return (
    <Field name={name}>
      {({ field, form }) => {
        const handleChange = (selectedOptions = []) => {
          const allOptionSelected = selectedOptions.some(item => item?.value === "All");
          const isEmpty = selectedOptions.length === 0;
          const isAllSelected = selectedOptions.length === options.length - 1;
          const lastSelectedIsAll = selectedOptions[selectedOptions.length - 1]?.value === 'All';

          if (isEmpty || isAllSelected || lastSelectedIsAll) {
            form.setFieldValue(name, [{ value: "All", label: 'All' }]);
          } else if (allOptionSelected && selectedOptions.length > 1) {
            const filteredOptions = selectedOptions.filter(item => item.value !== "All");
            form.setFieldValue(name, filteredOptions);
          } else {
            form.setFieldValue(name, selectedOptions);
          }
        };

        const value = field.value || [];

        return (
          <Select
            value={value}
            classNamePrefix="select"
            isMulti
            options={options}
            className="basic-multi-select allowed_on_org"
            onChange={handleChange}
          />
        );
      }}
    </Field>
  );
};

export default MultiSelectField;
