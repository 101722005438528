/**
 * This component is common to all users. When user clicks on profile drop-down in header and clicks on the "organization"
 * option, this card is displayed at the top. This card displays all the necessary information which user enters during the
 * organization creation process. It also allows users to edit details by clicking "Edit Organization Details"
 */
import axios from 'axios';
import React from 'react';
import { useState, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Card, CardHeader, CardBody, Row, Col } from 'reactstrap';
import SpinnerLoader from '../../../Common/LoadingAnimation/SpinnerLoader.component';

export default React.memo(function OrganizationDetailsCard({organization_name}) {

  const BASE_URI = useMemo(() => `/api/v1/organizations/${organization_name}?page_type=client&controller_name=organizations`, []);
  const [loading, setLoading] = useState(false);
  const [displayData, setDisplayData] = useState({}); // Data displayed in the card


  // Helper function to fetch the organization display data from backend DB
  async function getDataFromDB(signal) {
    setLoading(true);
    try {
      const response = await axios.get(BASE_URI, { signal: signal });
      if (response?.status === 200) {
        const displayInfo = {
          billing_name: await response?.data?.organization?.billing_name,
          business_type: await response?.data?.organization?.business_type,
          business_number: await response?.data?.organization?.business_number,
          business_duration: await response?.data?.organization?.business_duration,
          tax_number: await response?.data?.organization?.tax_number,
          ein: await response?.data?.organization?.ein,
          phone_number: await response?.data?.organization?.phone_number,
          gstin_id: await response?.data?.organization?.gstin_id,
          pan_number: await response?.data?.organization?.pan_number,
          address: await response?.data?.organization?.address
        }
        setDisplayData(displayInfo);
        setLoading(false);
      }
    }
    catch (err) {
      setLoading(false);
      // Refactor later
      if (err?.response?.data?.page_auth_failed) {
        history.push("/")
        toast.error(err?.response?.data?.message)
      }
      else if (!err?.response?.data?.org_auth_failed){
        toast.error(err?.response?.data?.message)
      }
    }
  }

  useEffect(async () => {
    const controller = new AbortController();

    // Fetch data to be displayed in the table
    await getDataFromDB(controller.signal);

    // cleanup
    return () => {
      controller.abort();
    }
  }, [organization_name]);

  return (
    <>
      <SpinnerLoader loading={loading}>
        <Card>
          <CardHeader className='p-4'>
            <div className='d-flex justify-content-between align-center'>
              <h4 className='fw-lighter m-0'>
                {displayData?.billing_name}
              </h4>
              <a href={`/organizations/${organization_name}/edit`} className='btn btn-light'>
                Edit Organization Details
              </a>
            </div>
          </CardHeader>
          <CardBody className='p-4'>
            <Row>
              <Col lg={"4"}>
                <div className='d-flex my-2'>
                  <h6 className='fw-bold my-0 me-2'>Billing Name:</h6>
                  <h6 className='my-0'>{displayData?.billing_name || ""}</h6>
                </div>
                <div className='d-flex my-2'>
                  <h6 className='fw-bold my-0 me-2'>Business Type:</h6>
                  <h6 className='my-0'>{displayData?.business_type || ""}</h6>
                </div>
                <div className='d-flex my-2'>
                  <h6 className='fw-bold my-0 me-2'>Business Number:</h6>
                  <h6 className='my-0'>{displayData?.business_number || ""}</h6>
                </div>
                <div className='d-flex my-2'>
                  <h6 className='fw-bold my-0 me-2'>Tax Number:</h6>
                  <h6 className='my-0'>{displayData?.tax_number || ""}</h6>
                </div>
                <div className='d-flex my-2'>
                  <h6 className='fw-bold my-0 me-2'>EIN:</h6>
                  <h6 className='my-0'>{displayData?.ein || ""}</h6>
                </div>
              </Col>
              <Col lg={"4"}>
                <div className='d-flex my-2'>
                  <h6 className='fw-bold my-0 me-2'>Phone Number:</h6>
                  <h6 className='my-0'>{displayData?.phone_number || ""}</h6>
                </div>
                <div className='d-flex my-2'>
                  <h6 className='fw-bold my-0 me-2'>GSTIN:</h6>
                  <h6 className='my-0'>{displayData?.gstin_id || ""}</h6>
                </div>
                <div className='d-flex my-2'>
                  <h6 className='fw-bold my-0 me-2'>PAN Number:</h6>
                  <h6 className='my-0'>{displayData?.pan_number || ""}</h6>
                </div>
              </Col>
              <Col lg={"4"}>
                <div className='d-flex my-2'>
                  <h6 className='fw-bold my-0 me-2'>Street:</h6>
                  <h6 className='my-0'>{displayData?.address?.line1 || ""}</h6>
                </div>
                <div className='d-flex my-2'>
                  <h6 className='fw-bold my-0 me-2'>City:</h6>
                  <h6 className='my-0'>{displayData?.address?.city || ""}</h6>
                </div>
                <div className='d-flex my-2'>
                  <h6 className='fw-bold my-0 me-2'>State:</h6>
                  <h6 className='my-0'>{displayData?.address?.state_name || ""}</h6>
                </div>
                <div className='d-flex my-2'>
                  <h6 className='fw-bold my-0 me-2'>Zipcode:</h6>
                  <h6 className='my-0'>{displayData?.address?.zip || ""}</h6>
                </div>
                <div className='d-flex my-2'>
                  <h6 className='fw-bold my-0 me-2'>Country:</h6>
                  <h6 className='my-0'>{displayData?.address?.country_name || ""}</h6>
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </SpinnerLoader>
    </>
  )
})
