import React, {forwardRef, useImperativeHandle, useState, useEffect} from 'react';
import axios from 'axios'
import { toast } from 'react-toastify';
import Loader from 'react-loader-spinner';

const AddressDetails = forwardRef(({data, setData, myOrganization}, ref) => {
    const [billingName, setBillingName] = useState('')
    const [user, setUser] = useState('')
    const [phoneNumber, setPhoneNumber] = useState('')
    const [loading, setLoading] = useState(false)
    const [clientCountry, setClientCountry] = useState('')
    const [gstin, setGstin] = useState('')
    const [line1, setLine1] = useState('')
    const [line2, setLine2] = useState('')
    const [city, setCity] = useState('')
    const [zip, setZip] = useState('')
    const [sstate, setSstate] = useState('')
    const [isItIndianClient, setIsItIndianClient] = useState(false)
    const [indianStates, setIndianStates] = useState()

    useEffect(() => {
        setUser(myOrganization?.org_identifier || '')
    }, [myOrganization])

    useEffect(() => {
        if ("address" in data){
            setLoading(true)
            setBillingName(data?.billing_name || '')
            setClientCountry(data?.address?.country_name || '')
            setPhoneNumber(data?.phone_number || '')
            setGstin(data?.gstin_id || '')
            setLine1(data?.address?.line1 || '')
            setLine2(data?.address?.line2 || '')
            setCity(data?.address?.city || '')
            setZip(data?.address?.zip || '')
            setSstate(data?.address?.state || '')
            setIsItIndianClient(data?.address?.is_it_indian_client || false)
            setIndianStates(data?.list_of_indian_states)
            setLoading(false)
        }
    }, [data])

    const validateGSTNumber = (value) => {
      const pattern = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/;
      return !pattern.test(value);
    };

    useImperativeHandle(ref, () => ({
        isValidated() {
            if (line1.trim().length === 0 && city.trim().length === 0){
                toast.error('Please enter Street line 1 and city details')
                return false
            }
            if (line1.trim().length === 0){
                toast.error('Please enter Street line details')
                return false
            }
            if (city.trim().length === 0){
                toast.error('Please enter City details')
                return false
            }
            if (isItIndianClient && gstin.trim().length != 0 && validateGSTNumber(gstin)){
                toast.error('GST number should be a valid number!')
                return false
            }
            else{
                setData({
                    ...data,
                    address: {
                        ...data.address, city, line1, line2, state: sstate, zip
                    },
                    billing_name: billingName, gstin_id: gstin, phone_number: phoneNumber
                })
                return true
            }
        }
    }));


    return (
        <>
        {
            loading ? <Loader type="Puff" color="#00BFFF" height={60} width={60} className='pc-loader' /> :
        <div className='px-2 py-4'>
            <div className='my-4'>
                <h6 className='fw-bold'>Billing Name </h6>
                <input type="text" className="form-control" name="billing_name" placeholder="Billing name"
                    value={billingName} onChange={(e) => setBillingName(e.target.value)} />
              </div>

            <div className='my-4'>
                <h6 className='fw-bold'>Phone Number </h6>
                <input type="text" className='form-control' name="phone_number" placeholder='phone number'
                    value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} />
            </div>

            { clientCountry.toLowerCase() === "india" &&
            <div className='my-4'>
                <h6 className='fw-bold'>GSTIN </h6>
                <input type="text" className="form-control" name="gstin_number" placeholder='please enter your GSTIN Number'
                    value={gstin} onChange={(e) => setGstin(e.target.value)} />
            </div>}

            <div className='my-4'>
                <h6 className='fw-bold'>Street Line 1* </h6>
                <input type="text" className='form-control' name="line1" placeholder='Required'
                    value={line1} onChange={(e) => setLine1(e.target.value)} />
            </div>

            <div className='my-4'>
                <h6 className='fw-bold'>Street Line 2 </h6>
                <input type="text" className='form-control' name="line2" placeholder='optional'
                    value={line2} onChange={(e) => setLine2(e.target.value)} />
            </div>

            <div className='my-4'>
                <h6 className='fw-bold'>City* </h6>
                <input type="text" className='form-control' name="city" placeholder='Required'
                    value={city} onChange={(e) => setCity(e.target.value)} />
            </div>

            { clientCountry.toLowerCase() === "india" &&
            <div className='my-4'>
                <h6 className='fw-bold'>State* </h6>
                <select className='form-control show-arrow' name="state" value={sstate}
                    onChange={(e) => setSstate(e.target.value)}>
                    {indianStates?.map(each => {
                      return (
                        <option value={each.value} key={each.value}>{each.label}</option>
                      )
                    })}
                </select>
            </div>}

            <div className='my-4'>
                <h6 className='fw-bold'>ZIP </h6>
                <input type="text" className='form-control' name="zip" placeholder='optional'
                    value={zip} onChange={(e) => setZip(e.target.value)} />
            </div>
        </div>}
        </>
    );
});

export {AddressDetails as default}
