import React, { useEffect, useState } from 'react'
import { useParams, useHistory } from "react-router-dom";

import StepZilla from "react-stepzilla";
import { toast } from 'react-toastify';
import { Card, Container } from 'reactstrap';
import axios from 'axios';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

import SchemaDetails from './Formik/SchemaDetails.component';
import SiteDetails from './Formik/SiteDetails.component';
import Breadcrumbs from '../../Common/BreadCrumb/BreadCrumbs.component';
import Loader from "react-loader-spinner";


export default function EditFeasibilityCheck() {

  const [data, setData] = useState({});
  const [globalTableData, setGlobalTableData] = useState({});
  const [v2Data, setV2Data] = useState({});
  const [loading, setLoading] = useState(true);
  const [dataToggle, setDataToggle] = useState(true);
  const [dataToggle2, setDataToggle2] = useState(false);

  let history = useHistory();
  let { feasid } = useParams();
  const formikRef = React.useRef();

  useEffect(() => {
    setLoading(true)
    axios.get(`/api/v1/admins/feasibility_checks/${feasid}`).then(res => {
      if (!(res.data.feasibility_report?.feasibility_report_details?.can_edit)){
        history.push(`/admins/feasibility_checks/${res.data.feasibility_report.id}`)
      }
      setLoading(false)
    }).catch(err => {
      toast.error(err.response.data.message)
      setLoading(false)
    })
  }, [dataToggle])

  useEffect(() => {
    axios.get(`/api/v1/admins/feasibility_checks/${feasid}/edit?feasibility_report_id=${feasid}`).then(res => {
      setData(res.data.feasibility_report)
    }).catch(err => {
      toast.error(err.response.data.message)
    })
  }, [dataToggle, dataToggle2])

  const steps = [
    {
      name: 'Schema Details',
      component: <SchemaDetails
        data={data}
        v2Data={v2Data}
        setV2Data={setV2Data}
        dataDisable={true}
        setDataToggle={setDataToggle}
        globalTableData={globalTableData}
        dataToggle={dataToggle}
        setGlobalTableData={setGlobalTableData}
        formikRef={formikRef} />
    },
    {
      name: 'Site Details', component: <SiteDetails
        data={data}
        setDataToggle={setDataToggle}
        dataToggle={dataToggle}
        setDataToggle2={setDataToggle2}
        v2Data={v2Data}
        setV2Data={setV2Data}
        dataDisable={true}
        globalTableData={globalTableData}
        setGlobalTableData={setGlobalTableData}
        formikRef={formikRef}
         />
    },
  ]

  Yup.addMethod(Yup.object, 'unique', function (propertyName, message) {
    return this.test('unique', message, function (value) {
      if (!value || !value[propertyName]) {
        return true;
      }

      const { path } = this;
      const options = [...this.parent];
      const currentIndex = options.indexOf(value);

      const subOptions = options.slice(0, currentIndex);

      if (subOptions.some((option) => option[propertyName] === value[propertyName])) {
        throw this.createError({
          path: `${path}.${propertyName}`,
          message,
        });
      }

      return true;
    });
  });

  const numberRegex =  /^\d+(\.\d+)?$/

  
  const validationSchema = Yup.object().shape({
    schema_fields_details: Yup.array().of(
      Yup.object({}).strip(false)
    ),
    site_details: Yup.array().of(
      Yup.object().shape({
        site_setup_fee: Yup.string()
          .matches(numberRegex, 'Site setup fee must be a valid number')
          .required('Site setup fee is required'),
        monthly_maintenance_fee: Yup.string()
          .matches(numberRegex, 'Monthly maintenance fee must be a valid number')
          .required('Monthly maintenance fee is required'),
        volume_charges: Yup.string()
          .matches(numberRegex, 'Volume charges must be a valid number')
          .required('Volume charges are required'),
        number_of_free_records: Yup.string() 
          .matches(numberRegex, 'Number of free records must be a valid number')
          .required('Number of free records is required'),
        isEnabled: Yup.boolean(),
      })
    ),
    overall_feasibility: Yup.string().required("Please select feasibility"),
    comments: Yup.string(),
    additional_information: Yup.string(),
    send_feasibility_report:Yup.boolean(),
    skip_agreements: Yup.boolean(),
    skip_invoice: Yup.boolean(),
    use_custom_contracts: Yup.boolean(),
    image_downloads_fee: Yup.string().matches(numberRegex, 'This Field must be a valid number'),
    two_days_of_delivery_fee: Yup.string().matches(numberRegex, 'This Field must be a valid number'),
    hosted_indexing_fee: Yup.string().matches(numberRegex, 'This Field must be a valid number'),
    merge_files_fee: Yup.string().matches(numberRegex, 'This Field must be a valid number'),
  })

  return (
    <div>
      <Breadcrumbs parent={"Feasibility Check"} title={feasid}
        childrenObj={[{ name: 'Feasibility Checks', url: '/admins/feasibility_checks' }]} />

      {loading ? <Loader type="Puff" color="#00BFFF" height={60} width={60} className='pc-loader' /> :
      <div>
        <Formik
          initialValues={{
            schema_fields_details: data?.schema_fields_details || [],
            additional_information: data?.additional_information || "",
            fields_to_extract : data?.fields_to_extract || "",
            site_details: data?.websites?.map(item => ({
              site_id: item?.id,
              site_url: item?.sample_url,
              site_setup_fee: item?.site_setup_fee || 0,
              monthly_maintenance_fee: item?.monthly_maintenance_fee || 0,
              volume_charges: item?.volume_charges || 0,
              number_of_free_records: item?.number_of_free_records || 0,
              isEnabled: item?.feasibility_status == 'feasible' ? true : false
            })) || [],
            overall_feasibility: '',
            comments:'',
            any_additional_cost:data?.any_additional_cost || '',
            send_feasibility_report:true,
            skip_agreements: data?.skip_agreements || false,
            skip_invoice: data?.skip_invoice || false,
            use_custom_contracts: data?.use_custom_contracts ? true : false,
            merge_files_fee: String(data?.additional_details?.find(item => item?.key === 'merge_files')?.fee || 0),
            hosted_indexing_fee: String(data?.additional_details?.find(item => item?.key === 'hosted_indexing')?.fee || 0),
            two_days_of_delivery_fee: String(data?.additional_details?.find(item => item?.key === 'two_days_of_delivery')?.fee || 0),
            image_downloads_fee: String(data?.additional_details?.find(item => item?.key === 'image_downloads')?.fee || 0)
          }}                
          validationSchema={validationSchema}
          innerRef={formikRef}
          enableReinitialize={true}
        >
        {()=>{
          return(
            <Form>
              <Card className='p-4'>
                <StepZilla 
                steps={steps} 
                showSteps={true}
                showNavigation={true}
                stepsNavigation={true}
                prevBtnOnLastStep={true} 
                dontValidate={false}
                />
              </Card>
            </Form>
          )
        }}
        </Formik>
    </div>}
  </div>
  )
}
