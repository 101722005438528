import React from 'react';
import { useState, useEffect, useContext } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { cardImages } from '../../Common/CardImages/CardImages';
import Breadcrumb from '../../Common/BreadCrumb/BreadCrumbs.component';
import { Card, CardBody, CardFooter, CardHeader, Container, Input, Row, Col, Alert } from 'reactstrap';
import { Calendar } from 'react-feather';
import { toast } from 'react-toastify';
import { useStripe, useElements, CardNumberElement,
  CardExpiryElement, CardCvcElement} from '@stripe/react-stripe-js';
import Loader from 'react-loader-spinner';
import axios from 'axios';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';

// custom hooks
import useOrgIdentifer from '@hooks/org_identifier';

export default function MakePayment({ current_user, current_organization, take_my_org_details }) {
  let { invoiceId } = useParams();
  const [invoiceData, setInvoiceData] = useState({})
  const [cardsList, setCardsList] = useState([])
  const [loading, setLoading] = useState(true)
  const [errors, setErrors] = useState({})
  const [loading2, setLoading2] = useState(true)
  const [couponcode, setCouponcode] = useState("")
  const [toggleCard, setToggleCard] = useState(false)
  const [toggle, setToggle] = useState(false)
  const [defaultCardID, setDefaultCardID] = useState(0)
  const stripe = useStripe();
  const [partialAmount, setPartialAmount] = useState('')
  const elements = useElements();
  let history = useHistory();
  const [loadingBtns, setLoadingBtns] = useState(false)
  const [errs, setErrs] = useState('')

  const org_identifier = useOrgIdentifer();

  useEffect(() => {
    take_my_org_details(org_identifier);
  }, [org_identifier]);

  useEffect(() => {
    axios.get(`/api/v1/organizations/${org_identifier}/invoices/${invoiceId}?page_type=client&controller_name=invoices`).then(res => {
      /* Payment is being processed */
      if (res.data.invoice.any_payments_being_processed) {
        history.push(`/organizations/${org_identifier}/invoices/${invoiceId}`)
      }

      /* Post payment */
      else if (res.data.invoice.service_type === 'freshbooks' && res.data.invoice.all_payments_synced && res.data.invoice.invoice_status === "paid") {
        history.push(`/organizations/${org_identifier}/invoices/${invoiceId}`)
      }

      if (res.data.invoice.service_to_use === "quickbooks") {
        history.push(`/organizations/${org_identifier}/invoices/${invoiceId}`)
      }
      setInvoiceData(res.data.invoice)
      setLoading(false)
    }).catch(err => {
      setInvoiceData({})
      setLoading(false)
      // Refactor later
      if (err?.response?.data?.page_auth_failed) {
        history.push("/")
        toast.error(err?.response?.data?.message)
      }
      else if (!err?.response?.data?.org_auth_failed){
        toast.error(err?.response?.data?.message)
      }
    })
  }, [toggle])

  useEffect(() => {
    if (org_identifier) {
      axios.get(`/api/v1/organizations/${org_identifier}/cards?page_type=client&controller_name=cards`).then(res => {
        setCardsList(res.data.cards)
        res.data.cards.forEach(each => {
          if (each.default_status == "default") {
            setDefaultCardID(each.id)
          }
        })
        setLoading2(false)
      }).catch(err => {
        setCardsList([])
        setLoading2(false)
        // Refactor later
        if (err?.response?.data?.page_auth_failed) {
          history.push("/")
          toast.error(err?.response?.data?.message)
        }
        else if (!err?.response?.data?.org_auth_failed){
          toast.error(err?.response?.data?.message)
        }
      })
    }
  }, [org_identifier])

  const makePayment = (id = 1) => {
    setLoadingBtns(true)
    if (id === 1) {
      axios.post(`/api/v1/organizations/${org_identifier}/invoices/${invoiceId}/payments`, {
        payment_details: {
          amount: invoiceData.payable_amount_in_cents,
          invoice_id: invoiceId, deduct_type: "full",
          card_id: defaultCardID
        },
        page_type: "client",
        controller_name: "new_payments"
      }).then(res => {
        toast.success(res.data.message)
        history.push(`/organizations/${org_identifier}/invoices/${invoiceId}`)
        setLoadingBtns(false)
      }).catch(err => {
        toast.error(err.response.data.message)
        setLoadingBtns(false)
      })
    }
    else {
      axios.post(`/api/v1/organizations/${org_identifier}/invoices/${invoiceId}/payments`, {
        payment_details: {
          amount: invoiceData.payable_amount_in_cents,
          invoice_id: invoiceId, deduct_type: "full",
          stripeToken: id
        },
        page_type: "client",
        controller_name: "new_payments"
      }).then(res => {
        toast.success(res.data.message)
        toast.info("Payment is being processed, please refresh the page after a minute to downoad the invoice.")
        history.push(`/organizations/${org_identifier}/invoices/${invoiceId}`)
        setLoadingBtns(false)
      }).catch(err => {
        toast.error(err.response.data.message)
        setLoadingBtns(false)
      })
    }
  }

  const addCard = (token) => {
    makePayment(token.id)
  }

  const handleSubmit = async (event) => {
    setLoadingBtns(true)
    event.preventDefault();
    if (elements == null) {
      setLoadingBtns(false)
      return;
    }
    if (Object.values(errs).filter(each => each != '').length > 0) {
      setLoadingBtns(false)
      return;
    }
    const { error, token } = await stripe.createToken(elements.getElement(CardNumberElement))

    if (error) {
      setLoadingBtns(false)
      toast.error(error)
    } else if (token.id) {
      addCard(token)
    }
  };

  const applyCoupon = (e) => {
    setLoadingBtns(true)
    e.preventDefault()
    axios.post(`/api/v1/organizations/${org_identifier}/redeem_coupons`, {
      redeem_coupons: {
        coupon_code: couponcode,
        invoice_id: invoiceId
      },
      page_type: "client",
      controller_name: "redeem_coupons"
    }).then(res => {
      toast.success(res.data.message)
      setLoadingBtns(false)
      setToggle(curr => !curr)
    }).catch(err => {
      toast.error(err.response.data.message)
      setLoadingBtns(false)
    })
  }

  //ValidationSchema for Coupon
  const validationSchema = Yup.object().shape({
    coupon_code: Yup.string()
  });

  // Submit Handler
  const applyCupon = async (values, { setSubmitting, setFieldError }) => {
    const applyRequestParameters = {
      redeem_coupons: {
        coupon_code: values.coupon_code,
        invoice_id: invoiceId
      }
    };

    if(values?.coupon_code?.length == 0){
      setFieldError('coupon_code', "Coupon code is required..!")
      return
    }

    try {
      const response = await axios.post(`/api/v1/organizations/${org_identifier}/redeem_coupons`, applyRequestParameters);
      toast.success(response?.data?.message);
      setToggle(curr => !curr);
    } catch (error) {
      const errorMessage = error?.response?.data?.message || 'An error occurred';
      setFieldError('coupon_code', errorMessage);  
    }
    setSubmitting(false);
  };

  const removeCoupon = (e) => {
    e.preventDefault()
    axios.delete(`/api/v1/organizations/${org_identifier}/redeem_coupons/${invoiceData?.org_redeem_coupon?.id}?page_type=client&controller_name=redeem_coupons`)
      .then(res => {
        toast.success(res.data.message)
        setToggle(curr => !curr)
      }).catch(err => {
        toast.error(err.response.data.message)
      })
  }

  const partialDeduct = () => {
    setLoadingBtns(true)
    setErrors({})
    try {
      if (partialAmount.split(".").length === 2 && partialAmount.split(".")[1].toString().length === 2) {
        if (invoiceData?.service_to_use === "freshbooks") {
          axios.post(`/api/v1/organizations/${org_identifier}/invoices/${invoiceId}/payments`, {
            payment_details: {
              amount: partialAmount * 100, invoice_id: invoiceId, deduct_type: 'partial',
              card_id: defaultCardID
            },
            page_type: "client",
            controller_name: "new_payments"
          }).then(res => {
            toast.success(res.data.message)
            toast.info("Payment is being processed, please refresh the page after a minute to downoad the invoice.")
            history.push(`/organizations/${org_identifier}/invoices/${invoiceId}`)
          }).catch(err => {
            toast.error(err.response.data.message)
          })
        }
      }
      else {
        setErrors({ anyError: 'Invalid format, should be of the format 10.25 with two decimal places' })
        setLoadingBtns(false)
      }
    } catch (error) {
      setErrors({ anyError: 'Invalid format, should be of the format 10.25 with two decimal places' })
      setLoadingBtns(false)
    }
  }

  const handleChange = (event) => {
    let tempErrs = errs
    if (event.error) {
      tempErrs = { ...tempErrs, [event.elementType]: event.error.message }
    } else {
      tempErrs = { ...tempErrs, [event.elementType]: '' }
    }
    setErrs(tempErrs)
  }
  return (
    <div>
      <Container fluid>
        <Breadcrumb parent={"Invoices"} title="Payment"
          childrenObj={[{
            name: "Payment", url: `/organizations/${org_identifier}/invoices/`
          }, {
            name: `Invoice for Feasibility Report #${invoiceId}`, url: `/organizations/${org_identifier}/invoices/${invoiceId}/`
          }]} />

        {(loading || loading2) ? <Loader type="Puff" color="#00BFFF" height={60} width={60} className='pc-loader' /> :
        <>
          <Card>
            <CardBody>
              <div className='d-flex justify-content-between'>
                <h5><a href={`/organizations/${org_identifier}/invoices/${invoiceId}/`}
                  className='text-primary fw-bold'>
                  {`Invoice for Feasibility Report #${invoiceId}`}
                </a></h5>
                <h5>
                  Amount: {invoiceData.balance_due && invoiceData.balance_due}
                </h5>
              </div>
              <div className='d-flex justify-content-between align-center mt-3'>
                <p className='d-flex align-center text-muted mb-0'>
                  <Calendar size={20} /> &nbsp;
                  {new Date().toString().substring(0, 15)}
                </p>
                {invoiceData.is_coupon_applied ? <form onSubmit={removeCoupon} className="d-flex">
                  <input type="text" className="form-control me-1" disabled
                    value={invoiceData.org_redeem_coupon.coupon_code}
                    onChange={(e) => console.log(e.target.value)} />
                  <button type="submit" className='btn btn-danger white-space-no-wrap'>
                    Remove Coupon
                  </button>
                </form>
                    : 
                    <Formik
                      initialValues={{ coupon_code: '' }}
                      validationSchema={validationSchema}
                      onSubmit={applyCupon}
                    >
                      {({ isSubmitting }) => (
                        <Form className="d-flex flex-row">
                          <div className="flex-grow-1">
                            <Field
                              type="text"
                              name="coupon_code"
                              className="form-control me-1"
                              placeholder="Enter coupon code"
                              style={{ height: '40px' }}
                            />
                            <ErrorMessage name="coupon_code" component="div" className='m-0 error-msg mt-1 mb-2' />
                          </div>
                          <button type="submit" className="btn btn-primary white-space-no-wrap" disabled={isSubmitting} style={{ height: '40px', marginLeft:"6px" }}>
                            {isSubmitting ? 'Applying...' : 'Apply Coupon'}
                          </button>
                        </Form>
                      )}
                    </Formik>}
                  </div>
                </CardBody>
              </Card>
              {Object.values(errs)?.map((each, index) => (
                each != '' && <Alert color='danger' key={index}>{each}</Alert>
              ))}
              {cardsList.length > 0 ?
              <Row>
                <Col lg={6}>
                  <Card>
                    <CardHeader className='d-flex justify-content-between align-center p-4'>
                      <h6> {toggleCard ? "Add New Card" : "Choose Existing Card"} </h6>
                      <button className='btn btn-bordered'
                        onClick={() => setToggleCard(curr => !curr)}>
                        {toggleCard ? "Choose Existing card" : "+ Add new card"}
                      </button>
                    </CardHeader>
                    <CardBody className='m-0 p-4'>
                      {toggleCard ? (
                        <form onSubmit={handleSubmit} className='px-3'>
                          <div className='form-group my-3'>
                            <label className="form-label">
                              Card number
                            </label>
                            <CardNumberElement className='form-control' onChange={handleChange} />
                          </div>
                          <div className='form-group my-3'>
                            <label className="form-label">
                              Expiration date
                            </label>
                            <CardExpiryElement className='form-control' onChange={handleChange} />
                          </div>
                          <div className='form-group my-3'>
                            <label className="form-label">
                              CVC
                            </label>
                            <CardCvcElement className='form-control' onChange={handleChange} />
                          </div>
                          <button className='btn btn-success my-3 float-end' type='submit'
                            disabled={(!stripe || !elements) || loadingBtns} > Pay {invoiceData.balance_due}
                          </button>
                        </form>
                      ) : <>
                      {cardsList.map(each => {
                        return (
                          <div
                            className={`d-flex align-center justify-content-evenly py-4 ${defaultCardID == each.id ? "bg-default" : "bg-white"}`}
                            key={each.id}>
                            <img src={cardImages[each.brand.toLowerCase()]}
                              style={{ height: 60, width: 80 }} />
                            <h4 className='d-flex align-center m-0 p-0'> **** **** **** {each.last4} </h4>
                            <Input type="radio" onClick={() => setDefaultCardID(each.id)}
                              onChange={() => console.log(`clicked`)} checked={defaultCardID === each.id}
                              className='d-flex align-center m-0 p-0' />
                          </div>
                        )
                      })}
                      <button className='btn btn-success my-3 float-end' disabled={loadingBtns}
                        onClick={() => makePayment()}> Pay {invoiceData.balance_due}
                      </button>
                  </>}
                </CardBody>
              </Card>
            </Col>

            {current_user?.is_admin &&
            <Col lg={6}>
              <Card className='mt-4'>
                <CardHeader className='p-4'>
                  Partial Amount
                </CardHeader>
                <CardBody>
                  <div className='d-flex align-items-center'>
                    <h6 className='my-0 fw-bold'>Enter Amount: </h6>
                    <input type="text" className='form-control' value={partialAmount}
                      onChange={(e) => setPartialAmount(e.target.value)} />
                  </div>
                  {errors.anyError && <p className='text-danger my-1'> {errors.anyError} </p>}
                </CardBody>
                <CardFooter className='p-3'>
                  <button className='btn btn-success float-end' disabled={loadingBtns}
                    onClick={partialDeduct}>Partial Deduct</button>
                </CardFooter>
              </Card>
          </Col>}
        </Row> :
        <Card>
          <CardHeader className='m-0 p-4'>
            Add a new card
          </CardHeader>
          <CardBody className='m-0 p-4'>
            <form onSubmit={handleSubmit}>
              <div className='form-group my-3'>
                <label className="form-label">
                  Card number
                </label>
                <CardNumberElement className='form-control' onChange={handleChange} />
              </div>
              <div className='form-group my-3'>
                <label className="form-label">
                  Expiration date
                </label>
                <CardExpiryElement className='form-control' onChange={handleChange} />
              </div>
              <div className='form-group my-3'>
                <label className="form-label">
                  CVC
                </label>
                <CardCvcElement className='form-control' onChange={handleChange} />
              </div>
              <button type="submit" className='btn btn-success mt-4'
                disabled={(!stripe) || loadingBtns}>
                Pay {invoiceData.balance_due}
              </button>
            </form>
          </CardBody>
      </Card>}
    </>}
  </Container>
</div>
  )
}
