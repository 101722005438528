import React from 'react';
import { Formik, Form, Field, FieldArray, ErrorMessage, useFormikContext } from 'formik';
import { Button, Row, Col, Table } from 'reactstrap';
import XLSX from 'xlsx';
import Dropzone from 'react-dropzone-uploader';
import { toast } from 'react-toastify';
import axios from 'axios';

export default function CrawlForm({ existingValues, dataDisable = false }){

  const { setFieldValue } = useFormikContext()

  const getUploadParams = ({ meta }) => { return { url: 'https://httpbin.org/post' } }

  const InitialValues = [
    {field_name:"uniq_id", data_type:"string", is_mandatory:"yes", default_value:"system_generated", sample_value:"default",comments:""},
    {field_name:"crawl_timestamp", data_type:"date", is_mandatory:"yes", default_value:"system_generated", sample_value:"default",comments:""},
    {field_name:"pageurl", data_type:"string", is_mandatory:"yes", default_value:"system_generated", sample_value:"",comments:""},
    {field_name: "", data_type: "", is_mandatory: "no", default_value: "", sample_value: "", comments: "" }
  ]

  function normalized_field_name(text) {
    return text.replace(/[^a-z0-9A-Z]+/g, ' ').trim().replace(/\s+/g, '_').toLowerCase();
  };

  const handleChangeStatus = (uploadData, status, form) => {
    const { schema_fields_details } = form
    const { meta, file } = uploadData
    const dataTypes = ["string", "date","datetime","boolean","float","integer"]

    if (status === "done") {
      var fileReader = new FileReader();
      fileReader.onload = function (event) {
        var workbook = XLSX.read(event.target.result, {
          type: "binary"
        });
        workbook.SheetNames.forEach(sheet => {
          let rowObject = XLSX.utils.sheet_to_row_object_array(
            workbook.Sheets[sheet]
          );
          let validRecords = rowObject.every(record => dataTypes.includes(record?.data_type) )
          if (rowObject.length === 0) {
            toast.error("Uploaded file is empty")
          } else if(!validRecords){
            toast.error("Uploaded file has invalid records")
          } else if ("field_name" in rowObject[0] && "data_type" in rowObject[0]) {
            if(schema_fields_details?.length == 4 && schema_fields_details[3].field_name.length == 0) form?.remove(3)
            rowObject?.map(item => {
              form?.push({ 
                field_name: item?.field_name ? normalized_field_name(item?.field_name) : "", 
                data_type: item?.data_type?.toLowerCase() || "",
                is_mandatory: item?.is_mandatory?.toLowerCase() || 'no', 
                default_value: item?.default_value || "", 
                sample_value: item?.sample_value || "", 
                comments: item?.comments || ""
              })
            })
          } else {
            toast.error("Please keep column headers as mentioned in sample excel file")
          }
        });
      };
      fileReader.readAsBinaryString(file);
    } else if (status === "removed") {
      if(existingValues && existingValues?.length > 3){
        setFieldValue("schema_fields_details",existingValues, false)
      } else{
        setFieldValue("schema_fields_details",InitialValues, false)
      }
    } else if (status === 'rejected_file_type') {
      toast.error("ERROR: Unsupported File Format!" + "\n" + "Supported file formats are .xls, .xlsx, .xlsb, .xlsm, .ods, .ots, .uos");
    }
  }

  const handleExcelDownload = () => {
    axios.defaults.headers["Authorization"] = `Bearer ${localStorage.getItem("authToken")}`;
    axios.defaults.headers["Content-Type"] = `application/vnd.ms-excel`;
    axios.defaults.headers["Accept"] = `application/vnd.ms-excel`;
    axios({
      url: `/api/v1/download_sample_schema_details`,
      method: 'GET',
      responseType: 'blob'
    })
      .then((res) => {
        const url_to_download = window.URL.createObjectURL(new Blob([res.data]));
        const a_tag = document.createElement('a');
        a_tag.href = url_to_download;
        a_tag.setAttribute('download', `sample_schema_fields.xls`);
        document.body.appendChild(a_tag);
        a_tag.click();
      });
    axios.defaults.headers["Content-Type"] = `application/json`;
    axios.defaults.headers["Accept"] = `application/json`;
  }

  return(
    <FieldArray name="schema_fields_details">
    {({ push, remove, form }) => {
      const { values } = form;
      const { schema_fields_details } = values;

      return (
        <Row className='my-5'>
          <Table>
            <thead>
              <tr>
                <th>Field Name</th>
                <th>Data Type</th>
                <th>isMandatory</th>
                <th>Default Value</th>
                <th>Sample Value</th>
                <th>Comments</th>
                <th>Options</th>
              </tr>
            </thead>
            <tbody>
              {schema_fields_details?.map((row, index) => (
                <tr key={index} className='my-5'>
                  <td>
                    <Field
                      name={`schema_fields_details[${index}].field_name`}
                      className="form-control"
                      type="text"
                      disabled={index <= 2 || dataDisable}
                      onChange={(e) => {
                        let normalized_value = e.target.value.replace(/[^a-z0-9A-Z]+/g, ' ').replace(/\s+/g, '_').toLowerCase();
                        setFieldValue(`schema_fields_details[${index}].field_name`, normalized_value);
                      }}
                    />
                    <ErrorMessage name={`schema_fields_details[${index}].field_name`} component="p" className='m-0 error-msg mt-1 mb-2'/>
                  </td>
                  <td>
                    <Field as="select" name={`schema_fields_details[${index}].data_type`} className="form-control" disabled={index <= 2 || dataDisable}>
                      <option value="">Select datatype</option>
                      <option value="string">string</option>
                      <option value="date">date</option>
                      <option value="datetime">datetime</option>
                      <option value="boolean">boolean</option>
                      <option value="float">float</option>
                      <option value="integer">integer</option>
                    </Field>
                    <ErrorMessage name={`schema_fields_details[${index}].data_type`} component="p" className='m-0 error-msg mt-1 mb-2'/>
                  </td>
                  <td>
                    <Field as="select" name={`schema_fields_details[${index}].is_mandatory`} className="form-control" disabled={index <= 2 || dataDisable}>
                      <option value="">Select one</option>
                      <option value="yes">yes</option>
                      <option value="no">no</option>
                    </Field>
                    <ErrorMessage name={`schema_fields_details[${index}].is_mandatory`} component="p" className='m-0 error-msg mt-1 mb-2'/>
                  </td>
                  <td>
                    <Field name={`schema_fields_details[${index}].default_value`} className="form-control" type="text" disabled={index <= 2 || dataDisable} />
                  </td>
                  <td>
                    <Field name={`schema_fields_details[${index}].sample_value`} className="form-control" type="text" disabled={index <= 1 || dataDisable} />
                  </td>
                  <td>
                    <Field name={`schema_fields_details[${index}].comments`} className="form-control" type="text" disabled={index === 1 || dataDisable} />
                  </td>
                  <td>
                    {(index > 2 && !dataDisable ) && (
                      <button
                        type="button"
                        className='btn btn-outline-danger'
                        onClick={() => {
                          if(index == 3 && schema_fields_details.length == 4){
                            toast.error("Minimum one schema field is required..!")
                          } else {
                            remove(index)
                          }
                        }}
                      >
                        Delete
                      </button>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          {
            !dataDisable && 
            <>
              <button className='btn btn-outline-primary my-3' type="button"
                onClick={() => {
                  push({ field_name: "", data_type: "", is_mandatory: "no", default_value: "", sample_value: "", comments: "" });
                }}
                style={{width:"100%"}}
                >
                + Add more fields
              </button>
              <div className='my-4 d-flex justify-content-center fw-bold'>
              OR
              </div>
              <Col lg={3}>
                <h6 className='fw-bold d-flex justify-content-end'>
                  Add Schema details via a file: </h6>
              </Col>
              <Col lg={5}>
                <div className="dz-message needsclick">
                  <Dropzone
                    getUploadParams={getUploadParams} maxFiles={1}
                    onChangeStatus={(uploadData, status) => handleChangeStatus(uploadData, status, { push, remove, schema_fields_details})} multiple={false}
                    canCancel={true}
                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel,application/vnd.ms-excel.sheet.binary.macroEnabled.12,application/vnd.oasis.opendocument.spreadsheet,application/vnd.ms-excel.sheet.macroEnabled.12,application/vnd.oasis.opendocument.spreadsheet-template"
                    inputContent={(files, extra) => (extra.reject ? 'Please enter only supported files' : 'Upload / Drop an Excel File')}
                    styles={{
                      dropzone: { height: 40 },
                      dropzoneActive: { borderColor: 'green' },
                    }}
                  />
                </div>
                <p className='text-muted m-0 mt-2 text-sm'>
                  Support file formats are .xls, .xlsx, .xlsb, .xlsm, .ods, .ots, .uos
                </p>
              </Col>
            </>
          }
        <p className='my-5 d-flex align-center'>
          <button type='button' onClick={handleExcelDownload}
            className='btn text-primary'> Click here for a sample Excel file. 
          </button>
          Please do not change column headers
      </p>
      </Row>
      );
    }}
    </FieldArray>
  )
}
