import React, {useState, useEffect, useContext} from 'react'
import { useParams, useHistory } from "react-router-dom";
import Breadcrumb from '../../global/Breadcrumb';
import { Card, CardBody, CardHeader, Container, Row, Col, Button, Modal, ModalBody, Nav, 
  NavItem, NavLink, ModalFooter, ModalHeader, TabContent, TabPane, Input, CardFooter } from 'reactstrap'
import axios from 'axios'
import { toast } from 'react-toastify';
import StepZilla from "react-stepzilla";
import AddressDetails from './address-steps/AddressDetails';
import BusinessDetails from './address-steps/BusinessDetails';

// custom hooks
import useOrgIdentifer from '@hooks/org_identifier';

export default function EditOrganization({myOrganization, take_my_org_details}) {

  const history = useHistory()

  const org_identifier = useOrgIdentifer();

  const [globalData, setGlobalData] = useState({})
  const [data, setData] = useState({})
  const [toggle, setToggle] = useState(false)

  useEffect(() => {
    take_my_org_details(org_identifier);
  }, [org_identifier]);

  useEffect(() => {
    axios.get(`/api/v1/organizations/${org_identifier}?page_type=client&controller_name=organizations`).then(res => {
      setGlobalData(res.data.organization)
    }).catch(err => {
      // Refactor later
      if (err?.response?.data?.page_auth_failed) {
        history.push("/")
        toast.error(err?.response?.data?.message)
      }
      else if (!err?.response?.data?.org_auth_failed){
        toast.error(err?.response?.data?.message)
      }
    })
  }, [toggle])

  useEffect(() => {
    setData(globalData)
  }, [globalData])

  const steps =
    [
      { name: 'Address Details', 
        component: <AddressDetails data={data} setData={setData} myOrganization={myOrganization} /> },
      { name: 'Business Details', component: <BusinessDetails data={data} setData={setData}
      organization_name={org_identifier} myOrganization={myOrganization} /> },
    ]

  return (
    <Container fluid>
      <Breadcrumb parent="Organization Details" title="Edit"
        childrenObj={[{name: 'Organization', url: `/organizations/${org_identifier}`}]} />

      <Card className='p-4'>
        <Row>
          <Col lg={6} className='d-flex'>
            <h6 className='fw-bold my-0 me-2'>
              Organization Name:
            </h6>
            <h6 className='my-0'>
              {globalData.billing_name}
            </h6>
          </Col>
          <Col lg={6} className='d-flex'>
            <h6 className='fw-bold my-0 me-2'>
              Country:
            </h6>
            <h6 className='my-0'>
              {globalData?.address?.country_name}
            </h6>
          </Col>
        </Row>
      </Card>

      <Card>
        <CardHeader>
          <h6 className='fw-bold my-0'>Edit Organization Details: </h6>
        </CardHeader>
        <CardBody>
          <StepZilla steps={steps} showSteps={true} showNavigation={true} 
            stepsNavigation={true} prevBtnOnLastStep={true} dontValidate={false} />
        </CardBody>
      </Card>
    </Container>
  )
}
