import React from 'react';
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { Card, CardHeader, CardBody, Row, Col, Table } from 'reactstrap';
import { toast } from 'react-toastify';
import StepZilla from 'react-stepzilla';
import DataTable from 'react-data-table-component';
import Loader from "react-loader-spinner";
import axios from 'axios';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

import Breadcrumbs from '../../Common/BreadCrumb/BreadCrumbs.component';
import SchemaDetails from './Formik/SchemaDetails.component';
import SiteDetails from './Formik/SiteDetails.component';

export default function FeasibilityCheckSteps() {
  const { feasid } = useParams();
  const [data, setData] = useState({})
  const [displayData, setDisplayData] = useState({})
  const [dataToggle, setDataToggle] = useState(true)
  const [globalTableData, setGlobalTableData] = useState({})
  const [dataToggle2, setDataToggle2] = useState(false)
  const [v2Data, setV2Data] = useState({})
  const [stepNumber, setStepNumber] = useState(0)

  const formikRef = React.useRef();

  useEffect(() => {
    axios.get(`/api/v1/admins/feasibility_checks/${feasid}`).then(res => {
      setDisplayData(res.data.feasibility_report)
    }).catch(err => {
      toast.error(err.response.data.message)
    })
  }, [dataToggle, dataToggle2])

  useEffect(() => {
    axios.get(`/api/v1/admins/feasibility_checks/${feasid}/show_form`)
      .then(res => {
        let fetchedData = res.data.feasibility_report;
        
        let updatedAdditionalDetails = {};
        Object.keys(fetchedData?.additional_details || {}).forEach(key => {
          updatedAdditionalDetails[key] = parseInt(fetchedData.additional_details[key]);
        });
  
        setData({
          ...fetchedData,
          additional_details: updatedAdditionalDetails
        });
      })
      .catch(err => {
        toast.error(err.response.data.message);
      });
  }, [dataToggle, dataToggle2]);
  

  const steps = [
    {
      name: 'Schema Details',
      component: <SchemaDetails
        data={data}
        v2Data={v2Data}
        setV2Data={setV2Data}
        dataDisable={false}
        setDataToggle={setDataToggle}
        globalTableData={globalTableData}
        dataToggle={dataToggle}
        setGlobalTableData={setGlobalTableData}
        formikRef={formikRef} />
    },
    {
      name: 'Site Details', component: <SiteDetails
        data={data}
        setDataToggle={setDataToggle}
        dataToggle={dataToggle}
        setDataToggle2={setDataToggle2}
        v2Data={v2Data}
        setV2Data={setV2Data}
        dataDisable={false}
        globalTableData={globalTableData}
        setGlobalTableData={setGlobalTableData}
        formikRef={formikRef}
         />
    },
  ];


  const siteColumns = [
    { name: 'Sample URL', selector: 'sample_url', sortable: true, center: true, },
    { name: 'Site Status', selector: 'site_status', sortable: true, center: true, },
    { name: 'Site Setup Fee', selector: 'site_setup_fee', sortable: true, center: true, },
    { name: 'Monthly Maintenance Fee', selector: 'monthly_maintenance_fee', sortable: true, center: true, },
    { name: 'Volume Charges', selector: 'volume_charges', sortable: true, center: true, },
    { name: 'No.of Free Records', selector: 'number_of_free_records', sortable: true, center: true, },
  ];

  Yup.addMethod(Yup.object, 'unique', function (propertyName, message) {
    return this.test('unique', message, function (value) {
      if (!value || !value[propertyName]) {
        return true;
      }

      const { path } = this;
      const options = [...this.parent];
      const currentIndex = options.indexOf(value);

      const subOptions = options.slice(0, currentIndex);

      if (subOptions.some((option) => option[propertyName] === value[propertyName])) {
        throw this.createError({
          path: `${path}.${propertyName}`,
          message,
        });
      }

      return true;
    });
  });

  const numberRegex =  /^\d+(\.\d+)?$/

  const validationSchema = Yup.object().shape({
    schema_fields_details: Yup.array().of(
      Yup.object({
        field_name: Yup.string().required('This field is required'),
        data_type: Yup.string().min(1, "This field is required").required('This field is required'),
        is_mandatory: Yup.string().min(1, "This field is required").required('This field is required')
      }).unique("field_name", "Field name should be unique")
    ),
    site_details: Yup.array().of(
      Yup.object().shape({
        site_setup_fee: Yup.string()
          .matches(numberRegex, 'Site setup fee must be a valid number')
          .required('Site setup fee is required'),
        monthly_maintenance_fee: Yup.string()
          .matches(numberRegex, 'Monthly maintenance fee must be a valid number')
          .required('Monthly maintenance fee is required'),
        volume_charges: Yup.string()
          .matches(numberRegex, 'Volume charges must be a valid number')
          .required('Volume charges are required'),
        number_of_free_records: Yup.string() 
          .matches(numberRegex, 'Number of free records must be a valid number')
          .required('Number of free records is required'),
        isEnabled: Yup.boolean(),
      })
    ),
    overall_feasibility: Yup.string().required("Please select feasibility"),
    comments: Yup.string(),
    additional_information: Yup.string(),
    send_feasibility_report:Yup.boolean(),
    skip_agreements: Yup.boolean(),
    skip_invoice: Yup.boolean(),
    use_custom_contracts: Yup.boolean(),
    image_downloads_fee: Yup.string().matches(numberRegex, 'This Field must be a valid number'),
    two_days_of_delivery_fee: Yup.string().matches(numberRegex, 'This Field must be a valid number'),
    hosted_indexing_fee: Yup.string().matches(numberRegex, 'This Field must be a valid number'),
    merge_files_fee: Yup.string().matches(numberRegex, 'This Field must be a valid number'),
  })

  const schema_fields_details_validations = Yup.object().shape({
    schema_fields_details: Yup.array().of(
      Yup.object({
        field_name: Yup.string().required('This field is required'),
        data_type: Yup.string().min(1, "This field is required").required('This field is required'),
        is_mandatory: Yup.string().min(1, "This field is required").required('This field is required')
      }).unique("field_name", "Field name should be unique")
    )
  })


  return (
    <>
      <div className='pt-2'>
        <Breadcrumbs parent={"Feasibility Check"} title={feasid}
          childrenObj={[{ name: 'Feasibility Checks', url: '/admins/feasibility_checks' }]} />

        {"id" in data ?
          (data.feasibility_check_status === "under_review" ?
            (<div>
              <Formik
                initialValues={{
                  schema_fields_details: data?.schema_fields_details || [],
                  additional_information: data?.additional_information || "",
                  fields_to_extract : data?.fields_to_extract || "",
                  site_details: data?.websites?.map(item => ({
                    site_id: item?.id,
                    site_url: item?.sample_url,
                    site_setup_fee: 0,
                    monthly_maintenance_fee: 0,
                    volume_charges: 0,
                    number_of_free_records: 0,
                    isEnabled: false
                  })) || [],
                  overall_feasibility: '',
                  comments:'',
                  any_additional_cost:'',
                  send_feasibility_report:true,
                  skip_agreements: false,
                  skip_invoice: false,
                  use_custom_contracts: false,
                  merge_files_fee: String(data?.additional_details_fee?.merge_files || 0),
                  hosted_indexing_fee: String(data?.additional_details_fee?.hosted_indexing || 0),
                  two_days_of_delivery_fee: String(data?.additional_details_fee?.two_days_of_delivery || 0),
                  image_downloads_fee: String(data?.additional_details_fee?.image_downloads || 0)
                }}                
                validationSchema={stepNumber == 0 ? schema_fields_details_validations : validationSchema}
                innerRef={formikRef}
                enableReinitialize={true}
              >
              {()=>{
                return(
                  <Form>
                    <Card className='p-4'>
                      <StepZilla steps={steps} showSteps={true}
                        showNavigation={true} stepsNavigation={true}
                        prevBtnOnLastStep={true} dontValidate={false}
                        onStepChange={(step)=>setStepNumber(step)} 
                      />
                    </Card>
                  </Form>
                )
              }}
              </Formik>
            </div>)
            : (
              <Card>
                <CardHeader className='p-4'>
                  <h6 className='fw-bold m-0'>Site Requirements</h6>
                </CardHeader>
                <CardBody>
                  <h4 className='fw-lighter'>
                    Sites belonging to this Feasibility Check
                  </h4>
                  <div></div>
                  <div className='my-5'>
                    <h6 className='fw-bold'>Site Details</h6>
                    <DataTable columns={siteColumns} noHeader responsive
                      data={displayData?.sites_of_feasibility_report} />
                  </div>

                  {displayData?.sitegroup_details?.unpaid_feasibility_reports.length > 0 &&
                    <div className='my-5'>
                      <h6 className='fw-bold'>Feasibility Report for this Feasibility Check</h6>
                      <Table responsive bordered>
                        <thead>
                          <tr>
                            <th className='fw-bold'>
                              # Sites
                            </th>
                            <th className='fw-bold'>
                              Overall Feasibility
                            </th>
                            <th className='fw-bold'>
                              Created By
                            </th>
                            <th className='fw-bold'>
                              # Viewed
                            </th>
                            <th className='fw-bold'>
                              Accepted By
                            </th>
                            <th className='fw-bold'>
                              Current Status
                            </th>
                            <th className='fw-bold'>
                              Action
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {displayData?.sitegroup_details?.unpaid_feasibility_reports.map((each, index) => (
                            <tr key={index}>
                              <td>{each?.sites_count}</td>
                              <td>{each?.overall_feasibility}</td>
                              <td>{each?.created_by}</td>
                              <td>{each?.viewed_count}</td>
                              <td>{each?.accepted_by}</td>
                              <td>{each?.client_acceptance_status}</td>
                              <td>
                                <a href={`/organizations/${data.organization_identifier}/feasibility_reports/${feasid}`}
                                  className='btn btn-xs btn-success mx-2 text-white'> View </a>
                                {each?.editable && <a href={`/admins/feasibility_checks/${feasid}/edit`}
                                  className='btn btn-xs btn-primary mx-2'> Edit </a>}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </div>}

                  <div className='my-4'>
                    <h4 className='fw-lighter mb-4'>
                      Sitegroup Full Details
                    </h4>
                    <Row className='my-2'>
                      <Col lg={4}>
                        <h6 className='fw-bold'>Sitegroup Name</h6>
                        <h6>
                          {displayData?.sitegroup_details?.sitegroup_name}
                        </h6>
                      </Col>
                      <Col lg={4}>
                        <h6 className='fw-bold'>Additional Information</h6>
                        <h6>
                          {displayData?.sitegroup_details?.additional_information}
                        </h6>
                      </Col>
                      <Col lg={4}>
                        <h6 className='fw-bold'>Sitegroup Status</h6>
                        <h6 className='text-capitalize'>
                          {displayData?.sitegroup_details?.sitegroup_status}
                        </h6>
                      </Col>
                    </Row>

                    {displayData?.sitegroup_details?.schema_fields_details ? <>
                      <h6 className='fw-bold mt-5 mb-3'>
                        Schema Details
                      </h6>
                      <Table responsive bordered>
                        <thead>
                          <tr>
                            <th className='fw-bold'>Field Name</th>
                            <th className='fw-bold'>Data Type</th>
                            <th className='fw-bold'>Is Mandatory</th>
                            <th className='fw-bold'>Default Value</th>
                            <th className='fw-bold'>Sample Value</th>
                            <th className='fw-bold'>Comments</th>
                          </tr>
                        </thead>
                        <tbody>
                          {displayData?.sitegroup_details?.schema_fields_details?.map(each => {
                            return (<tr key={each.id}>
                              <td>
                                {each?.field_name}
                              </td>
                              <td>
                                {each?.data_type}
                              </td>
                              <td>
                                {each?.is_mandatory}
                              </td>
                              <td>
                                {each?.default_value}
                              </td>
                              <td>
                                {each?.sample_value}
                              </td>
                              <td>
                                {each?.comments}
                              </td>
                            </tr>)
                          })}
                        </tbody>
                      </Table>
                    </> :
                      <>
                        <h6 className='fw-bold mt-5 mb-3'>
                          Fields to extract
                        </h6>
                        <textarea rows='3' disabled className='form-control my-3'
                          value={displayData?.sitegroup_details?.fields_to_extract} />
                      </>}

                    <div className='my-4'>
                      <h6 className='fw-bold'>Site Details</h6>
                      <DataTable columns={siteColumns} noHeader responsive
                        data={displayData?.sitegroup_details?.sites_of_sitegroup} />
                    </div>
                  </div>

                  <div className='my-4'>
                    <Row className='my-2'>
                      <Col lg={6} className='p-2'>
                        <h6 className='fw-bold'>Delivery Details</h6>
                        <Table>
                          <tbody>
                            <tr>
                              <td>
                                Crawl Type
                              </td>
                              <td className='fw-bold text-capitalize'>
                                {displayData?.sitegroup_details?.crawl_type}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                Output Format
                              </td>
                              <td className='fw-bold text-capitalize'>
                                {displayData?.sitegroup_details?.delivery_format.toUpperCase().replace(/_/g, '-')}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                Frequency
                              </td>
                              <td className='fw-bold text-capitalize'>
                                {displayData.sitegroup_details?.frequency === "other_frequency" ?
                                  `${displayData.sitegroup_details?.frequency} (${displayData.sitegroup_details?.delivery_method_details})`
                                  : displayData.sitegroup_details?.frequency}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                Delivery Method
                              </td>
                              <td className='fw-bold text-capitalize'>
                                {displayData?.sitegroup_details?.delivery_method}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                Creator
                              </td>
                              <td className='fw-bold text-capitalize'>
                                {displayData?.sitegroup_details?.creator}
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </Col>
                      <Col lg={6} className='p-2'>
                        <h6 className='fw-bold'>Additional Details</h6>
                        <Table>
                          <tbody>
                            {displayData?.sitegroup_details?.additional_details?.map((each, index) => (
                              <tr key={index}>
                                <td>
                                  {each?.title}
                                </td>
                                <td>
                                  {each?.status}
                                </td>
                                <td>
                                  {each?.fee}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      </Col>
                    </Row>
                  </div>

                  <div className='my-4'>
                    <Row>
                      <Col lg={3}>
                        <p className='fw-bold'>
                          Site Setup Cost
                        </p>
                        <input className='form-control' disabled onChange={(e) => console.log()}
                          value={displayData?.sitegroup_details?.site_setup_fee} />
                      </Col>
                      <Col lg={3}>
                        <p className='fw-bold'>
                          Maintenance Cost
                        </p>
                        <input className='form-control' disabled onChange={(e) => console.log()}
                          value={displayData?.sitegroup_details?.monthly_maintenance_fee} />
                      </Col>
                      <Col lg={3}>
                        <p className='fw-bold'>
                          Volume Costs
                        </p>
                        <input className='form-control' disabled onChange={(e) => console.log()}
                          value={displayData?.sitegroup_details?.volume_charges} />
                      </Col>
                      <Col lg={3}>
                        <p className='fw-bold'>
                          Any Additional Cost
                        </p>
                        <input className='form-control' disabled onChange={(e) => console.log()}
                          value={displayData?.sitegroup_details?.any_additional_cost} />
                      </Col>
                    </Row>
                  </div>

                  {displayData?.sitegroup_details?.unpaid_feasibility_reports.length > 0 &&
                    <div className='my-5'>
                      <h6 className='fw-bold'>Unpaid Feasibility Reports</h6>
                      <Table responsive bordered>
                        <thead>
                          <tr>
                            <th className='fw-bold'>
                              # Sites
                            </th>
                            <th className='fw-bold'>
                              Overall Feasibility
                            </th>
                            <th className='fw-bold'>
                              Created By
                            </th>
                            <th className='fw-bold'>
                              # Viewed
                            </th>
                            <th className='fw-bold'>
                              Accepted By
                            </th>
                            <th className='fw-bold'>
                              Current Status
                            </th>
                            <th className='fw-bold'>
                              Action
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {displayData?.sitegroup_details?.unpaid_feasibility_reports.map((each, index) => (
                            <tr key={index}>
                              <td>{each?.sites_count}</td>
                              <td>{each?.overall_feasibility}</td>
                              <td>{each?.created_by}</td>
                              <td>{each?.viewed_count}</td>
                              <td>{each?.accepted_by}</td>
                              <td>{each?.client_acceptance_status}</td>
                              <td>
                                <a href={`#`} className='btn btn-xs btn-success mx-2 text-white'>View</a>
                                {each?.editable && <a href={`/admins/feasibility_checks/${feasid}/edit`} className='btn btn-xs btn-primary mx-2'>Edit </a>}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </div>}
                </CardBody>
              </Card>
            ))
          : <Loader type="Puff" color="#00BFFF" height={60} width={60} className='pc-loader' />}
      </div>
    </>
  )
}
