import React, { useContext, useState, useEffect } from 'react';
import { Row, Col,Input, Table, Container } from 'reactstrap'
import { useParams, useHistory } from 'react-router-dom';
import Dropzone from 'react-dropzone-uploader';
import AsyncSelect from 'react-select/async';
import { toast } from 'react-toastify'

import { Formik, Form, Field, ErrorMessage, FieldArray,  setNestedObjectValues } from 'formik';
import axios from 'axios'
import XLSX from 'xlsx'
import * as Yup from 'yup';

import { getItem } from '../../../localStorageMethods';
import Breadcrumbs from '../../Common/BreadCrumb/BreadCrumbs.component';

// custom hooks
import useOrgIdentifer from '@hooks/org_identifier';

export default function AddNewSitesToSiteGroupFormik({current_user, current_organization, take_my_org_details}) {

  let accessEnv = ['staging']
  const history = useHistory()
  const environment = getItem('environment')
  const [sitegroupNamesDropdownOptions, setSitegroupNamesDropdownOptions] = useState([])

  const org_identifier = useOrgIdentifer();

  useEffect(() => {
    take_my_org_details(org_identifier);
  }, [org_identifier]);

  useEffect(() => {
    if (org_identifier == "promptcloud"){
      history.push('/');
      toast.error("You are not authorized this page")
    } 
  }, [org_identifier])

  useEffect(() => {
    axios.get(`/api/v1/organizations/${org_identifier}/sitegroups?from=new_sites_form`).then(res => {
        setSitegroupNamesDropdownOptions(convertToArrayOfObjects(res?.data?.sitegroups));
    }).catch(err => {
        // Refactor later
        if (err?.response?.data?.page_auth_failed) {
          history.push("/")
          toast.error(err?.response?.data?.message)
        }
        else if (!err?.response?.data?.org_auth_failed){
          toast.error(err?.response?.data?.message)
        }
    })
  }, [org_identifier])

 
  // Helper function to convert array of arrays into
  // Array of objects
  function convertToArrayOfObjects(inputArray) {
    return inputArray.map(([value, label]) => ({ value, label }));
  }

  // Helper function to filter the values and return the object
  // based on user input
  const filterValues = (inputValue) => {
      return sitegroupNamesDropdownOptions.filter((i) =>
      i.label.toLowerCase().includes(inputValue.toLowerCase())
      );
  };

  // AsyncMethod to traverse the array and return resut that matches 
  // the input query entered by the user 
  const loadOptions = (inputValue, callback) => {
      setTimeout(() => {
      callback(filterValues(inputValue));
      }, 650);
  };

  const getUploadParams = ({ meta }) => { return { url: 'https://httpbin.org/post' } }

  Yup.addMethod(Yup.object, 'unique', function (propertyName, message) {
    return this.test('unique', message, function (value) {
      if (!value || !value[propertyName]) {
        return true;
      }

      const { path } = this;
      const options = [...this.parent];
      const currentIndex = options.indexOf(value);

      const subOptions = options.slice(0, currentIndex);

      if (subOptions.some((option) => option[propertyName] === value[propertyName])) {
        throw this.createError({
          path: `${path}.${propertyName}`,
          message,
        });
      }

      return true;
    });
  });

  const handleChangeStatus = (uploadData, status, form) => {
    const { setFieldValue, validateForm, setTouched, values, setFieldTouched, setFieldError } = form
    const { meta, file } = uploadData
    if (status === "done") {
      var fileReader = new FileReader();
      fileReader.onload = function (event) {
        var workbook = XLSX.read(event.target.result, {
          type: "binary"
        });
        workbook.SheetNames.forEach(sheet => {
          let rowObject = XLSX.utils.sheet_to_row_object_array(
            workbook.Sheets[sheet]
          );
          let headerCondition;
          if (accessEnv.includes(environment)) {
            headerCondition = ("page_url" in rowObject[0] && "rss_urls_crawl_limit" in rowObject[0])
          }
          else {
            headerCondition = ("page_url" in rowObject[0])
          }
          if (rowObject.length === 0) {
            toast.error("Uploaded file is empty")
          }
          else if (headerCondition) {
            let newRows = values
            .filter(item => item?.url?.length > 0 || String(item?.rss_urls_crawl_limit).length > 0)
            .map(item => ({
              url: item.url || "",
              rss_urls_crawl_limit: item.rss_urls_crawl_limit || ""
            }));
            rowObject.forEach(item => {
              newRows.push({
                url: item?.page_url || "",
                rss_urls_crawl_limit: accessEnv.includes(environment) ? item?.rss_urls_crawl_limit || "" : ""
              });
            });
            setFieldValue("site_details",newRows,true).then(()=>{
              validateForm({ site_details: newRows})
              .then(errors => {
                setTouched(setNestedObjectValues(errors, true))
                if(!checkForValues(newRows)){
                  toast.error("please enter atleast one feasible url")
                } else if (Object.keys(errors).length === 0) {
                  return
                } else {
                  setTouched(setNestedObjectValues(errors, true))
                  toast.error("The form is invalid so please check it!")
                }
              })
              .catch(error => console.log(error));
            })
          }
          else {
            toast.error("Please keep column headers as mentioned in sample excel file")
          }
        });
      };
      fileReader.readAsBinaryString(file);
    }
    else if (status === "removed") {
      let empty_rows = [
        {url:"", rss_urls_crawl_limit:""},
        {url:"", rss_urls_crawl_limit:""},
        {url:"", rss_urls_crawl_limit:""},
        {url:"", rss_urls_crawl_limit:""},
      ]
      setFieldValue("site_details",empty_rows)
      setFieldTouched("site_details", false)
      setFieldError("site_details",[])
    }
    else if (status === 'rejected_file_type') {
      toast.error("ERROR: Unsupported File Format!" + "\n" + "Supported file formats are .xls, .xlsx, .xlsb, .xlsm, .ods, .ots, .uos");
    }
  }

  const handleExcelDownload = () => {
    axios.defaults.headers["Authorization"] = `Bearer ${localStorage.getItem("authToken")}`;
    axios.defaults.headers["Content-Type"] = `application/vnd.ms-excel`;
    axios.defaults.headers["Accept"] = `application/vnd.ms-excel`;
    axios({
        url: `/api/v1/download_sample_site_details`,
        method: 'GET',
        responseType: 'blob'
    })
        .then((res) => {
            const url_to_download = window.URL.createObjectURL(new Blob([res.data]));
            const a_tag = document.createElement('a');
            a_tag.href = url_to_download;
            a_tag.setAttribute('download', `sample_site_urls.xls`);
            document.body.appendChild(a_tag);
            a_tag.click();
        });
    axios.defaults.headers["Content-Type"] = `application/json`;
    axios.defaults.headers["Accept"] = `application/json`;
  }

  const handleSubmit = (files, allFiles) => {
    allFiles.forEach(f => f.remove())
    toast.success("Dropzone successfully submitted !");
  }

  const checkForValues = (details) => {
    let feasible = false
    var url = new RegExp(/^((www\.)|(http(s*)))/i);
    var rss_urls = accessEnv.includes(environment) ? new RegExp(/^\d+$/) : new RegExp(/.*/)
    details?.map(obj => {
      if(accessEnv.includes(environment)){
        if(url.test(obj?.url) && rss_urls.test(obj?.rss_urls_crawl_limit)) feasible = true
      } else {
        if(url.test(obj?.url)) feasible = true
      } 
    })
    return feasible
  }

  const validationSchema = Yup.object({
    sitegroup_id: Yup.string().strip(),
    site_details : Yup.array().of(
      Yup.object().shape({
        url: Yup.string().when("rss_urls_crawl_limit", {
          is: (rss_urls_crawl_limit) => rss_urls_crawl_limit?.length > 0 && accessEnv.includes(environment),
          then: () => Yup.string()
            .required("This field is required if rss urls crawl limit has value!")
            .min(1, "This field is required if rss urls crawl limit has value!")
            .matches(/^((www\.)|(http(s*):\/\/))/i, "Please enter a valid URL"),
          otherwise: () => Yup.string().matches(/^((www\.)|(http(s*):\/\/))/i, { message: "Please enter a valid URL", excludeEmptyString: true }),
        }),
        rss_urls_crawl_limit: Yup.string().when("url", {
          is: (url) => url?.length > 0 && accessEnv.includes(environment),
          then: () => Yup.string()
            .required('This field is required if url has value!')
            .min(1, 'This field is required if url has value!')
            .matches(/^\d+$/, "This field should be integer!"),
          otherwise: () => Yup.string(),
        })
      }, ['rss_urls_crawl_limit', 'url']).unique("url", "Entered URL is already present")
    )
  })

  const validationSchemaForUrls = Yup.array().of(
    Yup.lazy((values) => {

      const isUrlPresent = values.url && values.url.trim().length > 0;

      return Yup.object().shape({
        url: isUrlPresent
          ? Yup.string()
              .test('is-feasible', 'This URL is not feasible', async function (value) {
                const { path } = this;

                if (/^((www\.)|(http(s*):\/\/))/i.test(value?.trim())) {
                  const url = value?.trim();

                  try {
                    const response = await axios.post('/api/v1/sites/check_feasibility', {
                      sites: { sample_page_url: url },
                    });

                    if (response.data.status !== 'success') {
                      return this.createError({
                        path: `${path}`,
                        message: 'This URL is not feasible',
                      });
                    }
                  } catch (error) {
                    console.error("API call failed:", error);
                    return this.createError({
                      path: `${path}`,
                      message: 'This URL is not feasible',
                    });
                  }
                }
                return true;
              })
          : Yup.string(),
        rss_urls_crawl_limit: Yup.string(),
      });
    })
  )

  const addSitetoSitegroup = (values, form) => {
    const { setTouched, validateForm, setErrors, setSubmitting, setFieldValue} = form
    validateForm().then( async errors => {
      setTouched(setNestedObjectValues(errors, true));
      if(errors?.site_details?.length > 0){
        toast.error("Please fill in the required fields")
      }else if(!checkForValues(values?.site_details)) {
        toast.error("Please enter at least one feasible URL.");
      } else if (typeof values?.sitegroup_id === 'string' || typeof values?.sitegroup_id?.value != 'number') {
        toast.error(`Sitegroup is not present or does not belong to organization ${org_identifier}.`);
      } else {
        try {
          await validationSchemaForUrls.validate(values?.site_details, { abortEarly: false });
          setSubmitting(false)
          let new_urls = []
          const rss_urls = accessEnv.includes(environment) ? new RegExp(/^\d+$/) : new RegExp(/.*/)
          new_urls = values?.site_details.filter((site) => site?.url?.length > 0 && rss_urls.test(site?.rss_urls_crawl_limit))

          axios.post(`/api/v1/organizations/${org_identifier}/sites`, {
            submit_requirements: {
              sitegroup_id: values?.sitegroup_id?.value,
              site_add_from: "client_side",
              site_details: new_urls
            }
          }).then(res => {
            toast.success(res.data.message);
            history.push(`/organizations/${org_identifier}/sites`);
          }).catch(err => {
            toast.error(err?.response?.data?.message);
          })

        } catch (validationErrors) {
          const errorIndices = validationErrors.inner.map((error) => {
            const match = error.path.match(/\[(\d+)\]/);
            return match ? parseInt(match[1], 10) : -1;
          }).filter((index) => index !== -1);
    
          let formattedErrors = Array(values.site_details.length).fill({ url: '', rss_urls_crawl_limit: '' });
          errorIndices.forEach((index) => {
            formattedErrors[index] = {
              url: 'This URL is not feasible',
              rss_urls_crawl_limit: formattedErrors[index].rss_urls_crawl_limit,
            }
          })
          setErrors({ site_details: formattedErrors });
          setTouched(setNestedObjectValues({ site_details: formattedErrors }, true), false);
          toast.error('The form is invalid. Please check it!');
          setSubmitting(false);
        }
      }
    }).catch(error => {
      console.log('error', error)
    })
  }


  return (
      <Container fluid>
        <Breadcrumbs parent={"Add Sites To Site Group"} title={"Add Sites To Site Group"} />
          <Formik 
            initialValues={{
              sitegroup_id:"",
              site_details:[
                { url:"",rss_urls_crawl_limit:""},
                { url:"",rss_urls_crawl_limit:""},
                { url:"",rss_urls_crawl_limit:""},
                { url:"",rss_urls_crawl_limit:""},
              ],
            }}
            validateOnChange={false}
            validateOnBlur={true}
            validationSchema={validationSchema}
          >
            {({ isSubmitting, values, setFieldValue, validateForm , setTouched, errors, setErrors, setSubmitting, setFieldTouched, setFieldError})=>{
              return(
                <Form>
                    <Row className='card my-2 p-5'>
                      <Col sm={12} className='form-group d-flex justify-content-center align-items-center'>
                        <label className='form-label' style={{ whiteSpace: "nowrap" }}>
                            Select Sitegroup:
                        </label>
                        <Field
                            name="sitegroup_id"
                            component={AsyncSelect}
                            className='w-100 mx-2 select_sitegroup'
                            placeholder="Select a Site Group"
                            cacheOptions
                            defaultOptions={sitegroupNamesDropdownOptions}
                            onChange={(option) => setFieldValue("sitegroup_id", option)}
                            loadOptions={loadOptions}
                        />
                        <ErrorMessage name="sitegroup_id" component="p" className="m-0 error-msg mt-1 mb-2"  />
                      </Col>
                      <Col sm={12}>
                        <FieldArray name="site_details" validateOnChange={true}>
                          {({ push, remove, form }) => {
                            const { values } = form;
                            const { site_details } = values;

                            return(
                              <Row className='my-5 py-3'>
                                <Col span={12}>
                                  <Table hover bordered className='site-details-table'>
                                    <thead>
                                      <tr>
                                        <th>SI.No</th>
                                        <th>URL</th>
                                        {accessEnv.includes(environment) ? <th>Rss urls crawl limit</th> : ""}
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {
                                        site_details.map((row, index)=>{
                                            return(
                                              <tr>
                                                <td> {index + 1}</td>
                                                <td style={{ width: accessEnv.includes(environment) ? "70%" : "100%" }}>
                                                  <Field
                                                    name={`site_details[${index}].url`}
                                                    className="form-control"
                                                    type="text"
                                                    placeholder='http://www.ebay.com/sch/DSLR-Cameras/31388/bn_732/i.html'
                                                  />
                                                  <ErrorMessage name={`site_details[${index}].url`} component="p" className='m-0 error-msg mt-1 mb-2'/>
                                                </td>
                                                <td>
                                                  {
                                                    accessEnv.includes(environment) ?
                                                    <>
                                                      <Field
                                                      name={`site_details[${index}].rss_urls_crawl_limit`}
                                                      className="form-control"
                                                      type="text"
                                                      placeholder='It will be 30k by default'
                                                      />
                                                      <ErrorMessage name={`site_details[${index}].rss_urls_crawl_limit`} component="p" className='m-0 error-msg mt-1 mb-2'/>
                                                    </>
                                                    : ""
                                                  }
                                                </td>
                                              </tr>
                                            )
                                        })
                                      }
                                    </tbody>
                                  </Table>
                                  <button className='btn btn-outline-primary my-3 w-100' type='button'
                                    onClick={() => push({url:"", rss_urls_crawl_limit:""})}>
                                    + Add more fields
                                  </button>
                                </Col>
                                <div className='my-4 d-flex justify-content-center fw-bold'>
                                  OR
                                </div>
                                <Col span={12}>
                                  <Row> 
                                    <Col sm={3} className='d-flex justify-content-end'>
                                      <h6 className='fw-bold'>
                                        Add Sites via a file: </h6>
                                    </Col>
                                    <Col sm={5}>
                                      <Form onSubmit={handleSubmit}>
                                        <div className="dz-message needsclick">
                                          <Dropzone
                                            getUploadParams={getUploadParams} maxFiles={1}
                                            onChangeStatus={(uploadData, status) => handleChangeStatus(uploadData, status, { setFieldError, setFieldValue, setTouched, validateForm, setFieldTouched, values: values?.site_details})}
                                            multiple={false}
                                            canCancel={true}
                                            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel,application/vnd.ms-excel.sheet.binary.macroEnabled.12,application/vnd.oasis.opendocument.spreadsheet,application/vnd.ms-excel.sheet.macroEnabled.12,application/vnd.oasis.opendocument.spreadsheet-template"
                                            inputContent={(files, extra) => (extra.reject ? 'Please enter only supported files' : 'Upload / Drop an Excel File')}
                                            styles={{
                                              dropzone: { height: 40 },
                                              dropzoneActive: { borderColor: 'green' },
                                            }}
                                          />
                                        </div>
                                      </Form>
                                      <p className='text-muted m-0 mt-2 text-sm'>
                                        Support file formats are .xls, .xlsx, .xlsb, .xlsm, .ods, .ots, .uos
                                      </p>
                                    </Col>
                                    <p className='my-5 d-flex align-center'>
                                      <button type='button' onClick={handleExcelDownload}
                                        className='btn text-primary'> Click here for a sample Excel file. 
                                      </button>
                                      Please do not change column headers
                                    </p>
                                    <div>
                                        <button type="button" className='btn float-right btn-success' onClick={()=> addSitetoSitegroup(values, {validateForm, setTouched, setErrors, setSubmitting})}>
                                            Add sites
                                        </button>
                                    </div>
                                  </Row>
                                </Col>
                              </Row>
                            )
                          }}
                        </FieldArray>
                      </Col>
                    </Row>
                </Form>
              )
            }}
          </Formik>
      </Container>
  )
}
