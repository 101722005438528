import React, {
  forwardRef, useImperativeHandle,
  useState, useEffect
} from 'react';
import { Row, Col, Form, Input, Card, CardBody, Table } from 'reactstrap'
import Dropzone from 'react-dropzone-uploader';
import { toast } from 'react-toastify'
import XLSX from 'xlsx'
import axios from 'axios'
import Loader from 'react-loader-spinner';
import { setNestedObjectValues, useFormikContext, setTouched, Field } from 'formik';
import * as Yup from 'yup';

import CrawlForm from '../../../../shared_components/stepzilla/SchemaDetails/CrawlForm.component';

const SchemaDetails = forwardRef(({ data, setGlobalTableData,
  globalTableData, v2Data, setV2Data, dataDisable, formikRef }, ref) => {
  const [tableData, setTableData] = useState({})
  const [newRows, setNewRows] = useState(['row1'])
  const [localData, setLocalData] = useState({})
  const [version, setVersion] = useState(data.project_version)
  const [toggle, setToggle] = useState(false)

  const { values, validateForm, touched, setTouched, setFieldValue, validateField } = useFormikContext()

  useEffect(() => {
    let tempRows = {}
    let tempArr = []
    let filteredData = data.schema_fields_details || []
    filteredData.forEach((each, index) => {
      let keyy = index;
      keyy = "row" + keyy;
      tempArr.push(keyy)
      tempRows = {
        ...tempRows, [keyy]: each
      }
    })
    globalTableData.row1 ? setTableData(globalTableData) : setTableData({ ...tempRows })
    setNewRows(tempArr)
    setVersion(data.project_version)
    setLocalData({
      ...data,
      organization_name: v2Data.organization_name
        ? v2Data.organization_name : data.organization_name,
      additional_information: v2Data.additional_information
        ? v2Data.additional_information : data.additional_information,
      fields_to_extract: v2Data.fields_to_extract
        ? v2Data.fields_to_extract : data.fields_to_extract

    })
  }, [data, toggle])

  const schema = Yup.object().shape({
    schema_fields_details: Yup.array().of(
      Yup.object({
        field_name: Yup.string().required('This field is required'),
        data_type: Yup.string().min(1, "This field is required").required('This field is required'),
        is_mandatory: Yup.string().min(1, "This field is required").required('This field is required')
      }).unique("field_name", "Field name should be unique")
    ),
  })

  useImperativeHandle(ref, () => ({
    isValidated() {
      let validateValues = {
        schema_fields_details: values?.schema_fields_details,
      }
      if(dataDisable){
        return true
      } else {
        return new Promise((resolve, reject) => {
          formikRef.current.validateForm(validateValues)
             .then(errors => {
               if (Object.keys(errors).length === 0) {
                 setV2Data({
                   additional_information: localData.additional_information,
                   fields_to_extract: localData.fields_to_extract
                 })
                 setGlobalTableData(tableData)
                 resolve();
               } else {
                 reject(errors);
                 setTouched(setNestedObjectValues(errors, true))
                 toast.error("The form is invalid so please check it!")
               }
             })
             .catch(error => reject(error));
         })  
      } 
    }
  }))


  return (
    <div className='p-3'>
      {localData.sitegroup_id ? (<>
        <Card>
          <CardBody className='p-4'>
            <Row>
              <Col lg={4}>
                <h6 className='fw-bold'>
                  Sitegroup Name
                </h6>
                <p>
                  {localData.sitegroup_name &&
                    localData.sitegroup_name}
                </p>
              </Col>
              <Col lg={5}>
                <h6 className='fw-bold'>
                  Organization
                </h6>
                {localData.organization_name && <input
                  onChange={(e) => setLocalData({
                    ...localData,
                    organization_name: e.target.value
                  })}
                  value={"" || localData.organization_name}
                  placeholder='Enter organization name'
                  name="organization_name"
                  className='form-control' />}
              </Col>
              <Col lg={3}>
                <h6 className='fw-bold'>
                  Country
                </h6>
                <p>
                  {localData.country_name &&
                    localData.country_name}
                </p>
              </Col>
            </Row>
            <Row className='mt-4'>
              <Col lg={4}>
                <h6 className='fw-bold'>
                  Creator
                </h6>
                <p>
                  {localData.creator && localData.creator}
                </p>
              </Col>
              <Col lg={5}>
                <h6 className='fw-bold'>
                  Additional Information
                </h6>
                  <Field
                    as="textarea"
                    rows="2"
                    name="additional_information"
                    className="form-control"
                    value={values.additional_information}
                  />
              </Col>
              <Col lg={3}>
                <h6 className='fw-bold'>
                  Attachments
                </h6>
                {(localData.attachments &&
                  localData.attachments.length > 0) ?
                  localData.attachments.map((each, index) =>
                    <a href={each.url} key={index} download> {each.file_name} </a>
                  )
                  : <p> N/A </p>}
              </Col>
            </Row>
          </CardBody>
        </Card>

        {version == 'v2' ?
          (
            <div className='mt-4'>
              <h6>Fields to Extract:</h6>
              <Field
                as="textarea"
                rows="3"
                name="fields_to_extract"
                className="form-control"
                disabled={dataDisable}
                value={values.fields_to_extract}
              />
            </div>
          )
          : <CrawlForm existingValues={data?.schema_fields_details} dataDisable={dataDisable} />}
      </>)
        : <Loader type="Puff" color="#00BFFF" height={60} width={60} className='pc-loader' />}
    </div>
  );
});

export { SchemaDetails as default }
