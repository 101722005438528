import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { toast } from 'react-toastify'

export default function HelperText(){
    const history = useHistory();
    const [loading, setLoading] = useState(true);
    const onLInkClickedHandler = () =>{
        setLoading(loading);
        // history.push('/')
        window.location.pathname='/organizations/promptcloud_master/home'
        toast.success("Organization name changed to promptcloud_master");
        setLoading(!loading);
    };
    return (
        <>
            <div>
                <span className="header-text">For the training of PromptCloud user, please use the "promptcloud_master" organization to site(s) or sitegroup or issue(s) creation and its link <Link to='' onClick={onLInkClickedHandler}>PromptCloud Master</Link></span>
            </div>
        </>
    );
};
