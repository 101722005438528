import React from 'react';
import { useState, useEffect, useContext} from 'react';
import { useParams, useHistory } from 'react-router-dom';
import SpinnerLoader from '../../Common/LoadingAnimation/SpinnerLoader.component';
import Breadcrumbs from '../../Common/BreadCrumb/BreadCrumbs.component';
import BannerNotifications from '../../Common/BannerNotification/BannerNotifications.component';
import ClientFeasibilityReportsTable from './ClientFeasibilityReportsTable/ClientFeasibilityReportsTable.component';
import axios from 'axios';
import { toast } from 'react-toastify';

// custom hooks
import useOrgIdentifer from '@hooks/org_identifier';

export default function FeasibilityReports({ banner_notifications, environment, current_user, current_organization, take_my_org_details }) {

  const history = useHistory();

  const org_identifier = useOrgIdentifer();

  const [loading, setLoading] = useState(false);

  const [data, setData] = useState([]);

  const [searchString, setSearchString] = useState("");

  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    take_my_org_details(org_identifier);
  }, [org_identifier]);

  // use Effect hook for mounting
  useEffect(async () => {
    if (org_identifier) {
      await fetchFeasibilityReportsForClient(org_identifier);
    }
    return;
  }, [org_identifier]);

  // Use effect to populate the table with data filtered using the sitegroug name
  useEffect(() => {
    let searchedData = data.filter((report) => {
      return report.sitegroup_name.toLocaleLowerCase().includes(searchString.toLocaleLowerCase());
    });
    setFilteredData(searchedData);
  }, [searchString]);

  // Helper function for searching data in data table
  function onFeasibilityReportsSearchHandler(event) {
    const { value } = event.target;
    setSearchString(value)
  }

  // Helper function to fetch feasibility reports for clients from the back-end DB
  async function fetchFeasibilityReportsForClient(organization) {
    setLoading(true);
    try {
      const response = await axios.get(`/api/v1/organizations/${organization}/feasibility_reports?page_type=client&controller_name=feasibility_reports`);
      const feasibility_reports = await response?.data?.feasibility_reports;
      console.log(feasibility_reports);
      setData(feasibility_reports);
      setLoading(false)
    }
    catch (err) {
      setLoading(false)
      // Refactor later
      if (err?.response?.data?.page_auth_failed) {
        history.push("/")
        toast.error(err?.response?.data?.message)
      }
      else if (!err?.response?.data?.org_auth_failed){
        toast.error(err?.response?.data?.message)
      }
    }
  };

  return (
    <>
      <SpinnerLoader loading={loading}>
        <Breadcrumbs title={"Feasibility Reports"} parent={"Feasibility Reports"} />
        <div className='container container-fluid'>
          <BannerNotifications notificationsArray={banner_notifications} />
          <ClientFeasibilityReportsTable
            tableData={filteredData.length !== 0 ? filteredData : data}
            onSearch={onFeasibilityReportsSearchHandler}
            environment={environment} />
        </div>
      </SpinnerLoader>
    </>
  )
}

