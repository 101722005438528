/******************************************************************************************************************
 * This component is used for displaying all the invoices from the DB. This page can be found under amins section *
 * in the crawlboardv3 application. It queries the data from the DB based on the filters applied and displays it  *
 * in the data table component.                                                                                   *
 * ****************************************************************************************************************
 * Associated Controller: Admins::InvoicesController                                                              *
 * Component Type: Internal                                                                                       *
 * Usage: Non-Client for internal teams only                                                                      *
 * ****************************************************************************************************************                                                                              
 * CREATED BY: Atram Aniket                                                                                       *
 * CREATED ON: 3 April 2023                                                                                       *
 *****************************************************************************************************************/


import React from 'react';
import { useState, useEffect, useMemo } from 'react';

import { Container } from 'reactstrap';
import { toast } from 'react-toastify';
import axios from 'axios';

import SpinnerLoader from '../../V2/Common/LoadingAnimation/SpinnerLoader.component'; // Loading animation from common components
import Breadcrumbs from '../../V2/Common/BreadCrumb/BreadCrumbs.component' // Breadcrumbs
import Options from './Options/Options.component'; // The card showild all filter options on top with reset filter button
import AllInvoicesTable from './DataTable/DataTable.component'; // The table where all invoice data and links are displayed


export default function AllInvoices() {

  // Loading animation
  const [loading, setLoading] = useState(true); // Start or stop loading animation

  // States specific to options
  const [name, setName] = useState(""); // eg. 'Promptcloud'
  const [identifier, setIdentifier] = useState(""); // eg. 'promptcloud_master'
  const [invoiceID, setInvoiceID] = useState(""); // The id from the rails db eg. '12345'
  const [fbQbInvoiceId, setFbQbInvoiceId] = useState(""); // The id from the freshbooks or wuickbooks 
  const [invoiceNumber, setInvoiceNumber] = useState(""); // The invoice number displayed in invoice
  const [serviceType, setServiceType] = useState(""); // The invoice service eg. 'freshbooks'
  const [productType, setProductType] = useState(""); // eg. 'FeasibilityReport'
  const [invoiceStatus, setInvoiceStatus] = useState(""); // eg. 'paid', 'pending'
  const [allPaymentSynced, setAllPaymentSynced] = useState(""); // eg. 'yes', 'no', 'to_do_payment'

  // States specific to table
  const [tableData, setTableData] = useState([]); // Data displayed in the react data table component
  const [pageNumber, setPageNumber] = useState(1); // used for pagination 
  const [totalCount, setTotalCount] = useState(0); // The sum total number of invoices present in DB
  const [sortedBy, setSortedBy] = useState(""); // used for sorting the column in ascending or descending order


  // Every time use enters relevant data in the inputs, the api endpoint changes which will
  // trigger the repopulation of table data
  const API_ENDPOINT = useMemo(() => (
    `/api/v1/admins/invoices?filterrific[search_by_name]=${name}&filterrific[search_by_identifier]=${identifier}&filterrific[filter_by_service_type]=${serviceType}&filterrific[filter_by_product_type]=${productType}&filterrific[filter_by_status=${invoiceStatus}&filterrific[search_by_id]=${invoiceID===""?"":invoiceID*1}&filterrific[search_by_invoice_id]=${fbQbInvoiceId===""?"":fbQbInvoiceId*1}&filterrific[search_by_invoice_number]=${invoiceNumber}&filterrific[filter_by_all_payments_synced]=${allPaymentSynced === ""? "": allPaymentSynced}&filterrific[sorted_by]=${sortedBy}&page=${pageNumber}`
  ), [name, identifier, invoiceID, fbQbInvoiceId, invoiceNumber, invoiceStatus,serviceType, productType, pageNumber, sortedBy, allPaymentSynced])


  // The use effect hook is triggered everytime the api 
  // endpoint values change. This will populate the table
  // with the values from returned from backend DB and
  // will display in the data table component
  useEffect(async () => {
    const abortController = new AbortController();
    // await fetchInvoiceDataFromDB(abortController.signal);
    await fetchInitialData(abortController.signal);
    // setLoading(false);
    return () => {
      abortController.abort();
    }
  }, [])


  // Custom method to populate the data when user first navigates to
  // the component or when user clicks the reset button
  async function fetchInitialData(signal) {
    const response = await axios.get("/api/v1/admins/invoices?&page=1", { signal: signal });
      const data = await response?.data;
      const tableData = await data?.data;
      const totalInvoices = await data?.total_invoices_count;
      setTableData(tableData);
      setTotalCount(totalInvoices);
      setLoading(false);
  }


  // Custom method to fetch data from backend when user clicks search button
  async function fetchInvoiceDataFromDB() {
    try {
      const response = await axios.get(API_ENDPOINT);
      const data = await response?.data;
      const tableData = await data?.data;
      const totalInvoices = await data?.total_invoices_count;
      setTableData(tableData);
      setTotalCount(totalInvoices);
      setLoading(false);
    }
    catch (error) {
      toast.error(error?.response?.message);
      setLoading(false);
    }
  }


  // Custom hook to manupulate states for filter options
  // These are the values entered in the input fields
  // This method is bound to all input fields in the options
  // component's input onChange handler
  function onChangeHandler(name, value) {
    switch (name) {
      case "name":
        setName(value)
        break;
      case "identifier":
        setIdentifier(value)
        break;
      case "invoiceId":
        setInvoiceID(value)
        break;
      case "fbqb":
        setFbQbInvoiceId(value)
        break;
      case "invoiceNumber":
        setInvoiceNumber(value)
        break;
      case "serviceType":
        setServiceType(value)
        break;
      case "productType":
        setProductType(value)
        break;
      case "invoiceStatus":
        setInvoiceStatus(value)
      case "allPaymentSynced":
        setAllPaymentSynced(value)
      break;
      default:
        console.log("Invalid Input Data")
    }
  }

  // The object to hold values for the input field inputs 
  // This is helpful for clearing out the values in the 
  // input fields as well as resetting the select options
  // once the reset filters button is pressed
  // These valued are used to display user entered values 
  // in the input fields of the options component
  const inputVlaues = useMemo(()=>(
    {
      name:name,
      identifier:identifier,
      invoiceID:invoiceID,
      fbQbInvoiceId:fbQbInvoiceId,
      invoiceNumber:invoiceNumber,
      serviceType:serviceType,
      productType:productType,
      invoiceStatus:invoiceStatus,
      allPaymentSynced: allPaymentSynced
    }
  ),[name, identifier, invoiceID, fbQbInvoiceId, invoiceNumber, serviceType, productType, invoiceStatus, allPaymentSynced])

  // Custom Hook to reset the applied filters
  // This method is bound to "Reset Filters" button
  async function onResetFilterClickHandler() {
    setName("")
    setIdentifier("")
    setInvoiceID("")
    setFbQbInvoiceId("")
    setInvoiceNumber("")
    setServiceType("")
    setProductType("")
    setInvoiceStatus("")
    setAllPaymentSynced("")
  }

  // Custom hook to handle page change
  // When user paginates in the table, it repopulates
  // the table data by paginating on the server
  // This method is bound to pagination in the react data
  // table in the AllInvoicesTable
  function paginationHandler(pageNum) {
    setPageNumber(pageNum);
    (async () => {
        await fetchInvoiceDataFromDB();
    })();
  }

  // Custom hook to sort the table
  // This is used for sorting the table columns
  // This method is bound to sort functionality for
  //  AllInvoicesTable
  function customSort(rows, selector, direction) {
    if (selector !== null){
      setSortedBy(`${selector}_${direction}`)
    }
    return rows;
  }

  return (
    <React.Fragment>
      <Container fluid>
        <Breadcrumbs parent={"All Invoices"} title={"All Invoices"} />
        <SpinnerLoader loading={loading}>
          <Options
            inputVlaues = {inputVlaues}
            onChangeHandler={onChangeHandler}
            onSearchButtonClickHandler = {fetchInvoiceDataFromDB}
            onResetFilterClickHandler={onResetFilterClickHandler} />
          <AllInvoicesTable
           data={tableData}
           pageNumber={pageNumber}
           totalCount={totalCount}
           customSort={customSort}
           sortedBy={sortedBy}
           paginationHandler={paginationHandler} />
        </SpinnerLoader>
      </Container>
    </React.Fragment>
  )
}
